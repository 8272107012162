import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	root: {
        height: '100%',
        width: '100%',
	},
    workarea: {
        height: '100%',
        width: '100%',
        color: '#212121',
        backgroundColor: '#ffffff',
    },
});
class GraphQLSchemaV2OpenCreditManager extends Component {

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
               <iframe title="GraphQLSchemaV2OpenCreditManager" className={classes.workarea} src={process.env.PUBLIC_URL + '/GraphQLSchemaV2/graphql/intro/index.html'} frameBorder="0">
                </iframe>
            </div>
        );
    }

}

GraphQLSchemaV2OpenCreditManager.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GraphQLSchemaV2OpenCreditManager);
