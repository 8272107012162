import ExampleBuilder from './ExampleBuilder';

const data = {
    associatedRisksNo: "00001",
    associatedRisksName: "Test risk compound",
    riskPreventionParticipationKey: "10",
    clerk: "abit",
    primaryCustomerNo: "API0000003",
    customers: [{
        customerNo: "API0000001",
        salutationKey: "10",
        firstName: "John",
        lastName: "Smith",
        dateOfBirth: "1979-10-17",
        legalFormKey: "01",
        addresses: [{
            addressCategoryKey: "01",
            addressTypeKey: "01",
            mailingTypeKey: "01",
            addressNo: "0001",
            street: "Hauptstrasse",
            streetNo: "1",
            zip: "40668",
            city: "Meerbusch",
            countryKey: "004",
        }],
        clerk: "abit",
    }, {
        customerNo: "API0000002",
        salutationKey: "20",
        firstName: "Johana",
        lastName: "Smith",
        dateOfBirth: "1982-11-27",
        legalFormKey: "02",
        addresses: [{
            addressCategoryKey: "01",
            addressTypeKey: "01",
            mailingTypeKey: "01",
            addressNo: "0001",
            street: "Hauptstrasse",
            streetNo: "1",
            zip: "40668",
            city: "Meerbusch",
            countryKey: "004",
        }],
        clerk: "abit",
    }, {
        customerNo: "API0000003",
        salutationKey: "20",
        firstName: "Jane",
        lastName: "Bonda",
        dateOfBirth: "1981-02-21",
        legalFormKey: "02",
        addresses: [{
            addressCategoryKey: "01",
            addressTypeKey: "01",
            mailingTypeKey: "01",
            addressNo: "0001",
            street: "Hauptstrasse",
            streetNo: "2",
            zip: "40668",
            city: "Meerbusch",
            countryKey: "004",
        }],
        clerk: "abit",
    }],
    otherLegalEntities: [{
        customerNo: "API0000004",
        salutationKey: "10",
        firstName: "James",
        lastName: "Bond",
        dateOfBirth: "1971-01-01",
        legalFormKey: "01",
        addresses: [{
            addressCategoryKey: "01",
            addressTypeKey: "01",
            mailingTypeKey: "01",
            addressNo: "0001",
            street: "Hauptstrasse",
            streetNo: "2",
            zip: "40668",
            city: "Meerbusch",
            countryKey: "004",
        }],
        clerk: "abit",
    }],
    contracts: [{
        externId: "0001",
        mainContractNo: "0000000001",
        contractNo: "0000000001",
        contractType: "Forderung",
        creditTypeKey: "001",
        originKey: "01",
        mainCustomerNo: "API0000001",
        mainCustomerTypeKey: "00",
        dueDate: "2019-12-25",
        contractDate: "2019-01-01",
        effectiveInterest: 0.7,
        origEffectiveInterest: 0.5,
        specificValueAdjustmentDueDate: "2019-12-01",
        specificValueAdjustmentTypeKey: "00",
        specificValueAdjustmentCritical: false,
        IFRSCategoryKey: "",
        fileNo: "11213/2019",
        terminationDate: "2019-10-01",
        terminationBalance: 5.97,
        transferStatusKey: "0",
        clerk: "abit",
        histDate: "2025-01-01",
        accountBalance: 1214532.87,
        nominalInterestRate: 0.7,
        paymentArrears: 45673.52,
        monetaryCommitment: 43.97,
        ratings: [{
            validFrom: "2019-10-25",
            ratingClassKey: "10",
            ratingSourceKey: "01",
        }],
        debtorRelations: [{
            relationNo: 0,
            customerNo: "API0000001",
            origPrincipalClaim: 23.75,
            origPrincipalClaimValueDate: "2019-10-17",
            origInterestFreePrincipalClaim: 0.3,
            origInterestFreePrincipalClaimValueDate: "2019-10-16",
            origInterestStart: "2019-10-18",
            origInterestTable: "Bas_5",
            obligationKey: "11",
            receivableReasonKey: "01",
            proceduralStatusKey: "1",
            currency: "EUR",
        }, {
            relationNo: 1,
            customerNo: "API0000002",
            origPrincipalClaim: 23.75,
            origPrincipalClaimValueDate: "2019-10-17",
            origInterestFreePrincipalClaim: 0.3,
            origInterestFreePrincipalClaimValueDate: "2019-10-16",
            origInterestStart: "2019-10-18",
            origInterestTable: "Bas_5",
            obligationKey: "11",
            receivableReasonKey: "01",
            proceduralStatusKey: "1",
            currency: "EUR",
        }, {
            relationNo: 2,
            customerNo: "API0000003",
            origPrincipalClaim: 23.75,
            origPrincipalClaimValueDate: "2019-10-17",
            origInterestFreePrincipalClaim: 0.3,
            origInterestFreePrincipalClaimValueDate: "2019-10-16",
            origInterestStart: "2019-10-18",
            origInterestTable: "Bas_5",
            obligationKey: "11",
            receivableReasonKey: "01",
            proceduralStatusKey: "1",
            currency: "EUR",
        }],
        paymentPlans: [{
            reason: "es ist gut so",
            description: "alabalaportocala",
            probability: 0.008,
            validFrom: "2019-11-30",
            initialPlan: true,
            orderNo: 1,
            stateKey: "01",
            elements: [{
                reason: "ist besser so",
                amount: 10000000000.01,
                valueDate: "2019-12-07",
                iasInstallmentTypeKey: "01",
                currency: "EUR",
            }, {
                reason: "warum nicht",
                amount: -0.01,
                valueDate: "2019-12-24",
                iasInstallmentTypeKey: "01",
                currency: "EUR",
            }]
        }],
        currency: "EUR",
    }],
    collaterals: [{
        position: 1,
        positionDetail: 1,
        otherCollateral: {
            externId: "0000001",
            description: "test collateral",
            nominalAmount: 57.73,
            collateralTypeKey: "29",
            collateralOwner: "API0000001",
            contracts: [{
                rank: 1,
                contractNo: "0000000001",
                contractType: "P",
            }],
            customers: [{
                rank: 1,
                customerNo: "API0000001",
            }],
            collateralNo: "SIE0000001",
            collateralObjects: [{
                externId: "0000001",
                objectNo: "SIO0000001",
                collateralObjectTypeKey: "AUSS",
                valuations: [{
                    objectTypeKey: "I",
                    includeInCalculation: true,
                    valuation: 67.83,
                    valuationTypeKey: "30",
                    valuationDate: "2019-10-31",
                    relevantForHGB: false,
                    relevantForIAS39: false,
                    relevantForCredit: true,
                    relevantForRestructuring: false,
                    validFrom: "2019-11-04",
                    fairMarketValue: 600000.98,
                    mortgageLendingValue: 453267.87,
                    openMarketValue: 21111456.81,
                    targetDate: "2019-12-24",
                    currency: "EUR",
                }]
            }],
            currency: "EUR",
        }
    }, {
        position: 2,
        positionDetail: 1,
        mortgage: {
            externId: "0000002",
            collateralNo: "SIE0000002",
            nominalAmount: 2000056.17,
            collateralTypeKey: "10",
            collateralOwner: "API0000004",
            executoryTitleTypeKey: "06",
            executoryTitleStateKey: "00",
            contracts: [{
                rank: 1,
                contractNo: "0000000001",
                contractType: "P",
            }],
            currency: "EUR",
        }
    }],
    realEstate: [{
        externId: "0000001",
        realEstateNo: "IMO0000001",
        collateralObjectTypeKey: "AUSS",
        rootObject: "N",
        street: "Hauptstrasse",
        streetNo: "3",
        zip: "40668",
        city: "Meerbusch",
        countryKey: "004",
        valuations: [{
            objectTypeKey: "I",
            includeInCalculation: true,
            valuation: 187561.43,
            valuationTypeKey: "30",
            valuationDate: "2019-10-31",
            relevantForHGB: true,
            relevantForIAS39: false,
            relevantForCredit: true,
            relevantForRestructuring: true,
            validFrom: "2019-11-04",
            fairMarketValue: 187561.49,
            mortgageLendingValue: 567.36,
            openMarketValue: 187561.41,
            targetDate: "2019-12-24",
            currency: "EUR",
        }],
        landRegisterEntry: {
            externId: "0000001",
            volumeNo: "75",
            sheetNo: "184",
            landRegisterEntryNo: 0,
            districtCourt: "Hagen",
            landRegisterTypeKey: "WOG",
            ownerSections: [{
                sectionNo: "1",
                sectionTypeKey: "01",
                owner: "API0000004",
                ownerStake: 100,
            }],
            liabilitySections: [{
                sectionNo: "2",
                sectionTypeKey: "NZKR",
                valuation: 3.49,
                rank: 1,
                currency: "EUR",
            }],
            legalSections: [{
                sectionNo: "3",
                sectionTypeKey: "5",
                collateralNo: "SIE0000002",
                interestFrom: "2019-01-01",
                inRemInterestRate: 33.72,
                ownRegisteredLien: true,
                nominalAmount: 156432.84,
                rank: 1,
                interestCalcIntervalKey: "002",
                inFavorOf: "Zugunsten",
                currency: "EUR",
            }]
        }
    }]
};

/**
 * Queryfy.
 *
 * Prep javascript objects for interpolation within graphql queries.
 *
 * @param {mixed} obj
 * @return template string.
 */
export const queryfy = (obj, spacer) => {
    // Make sure we don't alter integers.
    if(typeof obj === 'number') {
        return obj;
    }

    if(Array.isArray(obj)) {
        const props = obj.map(value => `${queryfy(value, spacer)}`).join(', ');
        return `[${props}]`;
    }

    if(typeof obj === 'object') {
        const props = Object.keys(obj)
            .filter(key => obj[key] !== undefined && obj[key] !== null)
            .map(key => `${spacer}${key}:${queryfy(obj[key], spacer + '    ')}`)
            .join(',\n');
        return `{\n${props}\n${spacer.substring(4)}}`;
    }

    return JSON.stringify(obj);
};

export default {

    header : ExampleBuilder,

    queries: [{
        title   : "Query - retrieveCustomer [since v4.40]",

        label   : "retrieveCustomer", 

        path    : "debt_examples_retrieveCustomer",

        message : 
`query {
    retrieveCustomer (
        customerNo: "A000000001" 
    ) {
        customerID
        customerNo
        externID
        salutationKey
        titleKey
        firstName
        lastName
        extendedName
        dateOfBirth
        placeOfBirth
        legalFormKey
        industryKey
        clerk
        nationalityKey
        maritalStatusKey
        numberOfChildren
        numberOfAdults
        occupation
        occupationSince
        employer
        schufaAgreement
        iban
        bic
        tin
        letterSalutationKey
        blockKey
        vip
        salesPerson
        salesRegionKey
        startOfBusiness
        costCenterKey
        contactDetails {
            eMail
            mobilePhoneNo
            workPhoneNo
            privatePhoneNo
            faxNo
        }
        mainAddress {
            street
            streetNo
            zip
            city
            countryKey
            addressTypeKey
            addressCategoryKey
            mailingTypeKey
            addressNo
            externID
            poBox
        }
        addresses {
            street
            streetNo
            zip
            city
            countryKey
            addressTypeKey
            addressCategoryKey
            mailingTypeKey
            addressNo
            externID
            poBox    
        }
        contractRelations {
            relationNo
            obligationTypeKey
            origInterestTable
            origInterest
            obligationKey
            totalAmount
            currency
            contract {
                contractNo
                contractNoExt
                internalIdent
                fileNo
                originalFileNo
                specialTreatmentKey
                totalAmount
                currency
            }
        }
        dataSections {
            name
            values {
                name
                type
                value
                isoCode
            }
        }
    }
}`

    }, {

        title   : "Query - retrieveContract [since v4.40]",

        label   : "retrieveContract", 

        path    : "debt_examples_retrieveContract",

        message : 
`query {
    retrieveContract (
        contractNo: "00001" 
    ) {
        externId
        mainContractNo
        contractNo
        contractNoExt
        internalIdent
        contractType
        creditTypeKey
        originKey
        mainCustomerNo
        mainCustomerTypeKey
        dueDate
        contractDate
        effectiveInterest
        origEffectiveInterest
        specificValueAdjustmentDueDate
        specificValueAdjustmentTypeKey
        specificValueAdjustmentCritical
        IFRSCategoryKey
        fileNo
        originalFileNo
        specialTreatmentKey
        terminationDate
        terminationBalance
        transferStatusKey
        clerk
        histDate
        accountBalance
        nominalInterestRate
        paymentArrears
        monetaryCommitment
        totalAmount
        currency
        dataSections {
            name
            values {
                name
                type
                value
                isoCode
            }    
        }
        ratings {
            validFrom
            ratingClassKey
            ratingSourceKey
        }
        debtorRelations {
            relationNo
            customerNo
            origPrincipalClaim
            origPrincipalClaimValueDate
            origInterestFreePrincipalClaim
            origInterestFreePrincipalClaimValueDate
            origInterestStart
            origInterestTable
            origInterest
            obligationTypeKey
            obligationKey
            receivableReasonKey
            proceduralStatusKey
            totalAmount
            currency
        }
        paymentPlans {
            reason
            description
            probability
            validFrom
            initialPlan
            orderNo
            stateKey
            elements {
                reason
                amount
                iasInstallmentTypeKey
                valueDate
                currency    
            }
        }
    }
}`

    },
    {
       
        title   : "Query - retrievePaymentPlans [since v4.60]",

        label   : "retrievePaymentPlans", 

        path    : "debt_examples_retrievePaymentPlans",

        data    : data,

        message :
`query {
    retrievePaymentPlans(
        startIndex: 0 
        fetchSize: 7
        accountNo: "0000000115"
        date: "2023-01-10"
    ) {
        totalNumberOfPaymentPlans
        morePaymentPlansAvailable
        paymentPlans {
            IntervalNumber
            PaymentDate
            DueDate
            InterestRateType
            Amount
            InterestRate
            Repayment
            RemainingDebt
            Costs
            OneTimeCosts
            RunningCosts
            ProvisionInterestRate
            RemainingPaymentAmount
        }
}
}
`

    }],

    mutations : [{
        
        title   : "Mutation - saveDebt [since v4.40]",

        label   : "saveDebt", 

        path    : "debt_examples_saveAssociatedRisks",

        data    : data,

        message :
`mutation {
    saveDebt(
        testMode: true
        associatedRisks: ` + queryfy(data, '           ') + `
    ) {
        message
        responseCode
    }
}`

    }, {
       
        title   : "Mutation - importDebt [under construction]",

        label   : "importDebt", 

        path    : "debt_examples_importAssociatedRisks",

        data    : data,

        message :
`mutation {
    importDebt(
        fileReference: "Debt.csv"
    ) {
        message
        responseCode
    }
}`

    }
   
    ], jobs: [], files:[], workflows:[], events:[],
    
    blanks:[
	 {
        title   : "Blank - Test request 1",

        path    : "test_requests",
         
        message : ""
        
    },
    {
        title   : "Blank - Test request 2",

        path    : "debt_examples_testRequest2",
        
        message : ""
    },
    {
         title   : "Blank - Test request 3",

         path    : "debt_examples_testRequest3",
        
         message : ""
    },
    {
         title   : "Blank - Test request 4",

         path    : "debt_examples_testRequest4",
        
         message : ""
    },
    {
         title   : "Blank - Test request 5",

         path    : "debt_examples_testRequest5",
        
         message : ""
    },
    {
        title   : "Blank - Test request 6",

        path    : "debt_examples_testRequest6",
            
        message : ""
    },
    {
        title   : "Blank - Test request 7",

        path    : "debt_examples_testRequest7",
        
        message : ""
    }]
};
