import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Sidebar from '../../components/Sidebar';

import styles from '../../styles';

class CallSequence extends Component {

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Sidebar title="Call Sequence" samePageScroll={true} model={[
                    {label: "... to the begining", path: "callsequence"}, 
                    {label: "Import"             , path: "callsequence_creditmanager_import"}, 
                    {label: "Export"             , path: "callsequence_creditmanager_export"}]}/>
                <div className={classes.workarea} id = "content">
                    <div className={classes.paper}>
                        <div id="callsequence">
                            <span className={classes.title}>Call sequence</span>
                            <span className={classes.text}>
                                Herewith you find a description of use cases for the Credit Manager API.
                            </span>
                        </div>
                        <div id="callsequence_creditmanager_import">
                            <span className={classes.headingTitle}>Import</span>
                            <span className={classes.text}>
                                Customer import includes customer data, address, contact details and limit information.
                            </span>
                            <span className={classes.text}>
                                Accounting voucher import includes voucher information, dates, amounts, and dunning information.
                                For this import, all vouchers of a customer should always be provided.
                                A voucher already found in the Open Credit 4.0 system that is missing from the import will be considered closed.
                            </span>
                            <span className={classes.text}>
                                Customer notes import is separate from the main customer import because the source might be different.
                            </span>
                            <span className={classes.text}>
                                Collateral import includes collateral type, date, amount, guarantor and comment.
                            </span>
                            <img src={process.env.PUBLIC_URL + '/call_sequence_creditmanager_import.png'} alt="aha" className={classes.image}/>
                        </div>
                        <div id="callsequence_creditmanager_export">
                            <span className={classes.headingTitle}>Export</span>
                            <span className={classes.text}>
                                Customer export includes customer data, payment experience, score values, address and limit information.
                            </span>
                            <span className={classes.text}>
                                Accounting voucher export includes voucher information, dates and order release information.
                                Only the customers with accounting vouchers that have been released (have an order release date) after a requested release date.
                            </span>
                            <img src={process.env.PUBLIC_URL + '/call_sequence_creditmanager_export.png'} alt="aha" className={classes.image}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

CallSequence.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CallSequence);
