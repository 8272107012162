import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
    palette: {
        //type: 'dark',

        primary: {
        	light: '#FFFFFF',
    	    main: '#082048',
    	    dark: '#000000',
            contrastText: '#FFFFFF',
            text: '#D0C8C8',
        },
        
        secondary: {
            main: '#B0E800',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#082048',
            text: '#2e32ae'
        },
        
        // error: will use the default color

		background: {
			paper: "#fff",
			default: "#f4f4f4"
		},

        // Used by `getContrastText()` to maximize the contrast between the background and 
        // the text.
        contrastThreshold: 3,

        // Used to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,

    },
    
    typography: {
        fontSize: 14,
    },

    status: {
        danger: '#FF0000',
    },

});