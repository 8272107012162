export default {

    queries : [{
        
        title   : "Query - retrieveCustomers [since v4.40]",

        label   : "retrieveCustomers", 

        path    : "creditmanager_examples_retrieveCustomers",

        message :
`query {
    retrieveCustomers(
        startIndex: 5 
        fetchSize: 2
    ) {
        totalNumberOfCustomers
        moreCustomersAvaliable
        customers {
            customerNo
            externID
            salesRegionKey
            rating
            paymentExperienceScore
            blockKey
            actualHighestDunningLevel
            negativeCriteriaKey
            mainAddress {
                street
                streetNo
                zip
                city
                countryKey
            }
            creditLimit {
                contractNo
                limit
                currency
                limitUntil
                topUpCoverAmount
                insuredAmount
                collateralAmount
            }
        }
    }
}`

    }, {
        
        title   : "Query - exportCustomers [since v4.40]",

        label   : "exportCustomers", 

        path    : "creditmanager_examples_exportCustomers",

        message :
`query {
    exportCustomers(
        asXML: false 
    ) {
        fileReference
        responseCode
        message
    }
}`

    }, {

        title   : "Query - retrieveReleasedVouchers [since v4.40]",
        
        label   : "retrieveReleasedVouchers", 
        
        path    : "creditmanager_examples_retrieveReleasedVouchers",
        
        message : 
`query {
    retrieveReleasedVouchers(
        releaseDate: "2020-01-28"
        startIndex: 0
        fetchSize: 10
    ) {
        totalNumberOfCustomers
        moreCustomersAvaliable
        customers {
            customerNo
            externId
            salesRegionKey
            accountingVouchers {
                voucherNo
                typeKey
                voucherDate
                orderReleaseDate
                orderReleasedBy
            }
        }
    }
}`
    }, {
        
        title   : "Query - exportReleasedVouchers [since v4.40]",

        label   : "exportReleasedVouchers", 

        path    : "creditmanager_examples_exportReleasedVouchers",

        message :
`query {
    exportReleasedVouchers(
        asXML: false 
    ) {
        fileReference
        responseCode
        message
    }
}`

    }],

    mutations: [{

        title   : "Mutation - saveCustomer [since v4.40]",
        
        label   : "saveCustomer", 
        
        path    : "creditmanager_examples_saveCustomer",
        
        message : 
`mutation {
    saveCustomer(
        projectName: "KundeKonto"
        useExternID: false
        customer: {
            customerNo: "A000000003"
            externID: "00000000a3"
            firstName: "Tessa"
            lastName: "Test"
            extendedName: "A"
            legalFormKey: "12"
            industryKey: "01"
            vatID: "1234567890"
            clerk: "ABIT"
            blockKey: "01"
            negativeCriteriaKey: "10"
            vip: false
            salesRegionKey: "000"
            startOfBusiness: "2014-01-15"
            statusKey: "01"
            costCenterKey: "01"
            paymentTermKey: "10"
            paymentTypeKey: "01"
            nationKey: "004"
            mainAddress: {
                street: "Hauptstr."
                streetNo: "1"
                zip: "40239"
                city: "Düsseldorf"
                countryKey: "004"
                poBox: "555674"
            }
            creditLimit: {
                limit: 4000.98
                limitFrom: "2017-12-25"
                limitUntil: "2020-04-01"
                currency: "EUR"
            }
        } 
    ) {
        responseCode
        message
    }
}`

    }, {
        
        title   : "Mutation - importCustomers [since v4.40]",
        
        label   : "importCustomers", 
        
        path    : "creditmanager_examples_importCustomers",
        
        message : 
`mutation {
    importCustomers(
        projectName: "KundeKonto"
        useExternID: true
        fileReference: "KundeKonto.csv"
    ) {
        responseCode
        message
    }
}`
    
    }, {
        
        title   : "Mutation - updateCustomerVouchers [since v4.40]",
        
        label   : "updateCustomerVouchers", 
        
        path    : "creditmanager_examples_updateCustomerVouchers",
        
        message : 
`mutation {
    updateCustomerVouchers(
        customerNo: "A000000003"
        vouchers: [{
            voucherNo: "V000000001"
            typeKey: "RE"
            voucherDate: "2019-08-31"
            totalAmount: 1299.98
            totalAmountOrigCurrency: 1299.98
            netAmount: 1000.00
            openAmount: 1299.98
            openAmountOrigCurrency: 1299.98
            dueDate: "2019-11-05"
            paymentDate: "2019-12-15"
            currency: "EUR"
            exchangeRate: 1.0
            orderReference: "RG007/2019/08"
            dunningLevel: 1
            dunningDate: "2019-12-01"
            dunningAmount: 1299.98
            dunningBlockKey: "N"
            complaintKey: "001"
        }, {
            voucherNo: "V000000002"
            typeKey: "RE"
            voucherDate: "2019-11-30"
            totalAmount: 2599.98
            totalAmountOrigCurrency: 2599.98
            netAmount: 2300.00
            openAmount: 0
            openAmountOrigCurrency: 0
            dueDate: "2019-12-31"
            paymentDate: "2019-12-15"
            currency: "EUR"
            exchangeRate: 1.0
            orderReference: "RG009/2019/11"
        }],
        configuration: {
            useExternalID: false
            numberFormat: "EN"
            useOPOSLogic: true
            flatRateLimit: 10000
            offerLimit: 5000
            resubmissionOnOfferLimitExceeded: false
            daysToMDTAchievement: 3
            resubmissionOnMDTAchievement: false
            daysToEndOfWaitingPeriod: 7
            notInsuredIndicatorIfMDTExceeded: false
            useForeignCurrency: false
            useDunningOnlyOnFirstImport: false
        }
    ) {
        responseCode
        message
    }
}`
    
    }, {
        
        title   : "Mutation - importCustomerVouchers [since v4.40]",
        
        label   : "importCustomerVouchers", 
        
        path    : "creditmanager_examples_importCustomerVouchers",
        
        message : 
`mutation {
    importCustomerVouchers(
        fileReference: "Vouchers.csv"
        configuration: {
            useExternalID: true
            numberFormat: "EN"
            useOPOSLogic: true
            flatRateLimit: 10000
            offerLimit: 5000
            resubmissionOnOfferLimitExceeded: false
            daysToMDTAchievement: 3
            resubmissionOnMDTAchievement: false
            daysToEndOfWaitingPeriod: 7
            notInsuredIndicatorIfMDTExceeded: false
            useForeignCurrency: false
            useDunningOnlyOnFirstImport: false
        }
    ) {
        responseCode
        message
    }
}`
    
    }, {
        
        title   : "Mutation - updateCustomerNotes [since v4.40]",
        
        label   : "updateCustomerNotes", 
        
        path    : "creditmanager_examples_updateCustomerNotes",
        
        message : 
`mutation {
    updateCustomerNotes(
        customerNo: "A000000001"
        text: "Lorem ipsum dolor sit amet, deterruisset mediocritatem sea ex, ei quem veritus cum, et munere recusabo nec. Sea ad eius labore, eu eloquentiam reformidans per. Cu nihil verterem complectitur ius. Eum ei movet labore. Aliquid nominavi consectetuer eu eum."
    ) {
        responseCode
        message
    }
}`
    
    }, {
        
        title   : "Mutation - importCustomerNotes [since v4.40]",
        
        label   : "importCustomerNotes", 
        
        path    : "creditmanager_examples_importCustomerNotes",
        
        message : 
`mutation {
    importCustomerNotes(
        fileReference: "ImportBemerkung.csv"
        useExternalID: true
    ) {
        responseCode
        message
    }
}`
    
    }, {
        
        title   : "Mutation - updateCustomerCollateral [since v4.40]",
        
        label   : "updateCustomerCollateral", 
        
        path    : "creditmanager_examples_updateCustomerCollateral",
        
        message : 
`mutation {
    updateCustomerCollateral(
        customerNo: "A000000001"
        collateral: {
            externId: "S000000001"
            nominalAmount: 13500.87
            collateralTypeKey: "B02"
            insurerInstitution: "A000000004"
            validFrom: "2019-01-01"
            validUntil: "2023-01-01"
            comment: "Comment"
            currency: "EUR"
            customers: [{
                rank: 1
                customerNo: "A000000001"
            }]
        },
        configuration: {
            defaultCollateralTypeKey: "B09"
            defaultCollateralOwnerKey: "A000000003"
            resubmissionEnabled: false
            numberFormat: "EN"
            customerClerk: "ABIT"
        }
    ) {
        responseCode
        message
    }
}`
    }, {
        
        title   : "Mutation - importCustomerCollateral [since v4.40]",
        
        label   : "importCustomerCollateral", 
        
        path    : "creditmanager_examples_importCustomerCollateral",
        
        message : 
`mutation {
    importCustomerCollateral(
        fileReference: "ImportSicherheiten.csv"
        configuration: {
            defaultCollateralTypeKey: "B09"
            defaultCollateralOwnerKey: "A000000003"
            resubmissionEnabled: false
            numberFormat: "EN"
            customerClerk: "ABIT"
        }
    ) {
        responseCode
        message
    }
}`
    }, {
        
        title   : "Mutation - updateAccountNumber [since v4.40]",
        
        label   : "updateAccountNumber", 
        
        path    : "creditmanager_examples_updateAccountNumber",
        
        message : 
`mutation {
    updateAccountNumber(
        oldAccountNumber: "0000000112"
        oldAccountNumberExtension: ""
        newAccountNumber: "0001120000"
        newAccountNumberExtension: ""
    ) {
        responseCode
        message
    }
}`
    }], jobs: [], files:[], workflows:[], blanks:[], events:[]
}
