import React, { Component } from 'react';

import  { Redirect } from 'react-router-dom'

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from '../styles';

import { getJWTObject } from '../connect/authentication';

import store from "../connect/store";
import {logoutPerformed } from "../connect/actions";

class Signout extends Component {

    render() {
        var jwt = getJWTObject();
        if(jwt) {
            store.dispatch(logoutPerformed());
        }

        return <Redirect to='/'/>
    }
}

Signout.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Signout);
