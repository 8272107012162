import { takeEvery, call, put } from "redux-saga/effects";

import async from 'async';

import { 
    EXAMPLE_SEND, 
    LOGIN_REQUESTED, REFRESH_TOKEN_REQUESTED, LOGOUT_REQUESTED, 
    LIST_DOCUMENTS_REQUEST, DELETE_DOCUMENT_REQUEST, UPLOAD_DOCUMENT_REQUEST, DOWNLOAD_DOCUMENT_REQUEST, RECIEVE_EVENTS_REQUEST } from "./constants";
import { 
    exampleReceive, 
    loginPerformed, refreshTokenPerformed, logoutPerformed, apiError, 
    listDocumentsReceive, downloadDocumentReceive , recieveEventsResponse, recieveEventsError} from "./actions";

import awsConfig from '../aws-config.json';

import { getJWTObject } from './authentication';

import axios from 'axios'
import qs    from 'qs'

const httpsClient = axios.create({
    timeout: 10000,
});

export default function* watcherSaga() {
    yield takeEvery(EXAMPLE_SEND             , workerSaga);
    yield takeEvery(RECIEVE_EVENTS_REQUEST   , recieveEventsSaga);
    yield takeEvery(LOGIN_REQUESTED          , loginWorkerSaga);
    yield takeEvery(REFRESH_TOKEN_REQUESTED  , refreshTokenWorkerSaga);
    yield takeEvery(LOGOUT_REQUESTED         , logoutWorkerSaga);
    yield takeEvery(LIST_DOCUMENTS_REQUEST   , listDocumentWorkerSaga);
    yield takeEvery(DELETE_DOCUMENT_REQUEST  , deleteDocumentWorkerSaga);
    yield takeEvery(UPLOAD_DOCUMENT_REQUEST  , uploadDocumentWorkerSaga);
    yield takeEvery(DOWNLOAD_DOCUMENT_REQUEST, downloadDocumentWorkerSaga);
}

function* recieveEventsSaga() {
    try {
        const authPayload    = yield call(buildAuthorizationHeader);
        const receivePayload = yield call(recieveEvents, {auth : authPayload});
        const formatPayload  = yield call(formatEvents , receivePayload);
        const deletePayload  = yield call(deleteEvents , {auth : authPayload, received : receivePayload , format : formatPayload});

        yield put(recieveEventsResponse(deletePayload));
    } catch (e) {
        console.log("Exception: ", e);
        yield put(recieveEventsError(e));
    }
}

function* workerSaga(params) {
    try {
        const authPayload    = yield call(buildAuthorizationHeader);
        const sendPayload    = yield call(sendMessage   , {auth : authPayload, message : params.payload.message});
        const receivePayload = yield call(receiveMessage, {auth : authPayload, sent : sendPayload});
        const formatPayload  = yield call(formatMessage , receivePayload);
        const deletePayload  = yield call(deleteMessage , {auth : authPayload, received : receivePayload , format : formatPayload});

        yield put(exampleReceive({data: deletePayload, exampleName: params.payload.exampleName}));
    } catch (e) {
        console.log("Exception: ", e);
        yield put(apiError({error: e, exampleName: params.payload.exampleName}));
    }
}

function sendMessage(payload) {
    return async.retry(1, (callback) => {
        httpsClient.post(getJWTObject().url + '/in', payload.message, { 
            'headers' : { 
                'Authorization' : payload.auth,
                'Content-Type'  : 'text/plain; charset=utf-8'
            } 
        }).then((response) => {
            if(response.data) {
                callback(null, response.data);
            } else {
                callback("No data");
            }
        }).catch((error) => {
            callback(error);
        });
    });
}

function receiveMessage(payload) {
    return async.retry(10, (callback) => {
        httpsClient.get(getJWTObject().url + '/out?Action=ReceiveMessage&MaxNumberOfMessages=10&VisibilityTimeout=3&WaitTimeSeconds=2', { 
        //httpsClient.get(getJWTObject().url + '/out?Action=ReceiveMessage&OriginalMessageID=' + payload.sent.sendMessageResult.messageId + '&VisibilityTimeout=3&WaitTimeSeconds=2', { 
            'headers' : { 
                'Authorization' : payload.auth,
                'Content-Type'  : 'application/json',
            } 
        }).then((response) => {
            var rData = response.data && (typeof response.data === 'string' || response.data instanceof String)?
                    JSON.parse(response.data) : 
                    response.data;
            if(rData && rData.Messages) {
                var r = rData.Messages.find((message) => {
                    return message.MessageAttributes && 
                           message.MessageAttributes.originalMessageID && 
                           message.MessageAttributes.originalMessageID.StringValue === payload.sent.sendMessageResult.messageId
                });
                callback(r ? null : "Message not found!", r);
            } else {
                callback("No data");
            }
        }).catch((error) => {
            callback(error);
        });
    });
}

function recieveEvents(payload) {
    return async.retry(1, (callback) => {
        httpsClient.get(getJWTObject().url + '/out?Action=ReceiveMessage&MaxNumberOfMessages=10&VisibilityTimeout=3&WaitTimeSeconds=2', { 
            'headers' : { 
                'Authorization' : payload.auth,
                'Content-Type'  : 'application/json',
            } 
        }).then((response) => {
            var rData = response.data && (typeof response.data === 'string' || response.data instanceof String)?
                    JSON.parse(response.data) : 
                    response.data;
            if(rData && rData.Messages) {
                var r = rData.Messages.reduce((result, message) => {   
                    if(message.Body){
                        var body = JSON.parse(message.Body);
                        if(body && body.data && body.data.event){
                            result.push(message);
                        }
                    }
                    return result;
                },[]);
                callback(r.length > 0 ? null : "No event found!", r);
            } else {
                callback("No data");
            }
        }).catch((error) => {
            callback(error);
        });
    });
}

function formatMessage(payload) {
    if(payload.Body) {
        var o = JSON.parse(payload.Body.replace(/[\r]/g, '').replace(/[\n]/g, '\\n'));
        var json = JSON.stringify(o, null, 2);
        json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        let rez = json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+|-]?\d+)?)/g, (match) => {
            var cls = 'number';
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = 'key';
                } else {
                    cls = 'string';
                }
            } else if (/true|false/.test(match)) {
                cls = 'boolean';
            } else if (/null/.test(match)) {
                cls = 'null';
            }
            return '<span class="' + cls + '">' + match + '</span>';
        });
        return rez;    
    } else {
        let rez = JSON.stringify(payload)
        return rez;
    }
}

function formatEvents(payload) {
    if(!payload || !(payload instanceof Array) || payload.length === 0){
        return JSON.stringify(payload);
    }
    return payload.map((message) => {
        if(message.Body) {
            var o = JSON.parse(message.Body.replace(/[\r]/g, '').replace(/[\n]/g, '\\n'));
            var json = JSON.stringify(o, null, 2);
            json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
            let rez = json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+|-]?\d+)?)/g, (match) => {
                var cls = 'number';
                if (/^"/.test(match)) {
                    if (/:$/.test(match)) {
                        cls = 'key';
                    } else {
                        cls = 'string';
                    }
                } else if (/true|false/.test(match)) {
                    cls = 'boolean';
                } else if (/null/.test(match)) {
                    cls = 'null';
                }
                return '<span class="' + cls + '">' + match + '</span>';
            });
            return rez;    
        } else {
            let rez = JSON.stringify(message)
            return rez;
        }
    });   
}

function deleteMessage(payload) {
    return async.retry(1, (callback) => {
        if(payload && payload.received && payload.received.ReceiptHandle) {
            httpsClient.get(getJWTObject().url + '/out?Action=DeleteMessage&ReceiptHandle=' + encodeURIComponent(payload.received.ReceiptHandle), { 
                'headers' : { 
                    'Authorization' : payload.auth,
                    'Content-Type'  : 'application/json'
                } 
            }).then((response) => {
                callback(null, payload.format);
            }).catch((error) => {
                let msg = "AWS SQS delete error";
                console.log(msg, error);
                callback(msg);
            });
        } else {
            let msg = "no ReceiptHandle";
            console.log(msg);
            callback(msg);
        }
    });
}

function deleteEvents(payload) {
    return async.retry(1, (callback) => {
        if(payload && payload.received && payload.received.length > 0) {
            for (let i = 0; i < payload.received.length; i++) {
                let message = payload.received[i];
                httpsClient.get(getJWTObject().url + '/out?Action=DeleteMessage&ReceiptHandle=' + encodeURIComponent(message.ReceiptHandle), { 
                    'headers' : { 
                        'Authorization' : payload.auth,
                        'Content-Type'  : 'application/json'
                    } 
                }).then((response) => {
                    callback(null, payload.format);
                }).catch((error) => {
                    let msg = "AWS SQS delete error";
                    console.log(msg, error);
                    callback(msg);
                });
            }
        } else {
            let msg = "no ReceiptHandle";
            console.log(msg);
            callback(msg);
        }
    });
}

function* loginWorkerSaga(action) {
    try {
        const tokenPayload = yield call(getToken, action.payload);
        const userPayload  = yield call(userInfo, tokenPayload);

        yield put(loginPerformed(userPayload));
    } catch (e) {
        yield put(apiError(e));
    }
}

function* refreshTokenWorkerSaga(action) {
    try {
        const tokenPayload = yield call(refreshToken, action.payload);
        
        yield put(refreshTokenPerformed(tokenPayload));
    } catch (e) {
        yield put(apiError(e));
    }
}

function* logoutWorkerSaga(action) {
    try {
        const payload = yield call(logout, action.payload);
        yield put(logoutPerformed(payload));
    } catch (e) {
        yield put(apiError(e));
    }
}

function buildAuthorizationHeader() {
    return async.retry(1, (callback) => {
        var auth = getJWTObject();
        if(!auth) {
            console.log("buildAuthorizationHeader: no auth");
            callback("No AWS connection info!");
        } else if(auth.expires_at && auth.expires_at > Date.now()) {
            console.log("buildAuthorizationHeader: refresh token");
            _refreshToken(auth.refresh_token, auth.email, (error, response) => {
                if(error) {
                    console.log(error);
                    callback(error);
                } else {
                    refreshTokenPerformed(response);
                    var authorizationHeader = response ? response.token_type + ' ' + response.id_token : '';
                    //console.log("buildAuthorizationHeader: " + authorizationHeader)
                    callback(null, authorizationHeader);
                }
            });
        } else {
            console.log("buildAuthorizationHeader: has auth");
            //console.log(auth);
            var authorizationHeader = auth ? auth.token_type + ' ' + auth.id_token : '';
            //console.log("buildAuthorizationHeader: " + authorizationHeader)
            callback(null, authorizationHeader);
        }
    });
}

function getAuthConfig() {
    return (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? awsConfig.development : awsConfig.production;
}

function getToken(code) {
    return async.retry(1, (callback) => {
        let auth = getAuthConfig();
        httpsClient.post('https://' + auth.AppWebDomain + ":" + auth.AppWebPort + auth.AppWebTokenPath
        , qs.stringify({
            'grant_type'   : 'authorization_code',
            'client_id'    : auth.ClientId,
            'code'         : code,
            'redirect_uri' : auth.RedirectUriSignIn
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }).then((response) => {
            if(response.data && response.data.expires_in) {
                callback(null, Object.assign({}, response.data, {expires_at: Date.now() + (response.data.expires_in * 1000)}));
            } else {
                callback(null, response.data);
            }
        }).catch((error) => {
            console.log(error);
            callback(error);
        });
    });
}

function refreshToken(payload) {
    return async.retry(1, (callback) => {
        _refreshToken(payload.refreshToken, payload.email, callback);
    });
}

function _refreshToken(refreshToken, email, callback) {
    let auth = getAuthConfig();
    httpsClient.post('https://' + auth.AppWebDomain + ":" + auth.AppWebPort + auth.AppWebTokenPath
    , qs.stringify({
        'grant_type'   : 'refresh_token',
        'client_id'    : auth.ClientId,
        'refresh_token': refreshToken
    }), {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    }).then((response) => {
        if(response.data && response.data.expires_in) {
            callback(null, Object.assign({}, response.data, {
                expires_at: Date.now() + (response.data.expires_in * 1000),
                email     : email}));
        } else {
            callback(null, Object.assign({}, response.data, {email: email}));
        }
    }).catch((error) => {
        console.log(error);
        callback(error);
    });
}

function logout() {
    let auth = getAuthConfig();    
    return httpsClient.get(
        'https://'         + auth.AppWebDomain + ":" + auth.AppWebPort + auth.AppWebLogoutPath + 
            '&client_id='  + auth.ClientId           + 
            '&logout_uri=' + auth.RedirectUriSignOut, {
        headers: {
            'Access-Control-Allow-Origin': '*',
        }
    }).then((response) => {
        return response.data
    }).catch((error) => {
        console.log(error);
    });
}

function userInfo(tokenPayload) {
    return async.retry(1, (callback) => {
        let auth = getAuthConfig();
        const authorization = tokenPayload ? tokenPayload.token_type + ' ' + tokenPayload.access_token : '';

        httpsClient.post('https://' + auth.AppWebDomain  + ":" + auth.AppWebPort + auth.AppWebInfoPath, {}, {
            'headers' : { 
                'Authorization': authorization,
                'Content-Type' : 'application/json',
                'charset'      : 'UTF-8'
            } 
        }).then((response) => {
            if(response.data) {
                callback(null, Object.assign({}, tokenPayload, {
                    email : response.data.email, 
                    url   : response.data["custom:url"]}));
            } else {
                callback(null, tokenPayload);                
            }
        }).catch((error) => {
            console.log(error);
            callback(null, tokenPayload);
        });
    });
}

function* listDocumentWorkerSaga(action) {
    try {
        const authPayload = yield call(buildAuthorizationHeader);
        const payload     = yield call(listDocuments, {auth: authPayload});

        yield put(listDocumentsReceive(payload));
    } catch (e) {
        yield put(apiError(e));
    }
}

function* deleteDocumentWorkerSaga(action) {
    try {
        const authPayload   = yield call(buildAuthorizationHeader);
        const deletePayload = yield call(deleteDocument, {auth: authPayload, key: action.payload});
        const listPayload   = yield call(listDocuments , {auth: authPayload, deletePayload: deletePayload });
        
        yield put(listDocumentsReceive(listPayload));
    } catch (e) {
        yield put(apiError(e));
    }
}

function* uploadDocumentWorkerSaga(action) {
    try {
        const authPayload   = yield call(buildAuthorizationHeader);
        const uploadPayload = yield call(uploadDocument, {auth: authPayload, file: action.payload});
        const listPayload   = yield call(listDocuments , {auth: authPayload, uploadPayload: uploadPayload });

        yield put(listDocumentsReceive(listPayload));
    } catch (e) {
        yield put(apiError(e));
    }
}

function* downloadDocumentWorkerSaga(action) {
    try {
        const authPayload     = yield call(buildAuthorizationHeader);
        const downloadPayload = yield call(downloadDocument, {auth: authPayload, key: action.payload});

        yield put(downloadDocumentReceive(downloadPayload));
    } catch (e) {
        yield put(apiError(e));
    }
}

function listDocuments(payload) {
    return async.retry(1, (callback) => {
        httpsClient.get(getJWTObject().url + '/bucket', { 
            'headers' : { 
                'Authorization' : payload.auth,
                'Content-Type'  : 'application/json',
            } 
        }).then((response) => {
            var rData = response.data && (typeof response.data === 'string' || response.data instanceof String)?
                    JSON.parse(response.data) : 
                    response.data;
            callback(rData ? null : "Message not found!", rData);
        }).catch((error) => {
            callback(error);
        });
    });
}

function downloadDocument(payload) {
    return async.retry(1, (callback) => {
        httpsClient.get(getJWTObject().url + '/bucket?key=' + payload.key, { 
            transformResponse: (r) => r,
            'headers' : { 
                'Authorization' : payload.auth,
                'Content-Type'  : 'application/json',
            } 
        }).then((response) => {
            callback(response.data ? null : "Message not found!", {name: payload.key, data: response.data} );
        }).catch((error) => {
            callback(error);
        });
    });
}

function deleteDocument(payload) {
    return async.retry(1, (callback) => {
        httpsClient.delete(getJWTObject().url + '/bucket?key=' + payload.key, { 
            'headers' : { 
                'Authorization' : payload.auth,
                'Content-Type'  : 'application/json',
            } 
        }).then((response) => {
            callback(null, "Delete");
        }).catch((error) => {
            callback(error);
        });
    });
}

function uploadDocument(payload) {
    return async.retry(1, (callback) => {
        var reader = new FileReader();
        reader.addEventListener('load', (event) => {
            let fileBlob = new Blob([ event.target.result ], { type: payload.file.type });

            let formData = new FormData();
            formData.append("files", fileBlob, payload.file.name);

            httpsClient.post(getJWTObject().url + '/bucket', formData, { 
                'headers' : { 
                    'Authorization' : payload.auth,
                    'content-type'  : 'multipart/form-data',
                } 
            }).then((response) => {
                callback(null, "Upload");
            }).catch((error) => {
                callback(error);
            });
        });
        reader.readAsText(payload.file);
    });
}
