export default {

    queries : [{
        
        title   : "Query - checkCredibility [since v4.40]",

        label   : "checkCredibility", 

        path    : "loan_examples_checkCredibility",

        message :
`query {
    checkCredibility(loanRequest: {
        loanApplication: {
            id: 1
            user: "abit"
            created: "2019-07-10"
            modified: "2019-07-10"
            description: "test description"
            intendedUse: "test"
            expectedRate: 6.2
            submitted: false
            offerNumber: "789ONR"
            contractNumber: "789CNR"
            lastStage: "false"
            amount: 3499.98
            interestRate: 4.1
            effectiveInterestRate: 5.9
            term: 24
            loanToBeRestructured: {
                creditTypeKey: "001"
                interest: 5.7
                loanAmount: 200000
                remainingTermMonths: 24
                residualDebt: 100000
                iban: "DE8990048888711285"
                bic: "DEUDB"
            }
        }
        applicant: {
            customerNo: "C000000001"
            customerID: "56"
            salutationKey: "1"
            firstName: "Ursula"
            lastName: "Müller"
            dateOfBirth: "1981-05-17"
            nationalityKey: "004"
            mainAddress: {
                street: "Hauptstrasse"
                streetNo: "45A"
                zip: "27499"
                city: "Hamburg"
                countryKey: "004"
            }
            maritalStatusKey: "Ledig"
            numberOfChildren: 0
            numberOfAdults: 1
            occupation: "QS"
            occupationSince: "2005-09-01"
            contactDetails: {
                eMail:  "ursula.mueller@gmail.com"
                mobilePhoneNo: "+491723456789"
            }
            schufaAgreement: "2019-02-03"
            incomeExpenses: [{
                amount: 7000.0
                periodsPA: 12
                id: "SALARY"
                type: INCOME
            }, {
                periodsPA: 12
                id: "GROSS_INCOME"
                type: INCOME
            }, {
                periodsPA: 12
                id: "EXPECTED_AMORT"
                type: INCOME
            }, {
                periodsPA: 12
                id: "GROSS_INCOME_EMPL"
                type: INCOME
            }, {
                amount: 0.0
                periodsPA: 12
                id: "CHILD_BENEFIT"
                type: INCOME
            }, {
                amount: 1000.0
                periodsPA: 12
                id: "RENTAL_INCOME"
                type: INCOME
            }, {
                amount: 2000.0
                periodsPA: 12
                id: "PENSIONS"
                type: INCOME
            }, {
                amount: 1000.0
                periodsPA: 12
                id: "CAPITAL_INCOME"
                type: INCOME
            }, {
                amount: 2002.0
                periodsPA: 12
                id: "OTHER_INCOME"
                type: INCOME
            }, {
                amount: 3.0
                periodsPA: 12
                id: "COST_OF_LIVING"
                type: EXPENSE
            }, {
                periodsPA: 12
                id: "PVT_HEALTH_INSURANCE"
                type: EXPENSE
            }, {
                periodsPA: 12
                id: "PVT_PROVISIONS"
                type: EXPENSE
            }, {
                amount: 1000.0
                periodsPA: 12
                id: "RENTAL_EXPENSE"
                type: EXPENSE
            }, {
                amount: 100.0
                periodsPA: 12
                id: "LEASING"
                type: EXPENSE
            }, {
                amount: 200.0
                periodsPA: 12
                id: "INSURANCE"
                type: EXPENSE
            }, {
                amount: 300.0
                periodsPA: 12
                id: "CAR_COSTS"
                type: EXPENSE
            }, {
                amount: 100.0
                periodsPA: 12
                id: "ALIMONY"
                type: EXPENSE
            }, {
                amount: 2099.0
                periodsPA: 12
                id: "SAVING_RATES"
                type: EXPENSE
            }, {
                amount: 10.0
                periodsPA: 12
                id: "OTHER_EXPENSES"
                type: EXPENSE
            }]
            tin: "ttiinn"
        }
        documentSections: [{
            name: "Docs"
            documents: [{
                ID: 1
                name: "scan_567.PDF"
                fileReference: "scan_567.PDF"
                title: "Ausweiss"
                contentType: "PDF"
                status: OPEN
            }, {
                ID: 2
                name: "scan_568.PDF"
                fileReference: "scan_568.PDF"
                title: "Mietvertrag"
                contentType: "PDF"
                status: OPEN
            }]
        }]
    }) {
        resultCode
        messages
        message
        responseCode
    }
}`

    }, {
        
        title   : "Query - customerSearch [since v4.40]",

        label   : "customerSearch", 

        path    : "loan_examples_customerSearch",

        message :
`query {
    customerSearch(userID: "aha", customerID: "34", customerNumber: "67") {
        userID
        status
        customerID
        customerNumber
        message
        responseCode
    }
}`

    }, {
        
        title   : "Query - retrieveLoanConditions [since v4.40]",

        label   : "retrieveLoanConditions", 

        path    : "loan_examples_retrieveLoanConditions",

        message :
`query {
    retrieveLoanConditions {
        productTypes {
            key
            name
            minAmount
            maxAmount
            stepsizeAmount
            minTenor
            maxTenor
            stepsizeTenor
            conditions {
                validTo
                amount
                term
                score
                nomInterestRate
                effInterestRate
            }
        }        
        message
        responseCode
    }
}`
    }],

    mutations: [{

        title   : "Mutation - submitLoanApplication [since v4.40]",

        label   : "submitLoanApplication", 

        path    : "loan_examples_submitLoanApplication",

        message :
`mutation {
    submitLoanApplication(loanRequest: {
        loanApplication: {
            id: 1
            user: "abit"
            created: "2019-07-10"
            modified: "2019-07-10"
            description: "test description"
            intendedUse: "test"
            expectedRate: 6.2
            submitted: false
            offerNumber: "789ONR"
            contractNumber: "789CNR"
            lastStage: "false"
            amount: 3499.98
            interestRate: 4.1
            effectiveInterestRate: 5.9
            term: 24
        }
        applicant: {
            customerNo: "C000000001"
            customerID: "56"
            salutationKey: "1"
            firstName: "Ursula"
            lastName: "Müller"
            dateOfBirth: "1981-05-17"
            nationalityKey: "004"
            mainAddress: {
                street: "Hauptstrasse"
                streetNo: "45A"
                zip: "27499"
                city: "Hamburg"
                countryKey: "004"
            }
            maritalStatusKey: "Ledig"
            numberOfChildren: 0
            numberOfAdults: 1
            occupation: "QS"
            occupationSince: "2005-09-01"
            contactDetails: {
                eMail:  "ursula.mueller@gmail.com"
                mobilePhoneNo: "+491723456789"
            }
            schufaAgreement: "2019-02-03"
            incomeExpenses: [{
                amount: 7000.0
                periodsPA: 12
                id: "SALARY"
                type: INCOME
            }, {
                periodsPA: 12
                id: "GROSS_INCOME"
                type: INCOME
            }, {
                periodsPA: 12
                id: "EXPECTED_AMORT"
                type: INCOME
            }, {
                periodsPA: 12
                id: "GROSS_INCOME_EMPL"
                type: INCOME
            }, {
                amount: 0.0
                periodsPA: 12
                id: "CHILD_BENEFIT"
                type: INCOME
            }, {
                amount: 1000.0
                periodsPA: 12
                id: "RENTAL_INCOME"
                type: INCOME
            }, {
                amount: 2000.0
                periodsPA: 12
                id: "PENSIONS"
                type: INCOME
            }, {
                amount: 1000.0
                periodsPA: 12
                id: "CAPITAL_INCOME"
                type: INCOME
            }, {
                amount: 2002.0
                periodsPA: 12
                id: "OTHER_INCOME"
                type: INCOME
            }, {
                amount: 3.0
                periodsPA: 12
                id: "COST_OF_LIVING"
                type: EXPENSE
            }, {
                periodsPA: 12
                id: "PVT_HEALTH_INSURANCE"
                type: EXPENSE
            }, {
                periodsPA: 12
                id: "PVT_PROVISIONS"
                type: EXPENSE
            }, {
                amount: 1000.0
                periodsPA: 12
                id: "RENTAL_EXPENSE"
                type: EXPENSE
            }, {
                amount: 100.0
                periodsPA: 12
                id: "LEASING"
                type: EXPENSE
            }, {
                amount: 200.0
                periodsPA: 12
                id: "INSURANCE"
                type: EXPENSE
            }, {
                amount: 300.0
                periodsPA: 12
                id: "CAR_COSTS"
                type: EXPENSE
            }, {
                amount: 100.0
                periodsPA: 12
                id: "ALIMONY"
                type: EXPENSE
            }, {
                amount: 2099.0
                periodsPA: 12
                id: "SAVING_RATES"
                type: EXPENSE
            }, {
                amount: 10.0
                periodsPA: 12
                id: "OTHER_EXPENSES"
                type: EXPENSE
            }]
            tin: "ttiinn"
        }
        documentSections: [{
            name: "Docs"
            documents: [{
                ID: 1
                name: "scan_567.PDF"
                fileReference: "scan_567.PDF"
                title: "Ausweiss"
                contentType: "PDF"
                status: OPEN
            }, {
                ID: 2
                name: "scan_568.PDF"
                fileReference: "scan_568.PDF"
                title: "Mietvertrag"
                contentType: "PDF"
                status: OPEN
            }]
        }]
    }) {
        customerNumber
        offerNumber
        offer {
            ID
            name
            fileReference
            title
            contentType
            status
        }
        customerID
        loanApplicationID
        queueID
        message
        responseCode
    }
}`

    }, {

        title   : "Mutation - submitDocuments [since v4.40]",

        label   : "submitDocuments", 

        path    : "loan_examples_submitDocuments",

        message :
`mutation {
    submitDocuments(documents: {
        customerNumber: "C000000001"
        inquiryID: "5"
        documents: [{
            ID: 1
            name: "scan_567.PDF"
            fileReference: "scan_567.PDF"
            title: "Ausweiss"
            contentType: "PDF"
            status: OPEN
        }, {
            ID: 2
            name: "scan_568.PDF"
            fileReference: "scan_568.PDF"
            title: "Mietvertrag"
            contentType: "PDF"
            status: OPEN
        }]
    }) {
        message
        responseCode
    }
}`

    }, {

        title   : "Mutation - submitSignedLoanContract [since v4.40]",

        label   : "submitSignedLoanContract", 

        path    : "loan_examples_submitSignedLoanContract",

        message :
`mutation {
    submitSignedLoanContract(queueID: 5, loanContract: {
        customerNumber: "C000000001"
        offerNumber: "5"
        signId: "ttz6"
        signedOffer: {
            ID: 3
            name: "scan_569.PDF"
            fileReference: "scan_569.PDF"
            title: "LoanContract"
            contentType: "PDF"
            status: OPEN
        }
    }) {
        message
        responseCode
    }
}`

    }], jobs: [], files:[], workflows:[], blanks:[], events:[]
 
};
