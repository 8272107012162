export default {

	files: [], mutations :[], queries:[], jobs:[], workflows:[],
	events: [{
        title: "Events - recieve [since v4.71]",

		label: "recieveEvents",

		path: "v2_examples_recieveEvents",

		message: ""
	},
	{
        title: "Events - send [since v4.76]",

		label: "sendEvent",

		path: "v2_examples_sendEvent",

		message: `mutation {
  sendEvent (
   objectId: "-2285d00c:18a50c52b8c:-7fcd"
   objectType: "Customer"
   key: "000"
  ) {
   responseStatus
  }  
}`
	}], blanks:[]
}