const styles = theme => ({
    root: {
        display: 'flex',
        width: '100%',
    },
	paper: {
        fontFamily: 'Poppins-Regular',
        fontSize: '.875rem',
        fontWeight: '400',
        lineHeight: '1.43',
        color: '#212121',
        marginTop: '11px',
        marginBottom: '11px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
        borderRadius: '4px',
        padding: '20px 10px 10px 10px',
        /*overflow: 'auto',*/
        /*height: '85%',*/
        /*width: '97%',*/
	},
    title: {
        fontFamily: 'Poppins-Regular',
        fontSize: '1.4rem',
        fontWeight: '400',
        lineHeight: '1.5',
        verticalAlign: 'middle',
        padding: '5px 5px 23px 5px',
        width: '100%',
        textAlign: 'center',
        display: 'block',
    },
    heading: {
        minWidth: '400px',
        width: '48%',
        padding: '0px 0px 0px 5px',
    },
    headingTitle: {
        fontFamily: 'Poppins-Regular',
        fontSize: '1.1rem',
        fontWeight: '600',
        lineHeight: '1.5',
        verticalAlign: 'middle',
        padding: "20px 0px 0px 0px",
        display: "block",
    },
    exampleTitle: {
        fontFamily: 'Poppins-Regular',
        fontSize: '1.1rem',
        fontWeight: '600',
        lineHeight: '1.5',
        verticalAlign: 'middle',
    },
    text: {
        fontFamily: 'Poppins-Regular',
        fontSize: '1.0rem',
        fontWeight: '400',
        lineHeight: '1.5',
        display: 'block',
        padding: '10px 0px 0px 0px',
    },
    example: {
        display: 'block',
        padding: '23px 0px 0px 0px',
        //width: '1300px',
    },
    runExample: {
        fontFamily: 'Poppins-Regular',
        fontSize: '.9rem',
        fontWeight: '400',
        lineHeight: '1.1',
        float: 'right',
        margin: '3px 3px 5px 3px',
        padding: '5px 7px 5px 7px'
    },
    buttonUpload: {
        fontFamily: 'Poppins-Regular',
        fontSize: '.9rem',
        fontWeight: '400',
        lineHeight: '1.1',
        float: 'right',
        margin: '3px 3px 5px 3px',
        padding: '5px 7px 5px 7px'
    },
    generateExample: {
        fontFamily: 'Poppins-Regular',
        fontSize: '.9rem',
        fontWeight: '400',
        lineHeight: '1.1',
        float: 'right',
        margin: '3px 30px 5px 3px',
        padding: '5px 7px 5px 7px'
    },
    containerCodeResult: {
        display: 'flex',
        minWidth: '820px',
    },
    containerDocuments: {
        display: 'block',
        minWidth: '100%',
        border: '1px solid #ddd',
        fontFamily: 'Poppins-Regular',
        fontSize: '1.1rem',
        lineHeight: '1.1',
        maxWidth: '100%',
        background: '#f4f4f4',
        padding: '15px 15px 15px 25px',
        boxSizing: 'border-box',
    },
    code: {
        minWidth: '400px',
        width: '48%',
        whiteSpace: 'pre',
        border: '1px solid #ddd',
        borderLeft: '3px solid #2e32ae',
        pageBreakInside: 'avoid',
        fontFamily: 'Poppins-Regular',
        fontSize: '.9rem',
        lineHeight: '1',
        maxWidth: '100%',
        display: 'block',
        wordWrap: 'break-word',
        background: '#f4f4f4',
        padding: '15px 15px 15px 25px',
        boxSizing: 'border-box',
        overflow: 'auto',
    },
    codeText: {
        minWidth: '400px',
        width: '48%',
        maxWidth: '100%',
        fontFamily: 'Poppins-Regular',
        fontSize: '1.0rem',
        fontWeight: '400',
        lineHeight: '1.5',
        display: 'block',
        padding: '10px 0px 0px 0px',
    },
    httpCode: {
        width: '100%',
        whiteSpace: 'pre-wrap',
        border: '1px solid #ddd',
        borderLeft: '3px solid #2e32ae',
        fontFamily: 'Poppins-Regular',
        fontSize: '.9rem',
        lineHeight: '1',
        display: 'block',
        background: '#f4f4f4',
        padding: '15px 15px 15px 25px',
        boxSizing: 'border-box',
    },
    result: {
        minWidth: '400px',
        width: '48%',
        whiteSpace: 'pre',
        border: '1px solid #ddd',
        borderLeft: '3px solid #2e32ae',
        pageBreakInside: 'avoid',
        fontFamily: 'Poppins-Regular',
        fontSize: '.9rem',
        lineHeight: '1',
        maxWidth: '100%',
        display: 'block',
        wordWrap: 'break-word',
        background: '#f4f4f4',
        padding: '15px 15px 15px 25px',
        margin: '0px 0px 0px 20px',
        boxSizing: 'border-box',
        overflow: 'auto',
        maxHeight: '544px',
    },
    resultText: {
        minWidth: '400px',
        width: '48%',
        maxWidth: '100%',
        fontFamily: 'Poppins-Regular',
        fontSize: '1.0rem',
        fontWeight: '400',
        lineHeight: '1.5',
        display: 'block',
        padding: '10px 0px 0px 0px',
        margin: '0px 0px 0px 20px',
    },
    workarea: {
        padding: '0px 20px 0px 20px',
        overflow: 'auto',
        width: '100%',
        height: '100%',
    },
    sidebarExample: {
        boxShadow: '0 1px 1px rgba(0,0,0,.15)',
        backgroundColor: theme.palette.primary.light,
        overflow: 'auto',
        minWidth: '320px',
        height: '100%',
    },
    buttonResetExample : {
	    fontFamily: 'Poppins-Regular',
	    backgroundColor: '#2e32ae',
        margin: '10px 10px 10px 10px',
        width: '228px',     
    },
    buttonShowDocuments : {
        margin: '10px 10px 10px 10px',
        width: '228px',        
    },
    image: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '25px 0px 25px 0px',
    },
    objectEditor: {
	    fontFamily: 'Poppins-Regular',
        border: '1px solid #CACACA', 
        margin: '0px 10px 0px 0px',
        padding: '0px 0px 10px 10px',    
    },
    objectEditorTitle: {
	    fontFamily: 'Poppins-Regular',
        position: 'relative',
        top: '-12px',
        background: 'white',
        padding: '0px 10px 0px 10px',
    },
    refreshButton: {
	    margin: '0px 0px 0px 10px'
    }
});

export default styles;