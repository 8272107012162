import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles }          from '@material-ui/core/styles';
import Button                  from '@material-ui/core/Button';
import AddCircleOutlineIcon    from '@material-ui/icons/AddCircleOutline';
import RefreshIcon             from '@material-ui/icons/Refresh';
import List                    from '@material-ui/core/List';
import ListItem                from '@material-ui/core/ListItem';
import ListItemAvatar          from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText            from '@material-ui/core/ListItemText';
import IconButton              from '@material-ui/core/IconButton';
import CloudDownloadIcon       from '@material-ui/icons/CloudDownload';
import DeleteIcon              from '@material-ui/icons/Delete';

import styles    from '../styles';

class Documents extends Component {
    state = {
        documents: []
    };

    componentDidMount() {
        this.props.listDocuments();
    }

    componentDidUpdate(prevProps) {
        if(this.props.document) {
            // 2. Create blob link to download
            const url = window.URL.createObjectURL(new Blob([this.props.document.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.props.document.name);  

            // 3. Append to html page
            document.body.appendChild(link);  

            // 4. Force download
            link.click();  

            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);            
        }
    }

    handleChange = (event) => {
        event.preventDefault();
        let file = event.target.files[0];
        if(file) {
            this.props.uploadDocument(file);
        }
    };

    render() {
        const { id, classes } = this.props;
        let documents = this.props.documents ? this.props.documents : [];
        return (
            <div id={id} className={classes.example}>
                <div className={classes.heading}>
                    <span className={classes.exampleTitle}>Documents</span>
                </div>
                <div className={classes.containerDocuments}>
                    <List dense={true}>
                        {documents.map((document, idx) => {
                            return (
                                <ListItem key={idx}>
                                    <ListItemAvatar>
                                        <IconButton edge="end" aria-label="download" onClick={event => this.props.downloadDocument(document)}>
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </ListItemAvatar>
                                    <ListItemText primary={document} />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="delete" onClick={event => this.props.deleteDocument(document)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                    
					<input accept="*/*" style={{ display: "none" }} id="contained-button-file" type="file"
						onChange={event => this.handleChange(event)} />
					<label htmlFor="contained-button-file">
						<Button variant="contained" color="secondary" disabled={!this.props.auth || (this.props.auth.expires_at && this.props.auth.expires_at < Date.now())} component="span">
							upload &nbsp; <AddCircleOutlineIcon />
						</Button>
					</label>
					
				    <Button className={classes.refreshButton} variant="contained" color="secondary" disabled= {!this.props.auth || (this.props.auth.expires_at && this.props.auth.expires_at < Date.now())} 
				     component="span" onClick={() => this.componentDidMount()} >
						<RefreshIcon />
					</Button>

                </div>                
            </div>
        );
    }
}

Documents.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Documents);
