import React, { Component } from "react";
import { connect, Provider } from "react-redux";

import PropTypes from "prop-types";

import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import theme from "./theme";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Examples from "./components/Examples";

import Architecture from "./content/gettingstarted/Architecture";
import HttpLevelAccess from "./content/gettingstarted/HttpLevelAccess";
import BluePrint from "./content/gettingstarted/BluePrint";

import GraphQLSchemaV2OpenCreditManager from "./content/graphql/GraphQLSchemaV2OpenCreditManager";
import GraphQLSchemaV1OpenCreditManager from "./content/graphql/GraphQLSchemaV1OpenCreditManager";

import V2CreditManagerExamplesData from "./content/examples/ExamplesV2";
import SchedulerJobExamplesData from "./content/examples/ExamplesSchedulerJob";
import WorkflowExamplesData from "./content/examples/ExamplesWorkflow";
import FileSharingExamplesData from "./content/examples/ExamplesFileSharing";
import ExamplesEventsData from "./content/examples/ExamplesEvents";
import CreditManagerExampleData from "./content/examples/ExamplesCreditManagement";
import OpenCreditExampleData from "./content/examples/ExamplesOpenCredit";
import CNRExampleData from "./content/examples/ExamplesCNR";

import CreditManagerCallSequence from "./content/callsequence/CNRCallSequence";
import LoanCallSequence from "./content/callsequence/LoanCallSequence";

import Callback from "./content/Callback";
import Signout from "./content/Signout";

import AIInferenceAPI from "./content/ai/AIInferenceAPI";

import exampleStore from "./connect/store";
import {
  exampleSend,
  listDocumentsSend,
  deleteDocumentSend,
  uploadDocumentSend,
  downloadDocumentSend,
  recieveEventsRequest,
} from "./connect/actions";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  workarea: {
    //position: 'fixed',
    top: "79px",
    width: "100%",
    height: "100%",
    display: "flex",
    //backgroundColor: '#f8f9fa',
    backgroundColor: "#f1f5f6",
  },
});

const ConnectedHeader = connect((state) => {
  return { auth: state.authenticationReducer };
}, {})(Header);

const CreditManagerExamples = connect(
  (state) => {
    return {
      data: state.exampleReducer,
      auth: state.authenticationReducer,
      queries: CreditManagerExampleData.queries,
      mutations: CreditManagerExampleData.mutations,
      jobs: CreditManagerExampleData.jobs,
      files: CreditManagerExampleData.files,
      workflows: CreditManagerExampleData.workflows,
      blanks: CreditManagerExampleData.blanks,
      events: CreditManagerExampleData.events,
    };
  },
  {
    exampleSend,
    listDocumentsSend,
    deleteDocumentSend,
    uploadDocumentSend,
    downloadDocumentSend,
  }
)(Examples);

const V2CreditManagerExamples = connect(
  (state) => {
    return {
      data: state.exampleReducer,
      auth: state.authenticationReducer,
      queries: V2CreditManagerExamplesData.queries,
      mutations: V2CreditManagerExamplesData.mutations,
      jobs: V2CreditManagerExamplesData.jobs,
      files: V2CreditManagerExamplesData.files,
      workflows: V2CreditManagerExamplesData.workflows,
      blanks: V2CreditManagerExamplesData.blanks,
      events: V2CreditManagerExamplesData.events,
    };
  },
  { exampleSend, listDocumentsSend, deleteDocumentSend, uploadDocumentSend }
)(Examples);

const SchedulerJobExamples = connect(
  (state) => {
    return {
      data: state.exampleReducer,
      auth: state.authenticationReducer,
      queries: SchedulerJobExamplesData.queries,
      mutations: SchedulerJobExamplesData.mutations,
      jobs: SchedulerJobExamplesData.jobs,
      files: SchedulerJobExamplesData.files,
      workflows: SchedulerJobExamplesData.workflows,
      blanks: SchedulerJobExamplesData.blanks,
      events: SchedulerJobExamplesData.events,
    };
  },
  { exampleSend, listDocumentsSend, deleteDocumentSend, uploadDocumentSend }
)(Examples);

const FileSharingExamples = connect(
  (state) => {
    return {
      data: state.exampleReducer,
      auth: state.authenticationReducer,
      queries: FileSharingExamplesData.queries,
      mutations: FileSharingExamplesData.mutations,
      jobs: FileSharingExamplesData.jobs,
      files: FileSharingExamplesData.files,
      workflows: FileSharingExamplesData.workflows,
      blanks: FileSharingExamplesData.blanks,
      events: FileSharingExamplesData.events,
    };
  },
  { exampleSend, listDocumentsSend, deleteDocumentSend, uploadDocumentSend }
)(Examples);

const EventsExamples = connect(
  (state) => {
    return {
      data: state.exampleReducer,
      auth: state.authenticationReducer,
      queries: ExamplesEventsData.queries,
      mutations: ExamplesEventsData.mutations,
      jobs: ExamplesEventsData.jobs,
      files: ExamplesEventsData.files,
      workflows: ExamplesEventsData.workflows,
      blanks: ExamplesEventsData.blanks,
      events: ExamplesEventsData.events,
    };
  },
  { exampleSend, recieveEventsRequest }
)(Examples);

const CNRExamples = connect(
  (state) => {
    return {
      data: state.exampleReducer,
      auth: state.authenticationReducer,
      header: CNRExampleData.header,
      queries: CNRExampleData.queries,
      mutations: CNRExampleData.mutations,
      jobs: CNRExampleData.jobs,
      files: CNRExampleData.files,
      workflows: CNRExampleData.workflows,
      blanks: CNRExampleData.blanks,
      events: CNRExampleData.events,
    };
  },
  {
    exampleSend,
    listDocumentsSend,
    deleteDocumentSend,
    uploadDocumentSend,
    downloadDocumentSend,
  }
)(Examples);

const OpenCreditLoanExamples = connect(
  (state) => {
    return {
      data: state.exampleReducer,
      auth: state.authenticationReducer,
      queries: OpenCreditExampleData.queries,
      mutations: OpenCreditExampleData.mutations,
      jobs: OpenCreditExampleData.jobs,
      files: OpenCreditExampleData.files,
      workflows: OpenCreditExampleData.workflows,
      blanks: OpenCreditExampleData.blanks,
      events: OpenCreditExampleData.events,
    };
  },
  {
    exampleSend,
    listDocumentsSend,
    deleteDocumentSend,
    uploadDocumentSend,
    downloadDocumentSend,
  }
)(Examples);

const WorkflowExamples = connect(
  (state) => {
    return {
      data: state.exampleReducer,
      auth: state.authenticationReducer,
      queries: WorkflowExamplesData.queries,
      mutations: WorkflowExamplesData.mutations,
      jobs: WorkflowExamplesData.jobs,
      files: WorkflowExamplesData.files,
      workflows: WorkflowExamplesData.workflows,
      blanks: WorkflowExamplesData.blanks,
      events: WorkflowExamplesData.events,
    };
  },
  {
    exampleSend,
    listDocumentsSend,
    deleteDocumentSend,
    uploadDocumentSend,
    downloadDocumentSend,
  }
)(Examples);

const ConnectedCallback = connect((state) => {
  return { auth: state.authenticationReducer };
}, {})(Callback);

class App extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Router
        basename={
          process.env.PUBLIC_URL ? new URL(process.env.PUBLIC_URL).pathname : ""
        }
      >
        <MuiThemeProvider theme={theme}>
          <Provider store={exampleStore}>
            <ConnectedHeader />
            <div className={classes.workarea} id="workarea">
              <Switch>
                <Route
                  path={"/gettingstarted/architecture"}
                  component={Architecture}
                />
                <Route
                  path={"/gettingstarted/httpLevelAccess"}
                  component={HttpLevelAccess}
                />
                <Route
                  path={"/gettingstarted/blueprint"}
                  component={BluePrint}
                />
                <Route
                  path={"/callsequence/score"}
                  component={CreditManagerCallSequence}
                />
                <Route
                  path={"/graphql/v2"}
                  component={GraphQLSchemaV2OpenCreditManager}
                />
                <Route
                  path={"/examples/v2"}
                  component={V2CreditManagerExamples}
                />
                <Route
                  path={"/examples/startJobV2"}
                  component={SchedulerJobExamples}
                />
                <Route
                  path={"/examples/workflowsV2"}
                  component={WorkflowExamples}
                />
                <Route
                  path={"/examples/fileSharingV2"}
                  component={FileSharingExamples}
                />
                <Route path={"/examples/eventsV2"} component={EventsExamples} />
                <Route
                  path={"/examples/score"}
                  component={CreditManagerExamples}
                />
                <Route
                  path={"/examples/collectAndRecovery"}
                  component={CNRExamples}
                />
                <Route
                  path={"/callsequence/originate"}
                  component={LoanCallSequence}
                />
                <Route
                  path={"/examples/originate"}
                  component={OpenCreditLoanExamples}
                />
                <Route path={"/ai"} component={AIInferenceAPI} />
                <Route
                  path={"/graphql/v1"}
                  component={GraphQLSchemaV1OpenCreditManager}
                />
                <Route path={"/callback"} component={ConnectedCallback} />
                <Route path={"/signout"} component={Signout} />
                <Route path={"/"} component={Architecture} />
              </Switch>
            </div>
          </Provider>
          <Footer />
        </MuiThemeProvider>
      </Router>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
