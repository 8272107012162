/* eslint-disable no-useless-escape */

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Sidebar from '../../components/Sidebar';

import styles from '../../styles';

class BluePrint extends Component {

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Sidebar title="Blue Print" samePageScroll={true} model={[
                        { label: "Import customer unit"           , path: "blueprint_ImportCustomerUnit"      }, 
                        { label: "Customer moves to a new address", path: "blueprint_ChangeCustomerAddress"   }, 
                        { label: "Change account holder"          , path: "blueprint_ChangeAccountHolder"     }, 
                        { label: "Change real estate owner"       , path: "blueprint_ChangeRealEstateOwner"   }]}/>
                <div className={classes.workarea} id ="content">
                    <div className={classes.paper}>
                        <span className={classes.title}>Blue Print</span>
                        <div id="blueprint_ImportCustomerUnit">
                            <span className={classes.headingTitle}>Import customer unit</span>

                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Get OAuth2 token</span>
                                <span className={classes.resultText}>Response, use IdToken</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request POST 'https://cognito-idp.eu-central-1.amazonaws.com:443' \\
--header 'Content-Type: application/x-amz-json-1.1' \\
--header 'X-Amz-Target: AWSCognitoIdentityProviderService.InitiateAuth' \\
--data-raw '{\\
    "AuthParameters" : {\\
        "USERNAME" : "develop@aryza.com",\\
        "PASSWORD" : "xxxxxxxx"\\
    },\\
    "AuthFlow" : "USER_PASSWORD_AUTH",\\
    "ClientId" : "xxxxxxxxxxxxxxxxxxxxxxxxxx"\\
}'`}
                                </span>
                                <span className={classes.result}>
{`{
    "AuthenticationResult": {
        "AccessToken": "eyJraWQiOiJpSHdvTFNaa1RQSkdRYTFtQ1lcL3ZlbTV5aXFYZUt4MDdOQ3grUVpBVmVjbz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuZXUtY2VudHJhbC0xLmFtYXpvbmF3cy5jb21cL2V1LWNlbnRyYWwtMV92NW5yV2NhZnAiLCJjbGllbnRfaWQiOiI1c25ocWtyMDExNzBhdWFhajNvbmtmdHRlNyIsIm9yaWdpbl9qdGkiOiI4ZDc5MTkxNS1iOTUxLTRjNDEtYjk4Ny1iOTIwZmI2M2E2ZDAiLCJldmVudF9pZCI6IjFkNGNmYTkyLTU0ZDItNGFkNy1iOWRkLTQwNjgyMWUzNzZjMCIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2ODM3MjY3ODUsImV4cCI6MTY4MzczMDM4NSwiaWF0IjoxNjgzNzI2Nzg1LCJqdGkiOiI0NzdmNTE3ZS05MWI4LTQ1ZWItYjFiNy0zN2M3NWZmZTFhODIiLCJ1c2VybmFtZSI6ImNoZWNrMjQifQ.3yVG5ZaS1ckBxt7UNheFmDGkMZVk3WTB1_0rTbXzq7qor0FT-W2VHRl5zfV6fv05Qk36MQle-QtMzmHAb3-upKnpoeGD58Al-8fTXJpA6Bh51bOPeMj41AxpZI16qv5FUMKrb7JbNfW5UpIjFDV4KzegL0ng5IjQn5poDHh6ez5hQ86JNfIqz01PKDrgEN8Z6_D7i7v362DMVTR77nfHDuVE2TddHVfBIQA9njAZngLWauNHVYcMAHFON9yby_RdxJDTarp6s3arNE6f6j7ysmSTQ3iBwpZdYaoQyXhkwd049PGQF85aoLUmJCwlkIp4CXvPX8rBbIEpNjI8cUHOPw",
        "ExpiresIn": 3600,
        "IdToken": "eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w",
        "RefreshToken": "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.Nheaf8dUzrMdzUk257w_LPEeKSaKg4C2oXQPw3RHfl-gARjGcR9oHZfEsBBDxsHMf126NsmlE4VBYKm8HRINYO20eMDcykR8aYq7ol1UkLe0OyJ262_evXoWSVj8k_xhDeO8Do6Uu6q0Ew_snlcOeqcBfKVOsWv4tlR1tSCyicmQI1JEkPl_qoV_EepyAO9rXbnUK-4YJeSSC4fJTdCIbBWFC5bHd44oAwtiuqeaJ7L_Qreigjs2nzvUGmHYvqtgsIOHHPmoaO9qlUcQkSar-Xqdf6Nfz2H27vtzKnle20EvCsVgOyMI9Vo0A1_jUVK3ADNvVuqpwuPjdUAS3lNOkQ.t0ZHHeVHKWntWgQ3.95vfVyH3K-9lGTYOV0IFcETxnlj5Wsniex-sryCKNtJISm5lmyQmSnc2FBp4QQWgFzEUD_p0RVEgPKsX_8CUDP7PhsljpULcJjxUX3fB-DZLwvwqlvfRoBEzxgLESCFjaxfeHdtJGuyf2ZUtzaC6c98e4vaWBArv6n9Zb_JtgzPgV-49EU6gpTa_L6_PoBr13MXV3ZOmvlEhmXjYZb1lbc9uIn0rt7Twxn9e4H1md4MJnaFck6vmUyrLSmT0uUTfmTtP8pTFvAHs09Bbt4KIpT35_8WNWbSXGh04akRbBdluMor0f40ojvOqfSfbkrbmH9h_2rXDd8G3EiNgZhc0RqMf974t9_zSKlUw0L2qoErKwkk--rMLvCWYU33JPVMEnTLuUnxeGj1_OUWXQAo9rfNcbfrskA16T-mIhmpE6H9HvQZeD4pyh87ODjhfNZZjTevsoFDjYwc-qFfILFNHqYIdWmVMPyzViYlRrEbEZ5n6ldsiuCEzZZVmNQtl3V9ikjUi1k99xFWRFmwYXhgwqV9RmhS6YCff9HMD7AGsh35Ywv31fWlwYXkay9yzbYOOQfsMQslih8BpS1btiPLKOtBbKKA7hfOSFeZVHinRZMCMfhz6ahsiuNNQka0HNDYPMFpsZhw5YQMFDZ0-NOVP_sCIYpPSUOJP7p4fv20u2R-dy-WD_tJqCNGyAZqosavqA1iZUj_zmNxg2kR2OLDlHiAnXsmoYahFnpxiK0PYg2_Um7qCjCR0MQ6MAn7Ns57T6jWJeC6-n4SDWq7AgpnFCq5Hv5Gz60Z29nCWHYoQzW2ruL2Q5KaP-NbAbtrNLwFctUQAc6vDl4t6MBjWOcDpllwy7wVH0PM5b4_z0lB-urzEN9xniwb5ghph0XYfDIBsVhEwBXlJIvCFL4oyory7xuto_qZjCFn_ojej1prr00yq5fnxjNGV3OpPcW_K33LOkUqaX-RQIxBSoy0j21Yg-7T8Su-6vC3q3l4_1UGFfl_gBMtNPvyHML_hVXrS5lnNqvfRPmOkRiKL4vVFlE0DsnU1PL_WNob0kisIgfq-sfkIBBL061-Ldms2aa-uUs4jZxemUfQiay56apdXkB7gTpgIe0ljKnA0uvqZJkdCo3boQfF4uUFPohfYHDP1dzCy19FT5_AI-i0dPQSY5oBYOyo9lstGXoB--FZh_ubaWtk3ojE1juxHIZXJj7YJAm1XaL1_o5cLBONlrKuVMZ-GrJvQLrrk1nvJpW1TsjtTN-cfl_TFUfEKCoQHZTmCp4EjdiPNIyAJVzKIHLCiLMM.q6M-qrvrirWcqADkcVdtGw",
        "TokenType": "Bearer"
    },
    "ChallengeParameters": {}
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Create customer 1</span>
                                <span className={classes.resultText}>Response, use the messageId to match your response.</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request POST 'https://test-server.lendingsolutions.aryza.cloud/v1/in' \\
--header 'Content-Type: text/plain' \\
--header 'Authorization: Bearer eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w' \\
--data-raw 'mutation { \\
    createCustomerV2( \\
        create: { \\
            Customernumber: "T000000001"\\
            LegalName1: "GeorgeO"\\
            Name1: "George"\\
            Name2: "Orwell"\\
            Administrator: "ABIT"\\
            PartyInvolvedTyp: "01"\\
            createAddress: {\\
                AddressNo: "0001"\\
                PostalForeignCountries: "01"\\
                PostalDomestic: "01"\\
                Addresstype: "01"\\
                address_status: "01"\\
                AddAddresstype: "01"\\
                origin: "01"\\
                Street: "Hauptstraße"\\
                HouseNoNumFrom: "11"\\
                postcode: "40239"\\
                location: "Düsseldorf"\\
                FederalState: "NRW"\\
                country: "004" } }\\
    ) { ObjectId } }'`}
                                </span>
                                <span className={classes.result}>
{`{
    "sendMessageResult": {
        "error": null,
        "messageId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    },
    "responseMetadata": {
        "requestId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    }
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Read response (in a loop until response is found or timeout)</span>
                                <span className={classes.resultText}>Response, use originalMessageID to match the send messageId and identify your message</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=ReceiveMessage&MaxNumberOfMessages=10&VisibilityTimeout=3&WaitTimeSeconds=2' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`{
    "Messages" : [{
        "Body" : "{\"data\": {\"createCustomerV2\": {\"ObjectId\": \"-2315a9d1:1860c99391d:-5f5c\"}},\"errors\": null}",
        "ReceiptHandle" : "9c0f98fa-a2f3-4c69-9fab-1c9484a769fc",
        "MessageId" : "00a93941-eec8-4e25-b62f-7a66dd7275ad",
        "Attributes" : {
            "SentTimestamp" : "1683733073663"},
        "MessageAttributes" : {
            "originalMessageID" : {
                "DataType" : "String",
                "StringValue" : "a1e82a7a-3380-437f-b62f-118cd889abf5"}}
    }]
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Delete the response message using the ReceiptHandle</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=DeleteMessage&ReceiptHandle=9c0f98fa-a2f3-4c69-9fab-1c9484a769fc' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`HTTP Status 200 OK`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Create customer 2</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request POST 'https://test-server.lendingsolutions.aryza.cloud/v1/in' \\
--header 'Content-Type: text/plain' \\
--header 'Authorization: Bearer eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w' \\
--data-raw 'mutation { \\
    createCustomerV2( \\
        create: { \\
            Customernumber: "T000000002"\\
            LegalName1: "IsackA"\\
            Name1: "Isack"\\
            Name2: "Assimov"\\
            Administrator: "ABIT"\\
            PartyInvolvedTyp: "01"\\
            createAddress: {\\
                AddressNo: "0001"\\
                PostalForeignCountries: "01"\\
                PostalDomestic: "01"\\
                Addresstype: "01"\\
                address_status: "01"\\
                AddAddresstype: "01"\\
                origin: "01"\\
                Street: "Hauptstraße"\\
                HouseNoNumFrom: "11"\\
                postcode: "40239"\\
                location: "Düsseldorf"\\
                FederalState: "NRW"\\
                country: "004" } }\\
    ) { ObjectId } }'`}
                                </span>
                                <span className={classes.result}>
{`{
    "sendMessageResult": {
        "error": null,
        "messageId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    },
    "responseMetadata": {
        "requestId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    }
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Read response (in a loop until response is found or timeout)</span>
                                <span className={classes.resultText}>Response, use originalMessageID to match the send messageId and identify your message</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=ReceiveMessage&MaxNumberOfMessages=10&VisibilityTimeout=3&WaitTimeSeconds=2' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`{
    "Messages" : [{
        "Body" : "{\"data\": {\"createCustomerV2\": {\"ObjectId\": \"-2315a9d1:1860c99391d:-5cb2\"}},\"errors\": null}",
        "ReceiptHandle" : "9c0f98fa-a2f3-4c69-9fab-1c9484a769fc",
        "MessageId" : "00a93941-eec8-4e25-b62f-7a66dd7275ad",
        "Attributes" : {
            "SentTimestamp" : "1683733073663"},
        "MessageAttributes" : {
            "originalMessageID" : {
                "DataType" : "String",
                "StringValue" : "a1e82a7a-3380-437f-b62f-118cd889abf5"}}
    }]
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Delete the response message using the ReceiptHandle</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=DeleteMessage&ReceiptHandle=9c0f98fa-a2f3-4c69-9fab-1c9484a769fc' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`HTTP Status 200 OK`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Create customer unit for customer 1</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request POST 'https://test-server.lendingsolutions.aryza.cloud/v1/in' \\
--header 'Content-Type: text/plain' \\
--header 'Authorization: Bearer eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w' \\
--data-raw 'mutation { \\
    createCustomerUnitV2(\\ 
        create: { \\
            CommitmentNumber: "CU00000001"\\
            MarketSegment: "00000"\\
            Customer: {\\
                Customernumber: "T000000001" } }\\
    ) { ObjectId } }'`}
                                </span>
                                <span className={classes.result}>
{`{
    "sendMessageResult": {
        "error": null,
        "messageId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    },
    "responseMetadata": {
        "requestId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    }
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Read response (in a loop until response is found or timeout)</span>
                                <span className={classes.resultText}>Response, use originalMessageID to match the send messageId and identify your message</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=ReceiveMessage&MaxNumberOfMessages=10&VisibilityTimeout=3&WaitTimeSeconds=2' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`{
    "Messages" : [{
        "Body" : "{\"data\": {\"createCustomerUnitV2\": {\"ObjectId\": \"-2315a9d1:1860c99391d:-5c58\"}},\"errors\": null}",
        "ReceiptHandle" : "9c0f98fa-a2f3-4c69-9fab-1c9484a769fc",
        "MessageId" : "00a93941-eec8-4e25-b62f-7a66dd7275ad",
        "Attributes" : {
            "SentTimestamp" : "1683733073663"},
        "MessageAttributes" : {
            "originalMessageID" : {
                "DataType" : "String",
                "StringValue" : "a1e82a7a-3380-437f-b62f-118cd889abf5"}}
    }]
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Delete the response message using the ReceiptHandle</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=DeleteMessage&ReceiptHandle=9c0f98fa-a2f3-4c69-9fab-1c9484a769fc' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`HTTP Status 200 OK`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Add customer 2 to customer unit</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request POST 'https://test-server.lendingsolutions.aryza.cloud/v1/in' \\
--header 'Content-Type: text/plain' \\
--header 'Authorization: Bearer eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w' \\
--data-raw 'mutation {\\
    createCustomerUnitCustomerV2(\\
        create: {\\
            CompoundPosition: "2"\\
            IsConsidered: true\\
            Customer: {\\
                Customernumber:"T000000002" }\\
            Customer1: {\\
                Customernumber:"T000000001" } }\\
    ) {\\
        CompoundPosition\\
        ObjectId\\
    } }'`}
                                </span>
                                <span className={classes.result}>
{`{
    "sendMessageResult": {
        "error": null,
        "messageId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    },
    "responseMetadata": {
        "requestId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    }
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Read response (in a loop until response is found or timeout)</span>
                                <span className={classes.resultText}>Response, use originalMessageID to match the send messageId and identify your message</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=ReceiveMessage&MaxNumberOfMessages=10&VisibilityTimeout=3&WaitTimeSeconds=2' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`{
    "Messages" : [{
        "Body" : "{\"data\": {\"createCustomerUnitCustomerV2\": {\"CompoundPosition\": \"2\", \"ObjectId\": \"-2315a9d1:1860c99391d:-5adc\"}},\"errors\": null}",
        "ReceiptHandle" : "9c0f98fa-a2f3-4c69-9fab-1c9484a769fc",
        "MessageId" : "00a93941-eec8-4e25-b62f-7a66dd7275ad",
        "Attributes" : {
            "SentTimestamp" : "1683733073663"},
        "MessageAttributes" : {
            "originalMessageID" : {
                "DataType" : "String",
                "StringValue" : "a1e82a7a-3380-437f-b62f-118cd889abf5"}}
    }]
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Delete the response message using the ReceiptHandle</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=DeleteMessage&ReceiptHandle=9c0f98fa-a2f3-4c69-9fab-1c9484a769fc' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`HTTP Status 200 OK`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Create account for customer 1</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request POST 'https://test-server.lendingsolutions.aryza.cloud/v1/in' \\
--header 'Content-Type: text/plain' \\
--header 'Authorization: Bearer eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w' \\
--data-raw 'mutation { \\
    createAccountV2( \\
        create: { \\
            Customer: {\\
                Customernumber:"T000000001" }\\
            createBKDetail:{\\
                NumberNegDisposaldo: 123\\
                PercentageShare: 50\\
                Recoverable: 10\\
                Currency: "EUR" } \\
            Account_type: "Darlehensbereich"\\
            Accountnumber:"7700000001"\\
            Responsible: "ABIT"\\
            Currency: "EUR"\\
            DistributionKey: "10"\\
            SLLPKey: "00" \\
            Credit_type: "22100"\\
            IBAN: "AT611904300234573202" }\\
    ) { ObjectId } }'`}
                                </span>
                                <span className={classes.result}>
{`{
    "sendMessageResult": {
        "error": null,
        "messageId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    },
    "responseMetadata": {
        "requestId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    }
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Read response (in a loop until response is found or timeout)</span>
                                <span className={classes.resultText}>Response, use originalMessageID to match the send messageId and identify your message</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=ReceiveMessage&MaxNumberOfMessages=10&VisibilityTimeout=3&WaitTimeSeconds=2' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`{
    "Messages" : [{
        "Body" : "{\"data\": {\"createAccountV2\": {\"ObjectId\": \"65f36df3:1860d85e4b9:-7769\"}},\"errors\": null}",
        "ReceiptHandle" : "9c0f98fa-a2f3-4c69-9fab-1c9484a769fc",
        "MessageId" : "00a93941-eec8-4e25-b62f-7a66dd7275ad",
        "Attributes" : {
            "SentTimestamp" : "1683733073663"},
        "MessageAttributes" : {
            "originalMessageID" : {
                "DataType" : "String",
                "StringValue" : "a1e82a7a-3380-437f-b62f-118cd889abf5"}}
    }]
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Delete the response message using the ReceiptHandle</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=DeleteMessage&ReceiptHandle=9c0f98fa-a2f3-4c69-9fab-1c9484a769fc' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`HTTP Status 200 OK`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Create collateral and add it to customer unit</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request POST 'https://test-server.lendingsolutions.aryza.cloud/v1/in' \\
--header 'Content-Type: text/plain' \\
--header 'Authorization: Bearer eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w' \\
--data-raw 'mutation {\\
    createCollateralV2(\\
        create: { \\
            Currency: "EUR"\\
            CollateralType: "60"\\
            createChargepos:{\\
                PositionLevel: 2\\
                ActPosition: 5\\
                CustomerUnit:{\\
                    CommitmentNumber: "CU00000001" } }\\
            createCollateralObject:{\\
                CollateralObjectType: "GFND"\\
                createSiObjValuation:{\\
                    Reporter: "Test"\\
                    Currency: "EUR" } }\\
            createPartial_right:{\\
                Currency: "EUR"\\
                CurrentPosition: 1 } }\\
    ) {\\
        ObjectId\\
        CollateralObject {\\
            ObjectId } } }'`}
                                </span>
                                <span className={classes.result}>
{`{
    "sendMessageResult": {
        "error": null,
        "messageId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    },
    "responseMetadata": {
        "requestId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    }
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Read response (in a loop until response is found or timeout)</span>
                                <span className={classes.resultText}>Response, use originalMessageID to match the send messageId and identify your message</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=ReceiveMessage&MaxNumberOfMessages=10&VisibilityTimeout=3&WaitTimeSeconds=2' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`{
    "Messages" : [{
        "Body" : "{\"data\": {\"createCollateralV2\": {\"ObjectId\": \"65f36df3:1860d85e4b9:-74af\", \"CollateralObject\": {\"ObjectId\": \"65f36df3:1860d85e4b9:-74aa\"}}},\"errors\": null}",
        "ReceiptHandle" : "9c0f98fa-a2f3-4c69-9fab-1c9484a769fc",
        "MessageId" : "00a93941-eec8-4e25-b62f-7a66dd7275ad",
        "Attributes" : {
            "SentTimestamp" : "1683733073663"},
        "MessageAttributes" : {
            "originalMessageID" : {
                "DataType" : "String",
                "StringValue" : "a1e82a7a-3380-437f-b62f-118cd889abf5"}}
    }]
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Delete the response message using the ReceiptHandle</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=DeleteMessage&ReceiptHandle=9c0f98fa-a2f3-4c69-9fab-1c9484a769fc' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`HTTP Status 200 OK`}
                                </span>
                            </div>
                        </div>
                        <div id="blueprint_ChangeCustomerAddress">
                            <span className={classes.headingTitle}>Customer moves to a new address</span>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Find the partner number and address ObjectId for customer with customer number A000000077</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request POST 'https://test-server.lendingsolutions.aryza.cloud/v1/in' \\
--header 'Content-Type: text/plain' \\
--header 'Authorization: Bearer eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w' \\
--data-raw 'query { \\
    getCustomerV2( \\
        filter: { \\
            Customernumber: "A000000077" }\\
    ) { \\
        Partner_number\\
        Address {\\
            ObjectId } } }'`}
                                </span>
                                <span className={classes.result}>
{`{
    "sendMessageResult": {
        "error": null,
        "messageId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    },
    "responseMetadata": {
        "requestId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    }
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Read response (in a loop until response is found or timeout)</span>
                                <span className={classes.resultText}>Response, use originalMessageID to match the send messageId and identify your message</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=ReceiveMessage&MaxNumberOfMessages=10&VisibilityTimeout=3&WaitTimeSeconds=2' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`{
    "Messages" : [{
        "Body" : "{\"data\": {\"getCustomerV2\": {\"Partner_number\": \"A000000077\", \"Address\": {\"ObjectId\": \"37b77f0f:1872ac1b3e3:-50eb\"}}},\"errors\": null}",
        "ReceiptHandle" : "9c0f98fa-a2f3-4c69-9fab-1c9484a769fc",
        "MessageId" : "00a93941-eec8-4e25-b62f-7a66dd7275ad",
        "Attributes" : {
            "SentTimestamp" : "1683733073663"},
        "MessageAttributes" : {
            "originalMessageID" : {
                "DataType" : "String",
                "StringValue" : "a1e82a7a-3380-437f-b62f-118cd889abf5"}}
    }]
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Delete the response message using the ReceiptHandle</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=DeleteMessage&ReceiptHandle=9c0f98fa-a2f3-4c69-9fab-1c9484a769fc' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`HTTP Status 200 OK`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Use the address ObjectId form the previous query to mark the current address as old</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request POST 'https://test-server.lendingsolutions.aryza.cloud/v1/in' \\
--header 'Content-Type: text/plain' \\
--header 'Authorization: Bearer eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w' \\
--data-raw 'mutation { \\
    updateAddressV2( \\
        update: { \\
            Addresstype: "07" \\
            where: { \\
                ObjectId: "37b77f0f:1872ac1b3e3:-50eb" } }\\
    ) { ObjectId } }'`}
                                </span>
                                <span className={classes.result}>
{`{
    "sendMessageResult": {
        "error": null,
        "messageId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    },
    "responseMetadata": {
        "requestId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    }
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Read response (in a loop until response is found or timeout)</span>
                                <span className={classes.resultText}>Response, use originalMessageID to match the send messageId and identify your message</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=ReceiveMessage&MaxNumberOfMessages=10&VisibilityTimeout=3&WaitTimeSeconds=2' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`{
    "Messages" : [{
        "Body" : "{\"data\": {\"updateAddressV2\": {\"ObjectId\": \"37b77f0f:1872ac1b3e3:-50eb\"}},\"errors\": null}",
        "ReceiptHandle" : "9c0f98fa-a2f3-4c69-9fab-1c9484a769fc",
        "MessageId" : "00a93941-eec8-4e25-b62f-7a66dd7275ad",
        "Attributes" : {
            "SentTimestamp" : "1683733073663"},
        "MessageAttributes" : {
            "originalMessageID" : {
                "DataType" : "String",
                "StringValue" : "a1e82a7a-3380-437f-b62f-118cd889abf5"}}
    }]
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Delete the response message using the ReceiptHandle</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=DeleteMessage&ReceiptHandle=9c0f98fa-a2f3-4c69-9fab-1c9484a769fc' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`HTTP Status 200 OK`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Create new address for customer with partner number.</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request POST 'https://test-server.lendingsolutions.aryza.cloud/v1/in' \\
--header 'Content-Type: text/plain' \\
--header 'Authorization: Bearer eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w' \\
--data-raw 'mutation {\\
    createAddressV2(\\
        create:{\\
            Partner:{\\
                Partner_number: "A000000077" }\\
            AddressNo: "0001"\\
            PostalForeignCountries: "01"\\
            PostalDomestic: "01"\\
            Addresstype: "01"\\
            address_status: "01"\\
            AddAddresstype: "01"\\
            origin: "01"\\
            Street: "Hauptstraße"\\
            HouseNoNumFrom: "11"\\
            postcode: "40239"\\
            location: "Düsseldorf"\\
            FederalState: "NRW"\\
            country: "004" }\\
    ) { ObjectId } }'`}
                                </span>
                                <span className={classes.result}>
{`{
    "sendMessageResult": {
        "error": null,
        "messageId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    },
    "responseMetadata": {
        "requestId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    }
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Read response (in a loop until response is found or timeout)</span>
                                <span className={classes.resultText}>Response, use originalMessageID to match the send messageId and identify your message</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=ReceiveMessage&MaxNumberOfMessages=10&VisibilityTimeout=3&WaitTimeSeconds=2' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`{
    "Messages" : [{
        "Body" : "{\"data\": {\"createAddressV2\": {\"ObjectId\": \"37b77f0f:1872ac1b3e3:-50eb\"}},\"errors\": null}",
        "ReceiptHandle" : "9c0f98fa-a2f3-4c69-9fab-1c9484a769fc",
        "MessageId" : "00a93941-eec8-4e25-b62f-7a66dd7275ad",
        "Attributes" : {
            "SentTimestamp" : "1683733073663"},
        "MessageAttributes" : {
            "originalMessageID" : {
                "DataType" : "String",
                "StringValue" : "a1e82a7a-3380-437f-b62f-118cd889abf5"}}
    }]
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Delete the response message using the ReceiptHandle</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=DeleteMessage&ReceiptHandle=9c0f98fa-a2f3-4c69-9fab-1c9484a769fc' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`HTTP Status 200 OK`}
                                </span>
                            </div>
                        </div>
                        <div id="blueprint_ChangeAccountHolder">
                            <span className={classes.headingTitle}>Change account holder</span>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Update account, change customer</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request POST 'https://test-server.lendingsolutions.aryza.cloud/v1/in' \\
--header 'Content-Type: text/plain' \\
--header 'Authorization: Bearer eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w' \\
--data-raw 'mutation { \\
    updateAccountV2( \\
        update: { \\
            Customer: {\\
                Customernumber: "T000000002" }\\
            where: {\\
                Accountnumber: "7700000001" } }\\
  ) { ObjectId } }'`}
                                </span>
                                <span className={classes.result}>
{`{
    "sendMessageResult": {
        "error": null,
        "messageId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    },
    "responseMetadata": {
        "requestId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    }
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Read response (in a loop until response is found or timeout)</span>
                                <span className={classes.resultText}>Response, use originalMessageID to match the send messageId and identify your message</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=ReceiveMessage&MaxNumberOfMessages=10&VisibilityTimeout=3&WaitTimeSeconds=2' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`{
    "Messages" : [{
        "Body" : "{\"data\": {\"updateAccountV2\": {\"ObjectId\": \"65f36df3:1860d85e4b9:-7769\"}},\"errors\": null}",
        "ReceiptHandle" : "9c0f98fa-a2f3-4c69-9fab-1c9484a769fc",
        "MessageId" : "00a93941-eec8-4e25-b62f-7a66dd7275ad",
        "Attributes" : {
            "SentTimestamp" : "1683733073663"},
        "MessageAttributes" : {
            "originalMessageID" : {
                "DataType" : "String",
                "StringValue" : "a1e82a7a-3380-437f-b62f-118cd889abf5"}}
    }]
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Delete the response message using the ReceiptHandle</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=DeleteMessage&ReceiptHandle=9c0f98fa-a2f3-4c69-9fab-1c9484a769fc' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`HTTP Status 200 OK`}
                                </span>
                            </div>
                        </div>
                        <div id="blueprint_ChangeRealEstateOwner">
                            <span className={classes.headingTitle}>Change real estate owner</span>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Find the SeqNo for the real estate owner you want to change.</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request POST 'https://test-server.lendingsolutions.aryza.cloud/v1/in' \\
--header 'Content-Type: text/plain' \\
--header 'Authorization: Bearer eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w' \\
--data-raw 'query { \\
    getRealEstateV2( \\
        filter: { \\
            Objectnumber: 3 }\\
    ) {\\
        Section1Owner {\\
            SeqNo\\
            Customer: {\\
                Customernumber } } } }'`}
                                </span>
                                <span className={classes.result}>
{`{
    "sendMessageResult": {
        "error": null,
        "messageId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    },
    "responseMetadata": {
        "requestId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    }
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Read response (in a loop until response is found or timeout)</span>
                                <span className={classes.resultText}>Response, use originalMessageID to match the send messageId and identify your message</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=ReceiveMessage&MaxNumberOfMessages=10&VisibilityTimeout=3&WaitTimeSeconds=2' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`{
    "Messages" : [{
        "Body" : "{\"data\": {\"getRealEstateV2\": {\"Section1Owner\": [{\"SeqNo\": \"1\", \"Customer\": {\"Customernumber\": \"T000000001\"}}] }},\"errors\": null}",
        "ReceiptHandle" : "9c0f98fa-a2f3-4c69-9fab-1c9484a769fc",
        "MessageId" : "00a93941-eec8-4e25-b62f-7a66dd7275ad",
        "Attributes" : {
            "SentTimestamp" : "1683733073663"},
        "MessageAttributes" : {
            "originalMessageID" : {
                "DataType" : "String",
                "StringValue" : "a1e82a7a-3380-437f-b62f-118cd889abf5"}}
    }]
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Delete the response message using the ReceiptHandle</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=DeleteMessage&ReceiptHandle=9c0f98fa-a2f3-4c69-9fab-1c9484a769fc' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`HTTP Status 200 OK`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Use the SeqNo form the previous query to identify the Section1Owner and update it's reference to another customer.</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request POST 'https://test-server.lendingsolutions.aryza.cloud/v1/in' \\
--header 'Content-Type: text/plain' \\
--header 'Authorization: Bearer eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w' \\
--data-raw 'mutation { \\
    updateRealEstateV2( \\
        update: { \\
            updateSection1Owner: {\\
                Customer: {\\
                    Customernumber: "T000000002" }\\
                where: {\\
                    SeqNo: "1"}}\\
            where: {\\
                Objectnumber: 3 } }\\
  ) { ObjectId } }'`}
                                </span>
                                <span className={classes.result}>
{`{
    "sendMessageResult": {
        "error": null,
        "messageId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    },
    "responseMetadata": {
        "requestId": "a1e82a7a-3380-437f-b62f-118cd889abf5"
    }
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Read response (in a loop until response is found or timeout)</span>
                                <span className={classes.resultText}>Response, use originalMessageID to match the send messageId and identify your message</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=ReceiveMessage&MaxNumberOfMessages=10&VisibilityTimeout=3&WaitTimeSeconds=2' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`{
    "Messages" : [{
        "Body" : "{\"data\": {\"updateRealEstateV2\": {\"ObjectId\": \"37b77f0f:1872ac1b3e3:-50eb\"}},\"errors\": null}",
        "ReceiptHandle" : "9c0f98fa-a2f3-4c69-9fab-1c9484a769fc",
        "MessageId" : "00a93941-eec8-4e25-b62f-7a66dd7275ad",
        "Attributes" : {
            "SentTimestamp" : "1683733073663"},
        "MessageAttributes" : {
            "originalMessageID" : {
                "DataType" : "String",
                "StringValue" : "a1e82a7a-3380-437f-b62f-118cd889abf5"}}
    }]
}`}
                                </span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.codeText}>Delete the response message using the ReceiptHandle</span>
                                <span className={classes.resultText}>Response</span>
                            </div>
                            <div className={classes.containerCodeResult}>
                                <span className={classes.code}>
{`curl --location --request GET \\
'https://test-server.lendingsolutions.aryza.cloud/v1/out?Action=DeleteMessage&ReceiptHandle=9c0f98fa-a2f3-4c69-9fab-1c9484a769fc' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: eyJraWQiOiJWWjRFZ2NpUFFlcXhKaTRyOEhTaFA2NXF1clhwZ1N5M08yd3VVOGxXb0ZZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyZTQ2Mjc4My0xNzhiLTRjYWItYjA4Ny03YjdkY2MyYmEyZGIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY3VzdG9tOmluc3RpdHV0ZSI6IjQxNzI3IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdjVucldjYWZwIiwiY29nbml0bzp1c2VybmFtZSI6ImNoZWNrMjQiLCJjdXN0b206dXJsIjoiaHR0cHM6XC9cL3Rlc3QtNDE3Mjcub3BlbmNyZWRpdC5jb2xsZW5kYS5jbG91ZFwvdjFcL2luIiwib3JpZ2luX2p0aSI6IjhkNzkxOTE1LWI5NTEtNGM0MS1iOTg3LWI5MjBmYjYzYTZkMCIsImF1ZCI6IjVzbmhxa3IwMTE3MGF1YWFqM29ua2Z0dGU3IiwiZXZlbnRfaWQiOiIxZDRjZmE5Mi01NGQyLTRhZDctYjlkZC00MDY4MjFlMzc2YzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY4MzcyNjc4NSwiY3VzdG9tOnRlbmFudCI6IjQxNzI3IiwiY3VzdG9tOmNsaWVudCI6IkNIRUNLMjQiLCJleHAiOjE2ODM3MzAzODUsImlhdCI6MTY4MzcyNjc4NSwianRpIjoiNmYwNzQ1MTUtOTFkMC00MzA1LWFhNmMtZTEzYjI3NDc0OTA0IiwiZW1haWwiOiJkZXZvcHMtZGVAY29sbGVuZGEuY29tIn0.G3ksvmrOniJq86gD-ICk1VmKjRIWazcPOD2oRaaE2KZzJdNQHHkgztBVIN4T8mDqs2-xWVykw9CzCctCz_xUiOE-3cuJ2sGAk1Wzyy0uh8pXmJ9zz-E5GOHokiJF1rgGVXDJ4QdMG3SlMa2RLAUEO5wxcxc_daFJO5a8HtCuWU3uiEWMwbmhwU94lS7zIUG13JriePFG_pCtrlC4m0czcbQrQ-u51_SHMh7xkCEsUGIdlDnBCSti7sXlVx2CWJtu2mldZuLwDZSIDA7Z-HhJLX5oRUSaN7sQqQE4J4XEygpt5d9afwFRdf27ln9GFRzq1oFjsktH2CqxDCZbf5IF1w'`}
                                </span>
                                <span className={classes.result}>
{`HTTP Status 200 OK`}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BluePrint.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BluePrint);
