import { combineReducers } from 'redux';

import { EXAMPLE_RECEIVE, LOGIN_PERFORMED, REFRESH_TOKEN_PERFORMED, LOGOUT_PERFORMED, API_ERRORED, LIST_DOCUMENTS_RESPONSE, DOWNLOAD_DOCUMENT_RESPONSE, RECIEVE_EVENTS_RESPONSE, RECIEVE_EVENTS_ERROR } from "./constants";

import { reducer as reduxFormReducer } from "redux-form";

const initialState = {};

var exampleReducer = function(state = initialState, action) {
    let payload = {};
    if(action.type === EXAMPLE_RECEIVE) {
        payload[action.payload.exampleName] = {data: action.payload.data};
        return Object.assign({}, state, payload);
    } else if(action.type === API_ERRORED) {
        payload[action.payload.exampleName] = {data: "Error: " + action.payload.error};
        return Object.assign({}, state, payload);
    } else if(action.type === LIST_DOCUMENTS_RESPONSE) {
        payload.documents = action.payload;
        return Object.assign({}, state, payload);
    } else if(action.type === DOWNLOAD_DOCUMENT_RESPONSE) {
        payload.document = action.payload;
        return Object.assign({}, state, payload);
    } else if(action.type === RECIEVE_EVENTS_RESPONSE) {
        console.log(action.payload);
        payload.events = action.payload;
        return Object.assign({}, state, payload);
    } else if(action.type === RECIEVE_EVENTS_ERROR) {
        payload.events = action.payload;
        return Object.assign({}, state, payload);
    }else {
        return state;
    }
};

var authenticationReducer = function(state = null, action) {
    if(action.type === LOGIN_PERFORMED) {
        return Object.assign({}, state, action.payload);
    } else if(action.type === LOGOUT_PERFORMED) {
        return null;
    } else if(action.type === REFRESH_TOKEN_PERFORMED) {
        return Object.assign({}, state, action.payload);
    } else {
        return state;
    }
};

export default combineReducers({
    exampleReducer,
    authenticationReducer,
    form: reduxFormReducer
});
