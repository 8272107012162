import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { withRouter } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
	root: {
        fontFamily: 'Poppins-Regular',
        fontSize: '.9rem',
        fontWeight: '400',
        lineHeight: '1.5',
        padding: '0px 5px 0px 5px',
        transition: 'all .3s ease',
        boxShadow: '0 1px 1px rgba(0,0,0,.15)',
        backgroundColor: theme.palette.primary.light,
        overflow: 'auto',
        height: '100%',
        minWidth: '310px',
	},
    title: {
        fontSize: '1.3rem',
        fontFamily: 'Poppins-Regular',
        fontWeight: '500',
        lineHeight: '1.6',
        display: 'block',
        padding: '23px 5px 5px 15px',
    },
    item: {
        fontSize: '1.0rem',
        fontFamily: 'Poppins-Regular',
        fontWeight: '400',
        lineHeight: '1.75',
        padding: '5px 5px 5px 15px',
        cursor: 'pointer',
        color: '#5d5d5d',
        '&:hover': {
	        color: '#FFFFFF',
            backgroundColor: '#2e32ae',
        }
    },
    selected : {
        color: '#2e32ae',
        '&:hover': {
            backgroundColor: '#2e32ae',
            color: '#FFFFFF',
        }
    },
    parent: {
        fontWeight: '600',
    },
    child: {
    }
});

class Sidebar extends Component {
    state = {
        selectedIndex : 0,
        selectedChild : null,
    };

    handleListItemClick = (event, label, index, child, path) => {
        this.setState({ 
            selectedIndex: index,
            selectedChild: child});
        if(!path) {
            this.setState({selectedChild: null});
        }
        if(this.props.samePageScroll) {
            console.log(path);
            var anchorTarget = document.getElementById(path);
            console.log(anchorTarget);
            if(anchorTarget) {
                anchorTarget.scrollIntoView({behavior: "smooth", block: "start"});
            }
        } else {
            this.props.history.push(path);
        }
    };

    render() {
        const { classes } = this.props;
        const { selectedIndex } = this.state;
        const { selectedChild } = this.state;

        return (
            <div className={classes.root} id = "routerSidebar">
                <span className={classes.title}>{this.props.title}</span>
                <List component="nav">
                    {this.props.model.map((item, idx) => (
                        <div key={'div_' + idx}>
                            <ListItem key={'item_' + idx} button 
                                selected={selectedIndex === idx}
                                onClick={event => this.handleListItemClick(event, item.label, idx, 0, item.path)}
                                classes={{root: classes.item, selected: classes.selected}}>
                                <ListItemText primary={item.label} disableTypography className={item.children ? classes.parent : classes.child}/>
                            </ListItem>
                            {item.children && (
                                <List component="div" disablePadding>
                                    {item.children.map((childItem, childIdx) => (
                                        <ListItem key={'item_' + idx + '_' + childIdx} button 
                                            selected={selectedIndex === idx && selectedChild === childIdx} 
                                            onClick={event => this.handleListItemClick(event, childItem.label, idx, childIdx, childItem.path)}
                                            classes={{root: classes.item, selected: classes.selected}}>
                                            <ListItemText primary={childItem.label} disableTypography className={classes.child}/>
                                        </ListItem>
                                    ))}
                                </List>
                            )}
                        </div>
                    ))}
                </List>
            </div>
        );
    }
}

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Sidebar));
