import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Sidebar from '../../components/Sidebar';

import styles from '../../styles';

class CallSequence extends Component {

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Sidebar title="Call Sequence" samePageScroll={true} model={[{ 
                        label: "... to the begining"     , path: "callsequence"
                    }, {
                        label: "Loan granted"            , path: "callsequence_loangranted"
                    }, {
                        label: "Credibility check failed", path: "callsequence_credibilitycheckfailed"
                    }, {
                        label: "Loan rejected"           , path: "callsequence_loanrejected"
                    }, {
                        label: "Contract not signed"     , path: "callsequence_contractnotsigned"
                    }, {
                        label: "Request customer data"   , path: "callsequence_requestcustomerdata"
                    }]}/>
                <div className={classes.workarea} id = "content">
                    <div className={classes.paper}>
                        <div id="callsequence">
                            <span className={classes.title}>Call sequence</span>
                        </div>
                        <div id="callsequence_loangranted">
                            <span className={classes.headingTitle}>Loan granted</span>
                            <span className={classes.text}>
                                Customer fills a loan application, attaches needed documents and submits it for approval.
                                During the approval process, additional documents are requested.
                                The loan application is approved and the loan contract is sent to the customer for signing.
                                The signed loan contract is sent back.
                            </span>
                            <img src={process.env.PUBLIC_URL + '/call_sequence_pozitive_case.png'} alt="aha" className={classes.image}/>
                        </div>
                        <div id="callsequence_credibilitycheckfailed">
                            <span className={classes.headingTitle}>Credibility Check Failed</span>
                            <span className={classes.text}>
                                Customer and loan application data is proved for credibility check before adding needed documents 
                                attachments and submitting the loan application.   
                            </span>
                            <img src={process.env.PUBLIC_URL + '/call_sequence_credibility_check_failed.png'} alt="aha" className={classes.image}/>
                        </div>
                        <div id="callsequence_loanrejected">
                            <span className={classes.headingTitle}>Loan rejected</span>
                            <span className={classes.text}>
                                Customer fills a loan application, attaches needed documents and submits it for approval.
                                The loan application is rejected during the approval process.
                            </span>
                            <img src={process.env.PUBLIC_URL + '/call_sequence_loan_rejected.png'} alt="aha" className={classes.image}/>
                        </div>
                        <div id="callsequence_contractnotsigned">
                            <span className={classes.headingTitle}>Contract not signed</span>
                            <span className={classes.text}>
                                Customer fills a loan application, attaches needed documents and submits it for approval.
                                During the approval process, additional documents are requested.
                                The loan application is approved and the loan contract is sent to the customer for signing.
                                The customer decides not to sign the loan contract.
                            </span>
                            <img src={process.env.PUBLIC_URL + '/call_sequence_contract_not_signed.png'} alt="aha" className={classes.image}/>
                        </div>
                        <div id="callsequence_requestcustomerdata">
                            <span className={classes.headingTitle}>Request customer data</span>
                            <span className={classes.text}>
                                Synchronize customer data.
                            </span>
                            <img src={process.env.PUBLIC_URL + '/call_sequence_request_customer_data.png'} alt="aha" className={classes.image}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

CallSequence.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CallSequence);
