export default {

	files: [{
        title: "File - list [since v4.66]",

		label: "list - with filter",

		path: "list_with_filter_file_v2_examples",

		message:
`query { 
  listFiles ( 
      rootDirectoryName: "LOG"
      path: "api-gateway"
  ) {
      files {
       rootDirectoryName
       fileName
       lastModifiedOn
       sizeInKB
       permissions {
        createFile
        replaceFile
        renameFile
        readFile
        deleteFile
        deleteDir
        subDownloadDir
        createDir
        downloadDir
       }
     }
      directories {
       rootDirectoryName
       directoryPath
       permissions {
        createDir
      }
     }
   } 
 }`
	},
	{
        title: "File - list [since v4.66]",

		label: "list - withouth filter",

		path: "list_withouth_filter_file_v2_examples",

		message:
`query { 
  listFiles {
      files {
       rootDirectoryName
       fileName
       lastModifiedOn
       sizeInKB
       permissions {
        createFile
        replaceFile
        renameFile
        readFile
        deleteFile
        deleteDir
        subDownloadDir
        createDir
        downloadDir
       }
     }
      directories {
       rootDirectoryName
       directoryPath
       permissions {
        createDir
      }
     }
   } 
 }`
	},
	{
        title: "File - download [since v4.66]",

		label: "download",

		path: "download_file_v2_examples",

		message:
`mutation {
  downloadFile (
   rootDirectoryName: "LOG"
   path: "api-gateway"
   fileName: "Test.pdf"
  ) {
   fileName
   fileKey
  }  
}`
	},
	{
        title: "File - upload [since v4.66]",

		label: "upload",

		path: "upload_file_v2_examples",

		message:
`mutation {
  uploadFile (
   rootDirectoryName: "LOG"
   path: "api-gateway"
   fileKey: "5d881bc7-1cf3-4097-9bfc-36769de54043.zip"
   fileName : "testFileName"
  ) {
   responseStatus
  }  
}`
	},
	{
        title: "File - delete [since v4.66]",

		label: "delete",

		path: "delete_file_v2_examples",

		message:
`mutation{
  deleteFile (
   rootDirectoryName: "LOG"
   path: "api-gateway"
   fileName: "Test.pdf"
  ) {
   responseStatus
  }  
}`
	}], mutations :[], queries:[], jobs:[], workflows:[], blanks:[], events:[]
}