import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Sidebar from '../../components/Sidebar';

import styles from '../../styles';

class HttpLevelAccess extends Component {

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Sidebar title="HttpLevelAccess" samePageScroll={true} model={[
                    { label: "Authentication", children: [
                        { label: "Retrieve Token"    , path: "httpLevelAccess_RetrieveToken"    }, 
                        { label: "Refresh Token"     , path: "httpLevelAccess_RefreshToken"     }, 
                        { label: "Logout"            , path: "httpLevelAccess_Logout"           }]}, 
                    { label: "API Calls", children: [
                        { label: "Send Message"      , path: "httpLevelAccess_SendMessage"      }, 
                        { label: "Read Response"     , path: "httpLevelAccess_ReadResponse"     }, 
                        { label: "Delete Message"    , path: "httpLevelAccess_DeleteMessage"    }]}, 
                    { label: "Transfer Documents", children: [
                        { label: "Upload Document"   , path: "httpLevelAccess_UploadDocument"   }, 
                        { label: "List Documents"    , path: "httpLevelAccess_ListDocuments"    }, 
                        { label: "Download Document" , path: "httpLevelAccess_DownloadDocument" }, 
                        { label: "Delete Document"   , path: "httpLevelAccess_DeleteDocument"   }]}]}/>
                <div className={classes.workarea} id ="content">
                    <div className={classes.paper}>
                        <span className={classes.title}>Http Level Access</span>
                        <div id="httpLevelAccess_authentication">
                            <span className={classes.headingTitle}>Authentication</span>
                            <span className={classes.text}>
                                For authentication we need to connect to an OAuth 2 provider like for example: Keycloak or Amazon Cognito. 
                            </span>
                            <span className={classes.text}>    
                                The examples below are with Amazon Cognito.
                            </span>
                        </div>
                        <div id="httpLevelAccess_RetrieveToken">
                            <span className={classes.headingTitle}>Retrieve Token</span>
                            <span className={classes.text}>Method: POST</span>
                            <span className={classes.text}>URI: https://cognito-idp.eu-central-1.amazonaws.com:443</span>
                            <span className={classes.text}>Headers:</span>
                            <span className={classes.httpCode}>
{`'Content-Type': 'application/x-amz-json-1.1',
'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth'`}
                            </span>
                            <span className={classes.text}>Body:</span>
                            <span className={classes.httpCode}>
{`{
    "AuthParameters" : {
        "USERNAME" : <user name>",
        "PASSWORD" : <user password>"
    },
    "AuthFlow" : "USER_PASSWORD_AUTH",
    "ClientId" : <client id>"
}`}
                            </span>
                            <span className={classes.text}>Response:</span>
                            <span className={classes.httpCode}>
{`{
    "AuthenticationResult": {
        "AccessToken": "…",
        "ExpiresIn": 3600,
        "IdToken": "…",
        "RefreshToken": "…",
        "TokenType": "Bearer"
    }
}`}
                            </span>
                            <span className={classes.text}>For API authentication use header</span>
                            <span className={classes.httpCode}>
{`Authorisation: Bearer <IdToken>;`}
                            </span>
                        </div>
                        <div id="httpLevelAccess_RefreshToken">
                            <span className={classes.headingTitle}>Refresh Token</span>
                            <span className={classes.text}>Method: POST</span>
                            <span className={classes.text}>URI: https://idp-bb34.auth.eu-central-1.amazoncognito.com:443/oauth2/token</span>
                            <span className={classes.text}>Headers:</span>
                            <span className={classes.httpCode}>
{`'Content-Type': 'application/x-www-form-urlencoded'`}
                            </span>
                            <span className={classes.text}>Body:</span>
                            <span className={classes.httpCode}>
{`grant_type=refresh_token&
client_id=<client id>&
refresh_token=<RefreshToken>`}
                            </span>
                            <span className={classes.text}>Response:</span>
                            <span className={classes.httpCode}>
{`{
    "access_token" : "...",
    "refresh_token" : "...",
    "id_token" : "...",
    "token_type" : "Bearer",
    "expires_in" : 3600
}`}
                            </span>
                        </div>
                        <div id="httpLevelAccess_Logout">
                            <span className={classes.headingTitle}>Logout</span>
                            <span className={classes.text}>Method: GET</span>
                            <span className={classes.text}>URI: https://idp-bb34.auth.eu-central-1.amazoncognito.com:443/logout?client_id=&lt;client id&gt;&amp;logout_uri=&lt;redirect on logout&gt;</span>
                            <span className={classes.text}>Headers:</span>
                            <span className={classes.httpCode}>
{`'Access-Control-Allow-Origin' : '*'`}
                            </span>
                            <span className={classes.text}>Response:</span>
                            <span className={classes.httpCode}>
{`redirect`}
                            </span>
                        </div>
                        <div id="httpLevelAccess_APICalls">
                            <span className={classes.headingTitle}>API Calls</span>
                            <span className={classes.text}>
                                Open Credit provides an asynchronous Public API. 
                                Requests to Open Credit are placed in form of messages pushed to an IN Message Queue. 
                                Responses to these requests are pushed, also in in form of messages to an OUT Message Queue, which can be fetched at any time.
                            </span>
                            <span className={classes.text}>    
                                It is in the responsibility of the client to delete a message after successfully processing it. 
                                In case of client error, the message will be invisible for a number of seconds. 
                                After the invisibility timeout, it will reappear in the OUT queue and be ready for a retry.
                            </span>
                        </div>
                        <div id="httpLevelAccess_SendMessage">
                            <span className={classes.headingTitle}>Send Message</span>
                            <span className={classes.text}>Method: POST</span>
                            <span className={classes.text}>URI: https://&lt;test-server&gt;.opencredit.collenda.cloud/v1/in</span>
                            <span className={classes.text}>Headers:</span>
                            <span className={classes.httpCode}>
{`'Authorisation' : 'Bearer <IdToken>'
'Content-Type' : 'text/plain'`}
                            </span>
                            <span className={classes.text}>
                                To send a message with a specific encoding, use:
                            </span>
                            <span className={classes.httpCode}>
{`'Authorisation' : 'Bearer <IdToken>'
'Content-Type' : 'text/plain; charset=utf-8'`}
                            </span>
                            <span className={classes.text}>
                                To send a message so that the response will be sent to a webhook endpoint, use:
                            </span>
                            <span className={classes.httpCode}>
{`'Authorisation' : 'Bearer <IdToken>'
'Content-Type' : 'text/plain'
'webhookUrl'  : '<your webhook endpoint url>'
'webhookAuth' : '<the Authorisation string needed for the webhook endpoint, if needed>'`}
                            </span>
                            <span className={classes.text}>Body:</span>
                            <span className={classes.httpCode}>
{`GraphQL query or mutation`}
                            </span>
                            <span className={classes.text}>Response:</span>
                            <span className={classes.httpCode}>
{`{
    "responseMetadata": {
        "requestId": "…"
    },
    "sendMessageResult": {
        "error": null,
        "messageId": "…"
    }
}`}
                            </span>
                        </div>
                        <div id="httpLevelAccess_ReadResponse">
                            <span className={classes.headingTitle}>Read Response</span>
                            <span className={classes.text}>Method: GET</span>
                            <span className={classes.text}>URI: https://&lt;test-server&gt;.opencredit.collenda.cloud/v1/out?Action=ReceiveMessage&amp;MaxNumberOfMessages=10&amp;VisibilityTimeout=3&amp;WaitTimeSeconds=2</span>
                            <span className={classes.text}>Headers:</span>
                            <span className={classes.httpCode}>
{`'Authorisation' : 'Bearer <IdToken>'
'Content-Type' : 'application/json'`}
                            </span>
                            <span className={classes.text}>Response:</span>
                            <span className={classes.httpCode}>
{`{
    "Messages": [ {
        "Body": <Graph QL response>,
        "ReceiptHandle": "...",
        "MessageId": "...",
        "MessageAttributes": {
            "originalMessageID": {
                "DataType": "String",
                "StringValue": "...."
            }
        }
    }, {
        "Body": <Graph QL response>,
        "ReceiptHandle": "...",
        "MessageId": "...",
        "MessageAttributes": {
            "originalMessageID": {
                "DataType": "String",
                "StringValue": "...."
            }
        }
    }]
}`}
                            </span>
                            <span className={classes.text}>
                                Use the StringValue of the originalMessageID MessageAttribute to correlate with your messageId received as response to the initial Graph QL request.
                            </span>
                        </div>
                        <div id="httpLevelAccess_DeleteMessage">
                            <span className={classes.headingTitle}>Delete Message</span>
                            <span className={classes.text}>
                                It is in the responsibility of the client to delete a message after successfully processing it. 
                                In case of client error, the message will be invisible for a number of seconds. 
                                After the invisibility timeout, it will reappear in the OUT queue and be ready for a retry.
                            </span>
                            <span className={classes.text}>Method: GET</span>
                            <span className={classes.text}>URI: https://&lt;test-server&gt;.opencredit.collenda.cloud/v1/out?Action=DeleteMessage&amp;ReceiptHandle=&lt;url encoded ReceiptHandle&gt;</span>
                            <span className={classes.text}>Headers:</span>
                            <span className={classes.httpCode}>
{`'Authorisation' : 'Bearer <IdToken>'
'Content-Type' : 'application/json'`}
                            </span>
                            <span className={classes.text}>Response:</span>
                            <span className={classes.httpCode}>
{`Deleted`}
                            </span>
                        </div>
                        <div id="httpLevelAccess_TransferDocuments">
                            <span className={classes.headingTitle}>Transfer Documents</span>
                            <span className={classes.text}>
                                Uploaded documents get a document id that can be referenced in a Graph QL message. 
                                A Graph QL response could contain a document reference.
                            </span>
                        </div>
                        <div id="httpLevelAccess_UploadDocument">
                            <span className={classes.headingTitle}>Upload Document</span>
                            <span className={classes.text}>Method: POST</span>
                            <span className={classes.text}>URI: https://&lt;test-server&gt;.opencredit.collenda.cloud/v1/bucket</span>
                            <span className={classes.text}>Headers:</span>
                            <span className={classes.httpCode}>
{`'Authorisation' : 'Bearer <IdToken>'
'Content-Type' : 'multipart/form-data'`}
                            </span>
                            <span className={classes.text}>Body:</span>
                            <span className={classes.httpCode}>
{`Form data with files.`}
                            </span>
                            <span className={classes.text}>Response:</span>
                            <span className={classes.httpCode}>
{`Status 200`}
                            </span>
                        </div>
                        <div id="httpLevelAccess_ListDocuments">
                            <span className={classes.headingTitle}>List Documents</span>
                            <span className={classes.text}>Method: GET</span>
                            <span className={classes.text}>URI: https://&lt;test-server&gt;.opencredit.collenda.cloud/v1/bucket</span>
                            <span className={classes.text}>Headers:</span>
                            <span className={classes.httpCode}>
{`'Authorisation' : 'Bearer <IdToken>'
'Content-Type' : 'application/json'`}
                            </span>
                            <span className={classes.text}>Response:</span>
                            <span className={classes.httpCode}>
{`[key_1, key_2, ..., key_n]`}
                            </span>
                            <span className={classes.text}>A list of document keys.</span>
                        </div>
                        <div id="httpLevelAccess_DownloadDocument">
                            <span className={classes.headingTitle}>Download Document</span>
                            <span className={classes.text}>Method: GET</span>
                            <span className={classes.text}>URI: https://&lt;test-server&gt;.opencredit.collenda.cloud/v1/bucket?key=&lt;document key&gt;</span>
                            <span className={classes.text}>Headers:</span>
                            <span className={classes.httpCode}>
{`'Authorisation' : 'Bearer <IdToken>'
'Content-Type' : 'text/plain'`}
                            </span>
                            <span className={classes.text}>Response:</span>
                            <span className={classes.httpCode}>
{`File content.`}
                            </span>
                            <span className={classes.text}>A list of document keys.</span>
                        </div>
                        <div id="httpLevelAccess_DeleteDocument">
                            <span className={classes.headingTitle}>Delete Document</span>
                            <span className={classes.text}>Method: DELETE</span>
                            <span className={classes.text}>URI: https://&lt;test-server&gt;.opencredit.collenda.cloud/v1/bucket?key=&lt;document key&gt;</span>
                            <span className={classes.text}>Headers:</span>
                            <span className={classes.httpCode}>
{`'Authorisation' : 'Bearer <IdToken>'
'Content-Type' : 'text/plain'`}
                            </span>
                            <span className={classes.text}>Response:</span>
                            <span className={classes.httpCode}>
{`Status 200`}
                            </span>
                            <span className={classes.text}>A list of document keys.</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

HttpLevelAccess.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HttpLevelAccess);
