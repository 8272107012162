import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Sidebar from '../../components/Sidebar';

import styles from '../../styles';

class Architecture extends Component {

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Sidebar title="Architecture" samePageScroll={true} model={[{ 
                        label: "Transport"   , path: "architecture_transport"
                    }, {
                        label: "Format"      , path: "architecture_format"
                    }, {
                        label: "Documents"   , path: "architecture_documents"
                    }, {
                        label: "Persistence" , path: "architecture_persistence"
                    }, {
                        label: "Security"    , path: "architecture_security"
                    }, {
                        label: "Multitennant", path: "architecture_multitennant"
                    }, {
                        label: "Webhooks", path: "architecture_webhooks"
                    }]}/>
                <div className={classes.workarea} id ="content">
                    <div className={classes.paper}>
                        <span className={classes.title}>Architecture</span>
                        <span className={classes.text}>
                            The public API is asynchronous using an INBOX and OUTBOX Queue. 
                            Documents are transfered by uploading or downloading them from a file storage. 
                            The message on the queue is formated based on a GraphQL schema. 
                            Authentication is assured by a 3rd party OAuth 2 provider. 
                        </span>
                        <span className={classes.text}>    
                            The queue implementation depends on the deployment scenario: cloud → Amazon SQS / on premises → JMS. 
                        </span>
                        <span className={classes.text}>    
                            The document storage depends on the deployment scenarion: cloud → Amazon S3 / on premises → File system. 
                        </span>
                        <span className={classes.text}>    
                            The authentication provider depends on the deployment scenario also: cloud → Amazon Cognito / on premises → Keycloak, or others.                        
                        </span>
                        <div id="architecture_transport">
                            <span className={classes.headingTitle}>Transport</span>
                            <span className={classes.text}>
                                Open Credit provides an asynchronous Public API. 
                                Requests to Open Credit are placed in form of messages pushed to a INBOX Message Queue. 
                                Responses to these requests are pushed, also in in form of messages to a OUTBOX Message Queue which can be fetched at any time. 
                                Response time depends on use case. Message queues are persistent and depending on implementation, provide high availability. 
                                It also ensures message delivery even when backend is not available. 
                            </span>
                            <span className={classes.text}>    
                                Upon successful read, the message should be deleted from the OUTBOX queue. 
                                It falls in the responsibility of the client (the reader of the message) to delete the message from the queue.
                            </span>
                            <img src={process.env.PUBLIC_URL + '/architecture.png'} alt="aha" className={classes.image}/>
                        </div>
                        <div id="architecture_format">
                            <span className={classes.headingTitle}>Format</span>
                            <span className={classes.text}>
                                The API messages format is described by a GraphQL schema-definition. 
                                GraphQL is supported by a rich library ecosystem in many programming languages.
                            </span>
                            <span className={classes.text}>
                                <a href={process.env.PUBLIC_URL + '/GraphQLSchemas/v1/aryzaScore.graphql'} download>Aryza Score Schema [deprecated]</a>
                            </span>
                            <span className={classes.text}>
                                <a href={process.env.PUBLIC_URL + '/GraphQLSchemas/v1/aryzaCollectAndRecovery.graphql'} download>Aryza Collect And Recovery Schema</a>
                            </span>
                            <span className={classes.text}>
                                <a href={process.env.PUBLIC_URL + '/GraphQLSchemas/v1/aryzaOriginate.graphql'} download>Aryza Originate Schema</a>
                            </span>
                            <span className={classes.text}>
                                <a href={process.env.PUBLIC_URL + '/GraphQLSchemas/schemaV2.graphql'} download>V2 Schema</a>
                            </span>
                        </div>
                        <div id="architecture_documents">
                            <span className={classes.headingTitle}>Documents</span>
                            <span className={classes.text}>
                                Transferring documents is accomplished by uploading them to a document storage and referencing them in the message. 
                            </span>
                            <span className={classes.text}>
                                Upon successful read, the document should be deleted from the storage. 
                                It falls in the responsibility of the client (the reader of the document) to delete the referenced document from the storage.
                            </span>
                        </div>
                        <div id="architecture_persistence">
                            <span className={classes.headingTitle}>Persistence</span>
                            <span className={classes.text}>
                                Message queues have three possible implementations depending on deployment scenatio:
                                <ul>
                                    <li>Amazon SQS - cloud deployment</li>
                                    <li>JMS - (for example ZeroMQ) on premises deployment</li>
                                    <li>File system - local test</li>
                                </ul>
                                Document storage has two possible implementations depending on deployment scenatio:
                                <ul>
                                    <li>Amazon S3 - cloud deployment</li>
                                    <li>File system - (for example ZeroMQ) on premises deployment</li>
                                </ul>
                            </span>
                        </div>
                        <div id="architecture_security">
                            <span className={classes.headingTitle}>Security</span>
                            <span className={classes.text}>
                                In order to access the "INBOX" and "OUTBOX" queues, as well as the document storage, an OAuth 2 access token is needed. 
                                The OAuth authentication is handeled by a 3rd party service such as Amazon Cognito or Keycloak. 
                            </span>
                        </div>
                        <div id="architecture_multitennant">
                            <span className={classes.headingTitle}>Multitennant</span>
                            <span className={classes.text}>
                                For each tenant seperate "INBOX" and "OUTBOX" queue as well as a separate document storage are accessable via own OAuth credentials.
                            </span>
                            <img src={process.env.PUBLIC_URL + '/multitenant.png'} alt="aha" className={classes.image}/>
                        </div>
                        <div id="architecture_webhooks">
                            <span className={classes.headingTitle}>Webhooks</span>
                            <span className={classes.text}>
                                The public API is an asynchronous API, that's why we have support for Webhooks since v4.80. There are two possibilities to work with our API: 
                                <ul>
                                    <li>constantly polling the OUTBOX for new messages</li>
                                    <li>sending a webhook url with every request to the INBOX queue; in this case the response, when available, will be sent to the webhook endpoint.</li>
                                </ul>
                                In case you need mixed processing we recommend setting up two clients: one for queue polling and a separate one for webhooks.
                            </span>
                            <img src={process.env.PUBLIC_URL + '/webhooks.png'} alt="aha" className={classes.image}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Architecture.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Architecture);
