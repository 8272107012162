import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid           from '@material-ui/core/Grid';
import Button         from '@material-ui/core/Button';

import BuildIcon      from '@material-ui/icons/Build';

import ObjectEditor    from '../../components/ObjectEditor';

import CNRExampleData from "./ExamplesCNR"

import { queryfy }     from "./ExamplesCNR"

import styles          from '../../styles';

class ExampleBuilder extends Component {
    state = {
        associatedRisks: {
        	associatedRisksNo              : "00001",
        	riskPreventionParticipationKey : "10",
        	primaryCustomerNo              : "CUS0000001",
        	clerk                          : "abit",
            currency                       : "EUR",
            customerNoPrefix               : "CUS0000",
            contractNoPrefix               : "CON0000",
            collateralNoPrefix             : "COL0000",
            collateralObjectNoPref         : "COO0000",
        },
    	customers: [{
            customerNo    : "CUS0000001",
            firstName     : "John",
            lastName      : "Smith",
            salutationKey : "10",
            legalFormKey  : "01",
            dateOfBirth   : "1979-10-17",
            countryKey    : "004"
    	}],
    	otherLegalEntities: [{
            customerNo    : "CUS0000004",
            firstName     : "John",
            lastName      : "Smith",
            salutationKey : "10",
            legalFormKey  : "01",
            dateOfBirth   : "1979-10-17",
            countryKey    : "004"
    	}],
		contracts: [{
            contractNo          : "CON0000001",
            contractType        : "Forderung",
            creditTypeKey       : "01",
            mainCustomerNo      : "CUS0000001",
            mainContractNo      : "",
            dueDate             : "2019-12-25",
            contractDate        : "2019-01-01",
            accountBalance      : 1214532.87,
            paymentArrears      :  676567.87,
        }],
        collaterals: [{
            externId                : "COL0000001",
            nominalAmount           : 57.73,
            collateralTypeKey       : "B04",
            collateralOwner         : "CUS0000004",
            collateralObjectId      : "COO0000001",
            collateralObjectTypeKey : "B04",
            valuationTypeKey        : "30",
            valuationDate           : "2019-10-31"
        }]
    };

    handleValueChange = (propName, value) => {
        this.setState({[propName]: value});
    }

    generateExample = () => {
        let cl_orig = CNRExampleData.mutations[0].data.customers;
        let ol_orig = CNRExampleData.mutations[0].data.otherLegalEntities;
        let al_orig = CNRExampleData.mutations[0].data.contracts;
        let s_orig  = CNRExampleData.mutations[0].data.collaterals[0];
        //let m_orig  = CNRExampleData.mutations[0].data.collaterals[1];
        
        let n_data = Object.assign({}, CNRExampleData.mutations[0].data, {
        	associatedRisksNo              : this.state.associatedRisks.associatedRisksNo,
        	riskPreventionParticipationKey : this.state.associatedRisks.riskPreventionParticipationKey,
        	primaryCustomerNo              : this.state.associatedRisks.primaryCustomerNo,
        	clerk                          : this.state.associatedRisks.clerk,
	        customers : this.state.customers.length === 0 ? null : 
                this.state.customers.map((c_input, idx) => {
    	        	let c_orig = idx < cl_orig.length ? cl_orig[idx] : cl_orig[cl_orig.length];
    	        	return Object.assign({}, c_orig, {
    		            customerNo    : c_input.customerNo,
    		            salutationKey : c_input.salutationKey,
    		            firstName     : c_input.firstName,
    		            lastName      : c_input.lastName,
    		            legalFormKey  : c_input.legalFormKey,
    		            dateOfBirth   : c_input.dateOfBirth,
    		            addresses     : c_orig.addresses.map(a_orig => Object.assign({}, a_orig, {countryKey: c_input.countryKey})),
    		            clerk         : this.state.associatedRisks.clerk
    	        	});
    	        }),
	        otherLegalEntities : this.state.otherLegalEntities.length === 0 ? null : 
                this.state.otherLegalEntities.map((o_input, idx) => {
    	        	let o_orig = idx < ol_orig.length ? ol_orig[idx] : ol_orig[ol_orig.length];
    	        	return Object.assign({}, o_orig, {
    		            customerNo    : o_input.customerNo,
    		            salutationKey : o_input.salutationKey,
    		            firstName     : o_input.firstName,
    		            lastName      : o_input.lastName,
    		            legalFormKey  : o_input.legalFormKey,
    		            dateOfBirth   : o_input.dateOfBirth,
    		            addresses     : o_orig.addresses.map(a_orig => Object.assign({}, a_orig, {countryKey: o_input.countryKey})),
    		            clerk         : this.state.associatedRisks.clerk
    	        	});
    	        }),
            contracts : this.state.contracts.length === 0 ? null : 
                this.state.contracts.map((a_input, idx) => {
    	        	let a_orig = idx < al_orig.length ? al_orig[idx] : al_orig[al_orig.length];
                    let dl_orig = a_orig.debtorRelations;
    	            return Object.assign({}, a_orig, {
    	            	externId            : a_input.contractNo,
    		            mainContractNo      : a_input.mainContractNo,
    		            contractNo          : a_input.contractNo,
    		            contractType        : a_input.contractType,
    		            creditTypeKey       : a_input.creditTypeKey,
    		            mainCustomerNo      : a_input.mainCustomerNo,
    		            dueDate             : a_input.dueDate,
    		            contractDate        : a_input.contractDate,
    		            clerk               : this.state.associatedRisks.clerk,
    		            accountBalance      : a_input.accountBalance,
    		            paymentArrears      : a_input.paymentArrears,
    		            histDate            : a_input.contractDate,
    		            currency            : this.state.associatedRisks.currency,
    		            debtorRelations     : this.state.customers.map((c_input, idx) => {
    	        	        let d_orig = idx < dl_orig.length ? dl_orig[idx] : dl_orig[dl_orig.length];
    			            return Object.assign({}, d_orig, {
    			            	customerNo                              : c_input.customerNo,
    			            	origPrincipalClaim                      : a_input.accountBalance / this.state.customers.length,
    			            	origPrincipalClaimValueDate             : a_input.contractDate,
    			            	origInterestFreePrincipalClaimValueDate : a_input.contractDate,
    			            	origInterestStart                       : a_input.contractDate,
    			            	currency                                : this.state.associatedRisks.currency
    			            });
    		            }),
    		            paymentPlans        : a_orig.paymentPlans.map(p_orig => Object.assign({}, p_orig, {
    		            	validFrom : a_input.dueDate,
    		            	elements  : p_orig.elements.map((e_orig, idx) => {
    		            		let dueDate = new Date(a_input.dueDate);
    		            		dueDate.setMonth(dueDate.getMonth() + idx);
    		            		return Object.assign({}, e_orig, {
                                	valueDate : dueDate.toISOString().split('T')[0],
                                	currency  : this.state.associatedRisks.currency
    		            		});
    		            	})
    		            }))
    	            });
                }),
            collaterals : this.state.collaterals.length === 0 ? null : 
                this.state.collaterals.map((s_input, idx) => {
                	return Object.assign({}, s_orig, {
                		position        : idx + 1,
                		otherCollateral : Object.assign({}, s_orig.otherCollateral, {
                			externId          : s_input.externId,
                			collateralNo      : s_input.externId,
                			nominalAmount     : s_input.nominalAmount,
                			collateralTypeKey : s_input.collateralTypeKey,
                			collateralOwner   : s_input.collateralOwner,
                			currency          : this.state.associatedRisks.currency,
                			contracts : this.state.contracts.map((r_input, idx) => Object.assign({}, s_orig.otherCollateral.contracts[0], {
                				rank      : idx + 1,
                				contractNo : r_input.contractNo
                			})),
                			customers : this.state.customers.map((c_input, idx) => {
                				return {
                                    rank       : idx +1,
                                    customerNo : c_input.customerNo
                				};
                			}),
                			collateralObjects : [Object.assign({}, s_orig.otherCollateral.collateralObjects[0], {
                				externId                : s_input.collateralObjectId,
                				objectNo                : s_input.collateralObjectId,
                				collateralObjectTypeKey : s_input.collateralObjectTypeKey,
                				valuations : [Object.assign({}, s_orig.otherCollateral.collateralObjects[0].valuations[0], {
                					valuation            : s_input.nominalAmount,
                					valuationTypeKey     : s_input.valuationTypeKey,
                					valuationDate        : s_input.valuationDate,
                					validFrom            : s_input.valuationDate,
                					fairMarketValue      : s_input.nominalAmount,
                					mortgageLendingValue : s_input.nominalAmount,
                					openMarketValue      : s_input.nominalAmount,
                					targetDate           : s_input.valuationDate,
                					currency             : this.state.associatedRisks.currency
                				})]
                			})]
                		})
                	});
                }),
            realEstate : null
        });
        
        let msg = 
`mutation {
    saveDebt(
        testMode: true
        associatedRisks: ` + queryfy(n_data, '           ') + `
    ) {
        message
        responseCode
    }
}`
        if(this.props.onChange) {
        	this.props.onChange(msg);
    	}
    }

    render() {
    	const { classes } = this.props;
    	return (
    		<Grid container spacing={3}>
    			<Grid item xs={12} className={classes.gridItem}>
    		    	<ObjectEditor 
    		    		data={this.state.associatedRisks} 
    		    		name="Associated Risks" 
    		    		onChange={value => this.handleValueChange('associatedRisks', value)}/>
        		</Grid>
    			<Grid item xs={12} className={classes.gridItem}>
    		    	<ObjectEditor 
    		    		data={this.state.customers} 
    		    		name="Customers" 
    		    		idPrefix={this.state.associatedRisks.customerNoPrefix}
    		    		idPropName="customerNo"
    		    		onChange={value => this.handleValueChange('customers', value)}/>
        		</Grid>
    			<Grid item xs={12} className={classes.gridItem}>
    		    	<ObjectEditor 
    		    		data={this.state.otherLegalEntities} 
    		    		name="Other Legal Entities" 
    		    		idPrefix={this.state.associatedRisks.customerNoPrefix}
    		    		idPropName="customerNo"
    		    		onChange={value => this.handleValueChange('otherLegalEntities', value)}/>
        		</Grid>
    			<Grid item xs={12} className={classes.gridItem}>
    		    	<ObjectEditor 
    		    		data={this.state.contracts} 
    		    		name="Contracts" 
    		    		idPrefix={this.state.associatedRisks.contractNoPrefix}
    		    		idPropName="contractNo"
    		    		onChange={value => this.handleValueChange('contracts', value)}/>
        		</Grid>
    			<Grid item xs={12} className={classes.gridItem}>
    		    	<ObjectEditor 
    		    		data={this.state.collaterals} 
    		    		name="Collaterals" 
    		    		idPrefix={this.state.associatedRisks.collateralNoPrefix}
    		    		idPropName="externId"
    		    		id2Prefix={this.state.associatedRisks.collateralObjectNoPref}
    		    		id2PropName="collateralObjectId"
    		    		onChange={value => this.handleValueChange('collaterals', value)}/>
        		</Grid>
    			<Grid item xs={12} className={classes.gridItem}>
                    <Button className={classes.generateExample} 
                            variant="contained" 
                            color="secondary" 
                            onClick={event => this.generateExample()}>
                        Generate &nbsp; <BuildIcon/>
                    </Button>
    			</Grid>
    		</Grid>
        );
    }
}

ExampleBuilder.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExampleBuilder);
