import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	root: {
        fontFamily: 'Poppins-Regular',
        fontSize: '.9rem',
        fontWeight: '400',
        lineHeight: '2',
        textAlign: 'right',
        paddingRight: '20px',
        backgroundColor: '#f1f5f6',
        //padding: '0px 0px 0px 50px',
        //margin: '50px 0px 0px 0px',
        //width: '99%',
        //height: '30px',
        //position: 'fixed',
        //right: '100px',
        //bottom: '-10px'
	},
});

class Footer extends Component {
    render() {
        const { classes } = this.props;

        return (            
            <div className={classes.root}>
                &copy; Aryza GmbH 2024
            </div>
        );
    }
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
