export default {

	files: [], mutations :[], queries:[], jobs:[], events:[],
	workflows: [{
        title: "Workflow - start [since v4.66]",

		label: "startWorkflow",

		path: "v2_examples_startWorkflow",

		message:
`mutation {
    startWorkflow(
        name: "TestTemplate123"
        description: "TestTemplate1TestTemplate1TestTemplate1TestTemplate1"
        templateType: "Recht"
        templateId: "TestTemplate1"
        objectType: "KND"
        objectId: "-598392ca:186986d7c31:-7e3d"
    ) {
        status
    }
}`

	}], blanks:[]
}