import React, { Component } from 'react';

import  { Redirect } from 'react-router-dom'

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from '../styles';

import { getJWTObject } from '../connect/authentication';

import store from "../connect/store";
import { loginRequested, loginPerformed } from "../connect/actions";

class Callback extends Component {

    render() {
        const { classes } = this.props;
        var jwt = getJWTObject();
        if(this.props.auth) {
            return <Redirect to='/'/>
        } else if(jwt) {
            store.dispatch(loginPerformed(jwt));
            return <Redirect to='/'/>
        } else {
            const urlParams = new URLSearchParams(window.location.search);
            store.dispatch(loginRequested(urlParams.get('code')));

            return (
                <div className={classes.root}>
                    <div className={classes.workarea}>
                        <div className={classes.paper}>
                            <span className={classes.title}>Waiting for login</span>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

Callback.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Callback);
