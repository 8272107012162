export default {

	jobs: [{
    
    title: "Start - ArchivingScheduler [since v4.60]",

		label: "start - ArchivingScheduler",

		path: "start_ArchivingScheduler_job_v2_examples_start",

		message:
`mutation { 
  startJob ( 
    Function: "net.abit.model.archiving.scheduler.ArchivingScheduler.start"
  ) { 
    JobId 
    Status
  } 
}`

	}, {
    
    title: "Start - FoBereBisHeuteJob [since v4.60]",

		label: "start - FoBereBisHeuteJob",

		path: "start_FoBereBisHeuteJob_job_v2_examples_start",

		message:
`mutation { 
  startJob ( 
    Function: "net.abit.model.kontoplus.forderung.scheduler.FoBereBisHeuteJob.start"
    ConfigurationParameters: [
      { Key: "nurLebende"          , Value: "true"       },
      { Key: "berechnungZum"       , Value: "2023-01-20" },
      { Key: "berechnungSchuldrech", Value: "true"       }]
  ) { 
    JobId
    Status
  } 
}`

	}, {
    
    title: "Start - QuartalsmeldungWeiterleitungDtaMP [since v4.60]",

		label: "start - QuartalsmeldungWeiterleitungDtaMP",

		path: "start_QuartalsmeldungWeiterleitungDtaMP_job_v2_examples_start",

		message:
`mutation { 
  startJob ( 
    JobName: "JobNameTest"
    SchedulerId: "SchedulerIdTest"
    Function: "net.abit.model.schufa.QuartalsmeldungWeiterleitungDtaMP.start"
    ConfigurationParameters: [
      { Key: "NaechstLfdNrExport", Value: "10"   },
      { Key: "SCHUFA_FORMAT"     , Value: "DATA" }]
    TestMode: false
    NoCreate: false
    NoValidation:false
    Restore: false
  ) { 
    JobId 
    Status
  } 
}`

	}, {

    title: "Get Status [since v4.60]",

		label: "getStatus",

		path: "start_job_v2_examples_getStatus",

		message:
`query { 
  getJobStatus ( 
    JobId: "d6f310c9-3137-46e5-8908-6a9d79676436"
  ) { 
    JobId 
    Status
  } 
}`

	}], 

  mutations :[], 

  queries:[], 

  workflows:[], 

  files:[], 

  blanks:[], 

  events:[]

}