import { 
	EXAMPLE_SEND, 
	EXAMPLE_RECEIVE, 
	API_ERRORED, 
	LOGIN_REQUESTED, 
	LOGIN_PERFORMED, 
	REFRESH_TOKEN_REQUESTED,
	REFRESH_TOKEN_PERFORMED, 
	LOGOUT_REQUESTED, 
	LOGOUT_PERFORMED,
	LIST_DOCUMENTS_REQUEST,
	LIST_DOCUMENTS_RESPONSE, 
	DELETE_DOCUMENT_REQUEST,
	UPLOAD_DOCUMENT_REQUEST,
	DOWNLOAD_DOCUMENT_REQUEST,
	DOWNLOAD_DOCUMENT_RESPONSE,
    RECIEVE_EVENTS_REQUEST,
    RECIEVE_EVENTS_RESPONSE,
    RECIEVE_EVENTS_ERROR
} from "./constants";

export function exampleSend(message, exampleName) {
    return { type: EXAMPLE_SEND, payload: {message : message, exampleName : exampleName} };
};

export function exampleReceive(payload) {
    return { type: EXAMPLE_RECEIVE, payload: payload };
};

export function apiError(payload) {
    return { type: API_ERRORED, payload: payload };
};

export function loginRequested(payload) {
    return { type: LOGIN_REQUESTED, payload };
};

export function loginPerformed(payload) {
    return { type: LOGIN_PERFORMED, payload };
};

export function refreshTokenRequested(refreshToken, email) {
    return { type: REFRESH_TOKEN_REQUESTED, payload: {refreshToken: refreshToken, email: email} };
};

export function refreshTokenPerformed(payload) {
    return { type: REFRESH_TOKEN_PERFORMED, payload };
};

export function logoutRequested(payload) {
    return { type: LOGOUT_REQUESTED, payload };
};

export function logoutPerformed(payload) {
    return { type: LOGOUT_PERFORMED, payload };
};

export function listDocumentsSend(payload) {
    return { type: LIST_DOCUMENTS_REQUEST, payload };
}

export function listDocumentsReceive(payload) {
    return { type: LIST_DOCUMENTS_RESPONSE, payload };
}

export function deleteDocumentSend(payload) {
    return { type: DELETE_DOCUMENT_REQUEST, payload };
}

export function uploadDocumentSend(payload) {
    return { type: UPLOAD_DOCUMENT_REQUEST, payload };
}

export function downloadDocumentSend(payload) {
    return { type: DOWNLOAD_DOCUMENT_REQUEST, payload };
}

export function downloadDocumentReceive(payload) {
    return { type: DOWNLOAD_DOCUMENT_RESPONSE, payload };
}

export function recieveEventsRequest(payload) {
    return { type: RECIEVE_EVENTS_REQUEST, payload };
};

export function recieveEventsResponse(payload) {
    return { type: RECIEVE_EVENTS_RESPONSE, payload };
};

export function recieveEventsError(payload) {
    return { type: RECIEVE_EVENTS_ERROR, payload: payload };
};