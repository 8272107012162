import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import DropDownMenu from "./DropDownMenu";

import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import awsConfig from "../aws-config.json";

const styles = (theme) => ({
  root: {
    fontFamily: "Poppins-Regular",
    fontWeight: "400",
    lineHeight: "1.5",
    padding: "5px 5px 5px 5px",
    height: "72px",
    backgroundColor: "#0D1D41",
    color: "#FFFFFF",
    transition: "all .3s ease",
    boxShadow: "0 1px 1px rgba(0,0,0,.15)",
    display: "flex",
  },
  mainTitle: {
    fontFamily: "Poppins-Bold",
    fontSize: "1.2rem",
    verticalAlign: "middle",
    display: "inline-block",
    padding: "2px 0px 0px 25px",
  },
  title: {
    fontFamily: "Poppins-Regular",
    fontSize: "1.2rem",
    verticalAlign: "middle",
    padding: "0px 0px 0px 4px",
  },
  user: {
    padding: "7px 10px 0px 0px",
    verticalAlign: "middle",
    display: "inline-block",
    float: "right",
    fontSize: "0.875rem",
  },
  logo: {
    verticalAlign: "middle",
    padding: "5px 5px 5px 5px",
  },
  spacer: {
    height: "20px",
    width: "20px",
    float: "right",
  },
  menuButtonContainer: {
    float: "right",
  },
  menuButton: {
    fontFamily: "Poppins-Regular",
    fontSize: "0.875rem",
    fontWeight: "500",
    lineHeight: "1.75",
    verticalAlign: "bottom",
    //marginTop: '30px',
    padding: "1px 8px 1px 8px",
    color: "#FFFFFF",
  },
  menuButtonSelected: {
    fontFamily: "Poppins-Regular",
    fontSize: "0.875rem",
    fontWeight: "500",
    lineHeight: "1.75",
    verticalAlign: "bottom",
    //marginTop: '30px',
    padding: "1px 8px 1px 8px",
    color: "#2e32ae",
  },
  loginButton: {
    fontFamily: "Poppins-Regular",
    fontSize: "0.875rem",
    fontWeight: "500",
    lineHeight: "1.75",
    verticalAlign: "bottom",
    //marginTop: '30px',
    padding: "1px 8px 1px 8px",
    color: "#FFFFFF",
  },
});

const gettingStartedMenu = [
  { path: "/gettingstarted/architecture", text: "Architecture" },
  { path: "/gettingstarted/httpLevelAccess", text: "HTTP level access" },
  { path: "/gettingstarted/blueprint", text: "Blue Print" },
];

const apisMenu = [
  { path: "/graphql/v2", text: "APIv2 [All Products - since v4.55]" },
  {
    path: "/graphql/v1",
    text: "APIv1 [Special Use Cases - since v4.40]",
  },
];

const examplesMenu = [
  { path: "/examples/v2", text: "APIv2 entities [since v4.55]" },
  { path: "/examples/startJobV2", text: "APIv2 start job [since v4.60]" },
  { path: "/examples/workflowsV2", text: "APIv2 workflows [since v4.66]" },
  { path: "/examples/fileSharingV2", text: "APIv2 file sharing [since v4.66]" },
  { path: "/examples/eventsV2", text: "APIv2 events [since v4.76]" },
  {
    path: "/examples/originate",
    text: "APIv1 originate [since v4.40]",
  },
  { path: "/examples/score", text: "APIv1 score [since v4.40, deprecated]" },
  {
    path: "/examples/collectAndRecovery",
    text: "APIv1 collect and recovery [since v4.40]",
  },
];

const callSequenceMenu = [
  {
    path: "/callsequence/originate",
    text: "APIv1 originate [since v4.40]",
  },
  {
    path: "/callsequence/score",
    text: "APIv1 score [since v4.40, deprecated]",
  },
];

class Header extends Component {
  state = {
    selectedPath: null,
    mainTitle: "lending solutions API",
    title: " - Public Documentation",
  };

  handleListItemClick = (event, path) => {
    if (path && path.startsWith("/gettingstarted/")) {
      this.setState({
        selectedPath: path,
        title:
          " - " +
          gettingStartedMenu.find((item) => item.path === path, { text: "" })
            .text,
      });
    } else if (path && path.startsWith("/graphql/")) {
      this.setState({
        selectedPath: path,
        title:
          " - APIs - " +
          apisMenu.find((item) => item.path === path, { text: "" }).text,
      });
    } else if (path && path.startsWith("/examples/")) {
      this.setState({
        selectedPath: path,
        title:
          " - Examples - " +
          examplesMenu.find((item) => item.path === path, { text: "" }).text,
      });
    } else if (path && path.startsWith("/callsequence/")) {
      this.setState({
        selectedPath: path,
        title:
          " - Call Sequence - " +
          callSequenceMenu.find((item) => item.path === path, { text: "" })
            .text,
      });
    } else if (path && path.startsWith("/gettingstarted/architecture")) {
      this.setState({
        selectedPath: path,
        title: " - Architecture",
      });
    } else if (path && path.startsWith("/gettingstarted/httpLevelAccess")) {
      this.setState({
        selectedPath: path,
        title: " - HTTP Level Access",
      });
    } else if (path && path.startsWith("/gettingstarted/blueprint")) {
      this.setState({
        selectedPath: path,
        title: " - Blue Print",
      });
    } else if (path && path.startsWith("/ai")) {
      this.setState({
        selectedPath: path,
        title: " - AI Inference API",
      });
    } else {
      this.setState({
        selectedPath: path,
        title: " - Public API Documentation",
      });
    }
    this.props.history.push(path);
  };

  render() {
    const { classes } = this.props;
    const { selectedPath, mainTitle, title } = this.state;

    let auth =
      !process.env.NODE_ENV || process.env.NODE_ENV === "development"
        ? awsConfig.development
        : awsConfig.production;

    const loginUrl =
      "https://" +
      auth.AppWebDomain +
      ":" +
      auth.AppWebPort +
      auth.AppWebLoginPath +
      "?response_type=code&client_id=" +
      auth.ClientId +
      "&scope=" +
      auth.AppWebScope +
      "&redirect_uri=" +
      auth.RedirectUriSignIn;

    const logoutUrl =
      "https://" +
      auth.AppWebDomain +
      ":" +
      auth.AppWebPort +
      auth.AppWebLogoutPath +
      "?client_id=" +
      auth.ClientId +
      "&logout_uri=" +
      auth.RedirectUriSignOut;

    return (
      <div className={classes.root}>
        <img
          alt="aryza"
          className={classes.logo}
          src={process.env.PUBLIC_URL + "/logo-aryza.png"}
        />
        <div style={{ width: "100%" }}>
          <div style={{ padding: "19px 0px 0px 0px" }}>
            <span className={classes.mainTitle}>{mainTitle}</span>
            <span className={classes.title}>{title}</span>
            <span className={classes.user}>
              {this.props.auth ? this.props.auth.email : ""}
            </span>
          </div>

          <div style={{ width: "100%", height: "26px" }}>
            <div className={classes.spacer} />
            <div className={classes.menuButtonContainer}>
              <DropDownMenu
                items={[]}
                loginUrl={loginUrl}
                logoutUrl={logoutUrl}
              />
            </div>
            <DropDownMenu
              selected={
                selectedPath && selectedPath.startsWith("/callsequence/")
              }
              title="Call Sequence"
              items={callSequenceMenu}
              onClick={this.handleListItemClick}
            />

            <DropDownMenu
              selected={selectedPath && selectedPath.startsWith("/examples/")}
              title="Examples"
              items={examplesMenu}
              onClick={this.handleListItemClick}
            />

            <DropDownMenu
              selected={selectedPath && selectedPath.startsWith("/graphql/")}
              title="APIs"
              items={apisMenu}
              onClick={this.handleListItemClick}
            />

            <DropDownMenu
              selected={
                selectedPath && selectedPath.startsWith("/gettingstarted/")
              }
              title="Getting Started"
              items={gettingStartedMenu}
              onClick={this.handleListItemClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Header));
