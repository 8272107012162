import { LOGIN_PERFORMED, REFRESH_TOKEN_PERFORMED, LOGOUT_PERFORMED, AUTH_KEY } from "./constants";

export function authenticationMiddleware({dispatch}) {
    return function(next) {
        return function(action) {
            if(action.type === LOGIN_PERFORMED) {
                localStorage.setItem(AUTH_KEY, JSON.stringify(action.payload));
            } else if(action.type === REFRESH_TOKEN_PERFORMED) {
                localStorage.setItem(AUTH_KEY, JSON.stringify(action.payload));
            } else if(action.type === LOGOUT_PERFORMED) {
                localStorage.removeItem(AUTH_KEY)
            }
            return next(action);
        };
    };
}

export const getJWTObject = (): object => {
    return JSON.parse(localStorage.getItem(AUTH_KEY));
}
