import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles }    from '@material-ui/core/styles';
import TextField         from '@material-ui/core/TextField';
import GridList          from '@material-ui/core/GridList';
import GridListTile      from '@material-ui/core/GridListTile';
import IconButton        from '@material-ui/core/IconButton';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddCircleIcon     from '@material-ui/icons/AddCircle';

import styles from '../styles';

class ObjectEditor extends Component {
    state = {
        name        : null,
        data        : null,
        base        : null,
        nextIdNo    : 0,
        idPrefix    : null,
        idPropName  : null,
        id2Prefix   : null,
        id2PropName : null
    };

    constructor(props) {
        super(props);
        this.state = {
            name        : this.props.name, 
            data        : this.props.data, 
            base        : Array.isArray(this.props.data) ? this.props.data[0]         : this.props.data,
            nextIdNo    : Array.isArray(this.props.data) ? this.props.data.length + 1 : 1,
            idPrefix    : this.props.idPrefix,
            idPropName  : this.props.idPropName,
            id2Prefix   : this.props.id2Prefix,
            id2PropName : this.props.id2PropName
        };
    }

    handleValueChange = (event, propName, idx) => {
        if(Array.isArray(this.state.data)) {
            let data = idx > 0 ? this.state.data.slice(0, idx) : [];
            data.push(Object.assign({}, this.state.data[idx], {[propName]: event.target.value}));
            this.setState({
                data: data.concat(idx < this.state.data.length - 1 ? 
                    this.state.data.slice(idx + 1, this.state.data.length) : 
                    []
                )
            }, () => {this.props.onChange(this.state.data)});
        } else {
            this.setState(
                {data: Object.assign({}, this.state.data, {[propName]: event.target.value})}, 
                () => {this.props.onChange(this.state.data)}
            );            
        }
    }

    deleteExample = (idx) => {
        let data = idx > 0 ? this.state.data.slice(0, idx) : [];
        this.setState({
            data: data.concat(idx < this.state.data.length - 1 ? 
                this.state.data.slice(idx + 1, this.state.data.length) : 
                []
            )
        }, () => {this.props.onChange(this.state.data)});        
    }

    addExample = () => {
        let s = this.state.nextIdNo + "";
        while (s.length < 3) s = "0" + s;

        let data = this.state.data.slice();
        let newObj = {[this.state.idPropName]: this.state.idPrefix + s};
        if(this.state.id2PropName) {
            newObj[this.state.id2PropName] = this.state.id2Prefix + s;
        }
        data.push(Object.assign({}, this.state.base, newObj));
        this.setState({
            data     : data, 
            nextIdNo : this.state.nextIdNo + 1
        }, () => {this.props.onChange(this.state.data)});
    }

    render() {
    	const { classes } = this.props;
    	return (
    		<div className={classes.objectEditor}>
                <span className={classes.objectEditorTitle}>{this.state.name}</span>
                {Array.isArray(this.state.data) ? this.state.data.length === 0 ? (
                    <div>
                        <IconButton color="secondary" style={{width: '50px'}} onClick={event => this.addExample()}>
                            <AddCircleIcon/>
                        </IconButton>
                    </div>
                ) : (
                    <div>
                        {this.state.data.map((obj, idx) => (
                            <GridList cellHeight={50} className={classes.gridList} cols={10} key={this.state.name + ' [' + idx + ']'}>
                                <IconButton color="primary" style={{width: '50px'}} onClick={event => this.deleteExample(idx)}>
                                    <DeleteForeverIcon/>
                                </IconButton>
                                {Object.keys(obj).map(propName => (
                                    <GridListTile key={this.state.name + ' [' + idx + '] ' + propName} cols={1}>
                                        <TextField 
                                            style={{width: '144px'}}
                                            id={this.state.name + ' [' + idx + '] ' + propName} 
                                            label={propName} 
                                            value={obj[propName]}
                                            onChange={event => this.handleValueChange(event, propName, idx)}/>
                                    </GridListTile>
                                ))}
                                {idx === (this.state.data.length - 1) ? (
                                    <IconButton color="secondary" style={{width: '50px'}} onClick={event => this.addExample()}>
                                        <AddCircleIcon/>
                                    </IconButton>
                                ) : ""}
                            </GridList>
                        ))}
                    </div> 
                ) : (
                    <GridList cellHeight={50} className={classes.gridList} cols={7}>
                        {Object.keys(this.state.data).map(propName => (
                            <GridListTile key={propName} cols={1}>
                                <TextField 
                                    style={{width: '180px'}}
                                    id={propName} 
                                    label={propName} 
                                    value={this.state.data[propName]}
                                    onChange={event => this.handleValueChange(event, propName)}/>
                            </GridListTile>
                        ))}
                    </GridList>
                )}
    		</div>
        );
    }
}

ObjectEditor.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ObjectEditor);
