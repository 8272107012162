export const EXAMPLE_SEND    = "EXAMPLE_SEND";
export const EXAMPLE_RECEIVE = "EXAMPLE_RECEIVE";
export const API_ERRORED     = "API_ERRORED";

export const RECIEVE_EVENTS_REQUEST = "RECIEVE_EVENTS_REQUEST";
export const RECIEVE_EVENTS_RESPONSE = "RECIEVE_EVENTS_RESPONSE";
export const RECIEVE_EVENTS_ERROR = "RECIEVE_EVENTS_ERROR";

export const LOGIN_REQUESTED         = "LOGIN_REQUESTED";
export const LOGIN_PERFORMED         = "LOGIN_PERFORMED";
export const REFRESH_TOKEN_REQUESTED = "REFRESH_TOKEN_REQUESTED";
export const REFRESH_TOKEN_PERFORMED = "REFRESH_TOKEN_PERFORMED";
export const LOGOUT_REQUESTED        = "LOGOUT_REQUESTED";
export const LOGOUT_PERFORMED        = "LOGOUT_PERFORMED";

export const AUTH_KEY = "AUTH";

export const LIST_DOCUMENTS_REQUEST     = "LIST_DOCUMENTS_REQUEST";
export const LIST_DOCUMENTS_RESPONSE    = "LIST_DOCUMENTS_RESPONSE";
export const DELETE_DOCUMENT_REQUEST    = "DELETE_DOCUMENT_REQUEST";
export const UPLOAD_DOCUMENT_REQUEST    = "UPLOAD_DOCUMENT_REQUEST";
export const DOWNLOAD_DOCUMENT_REQUEST  = "DOWNLOAD_DOCUMENT_REQUEST";
export const DOWNLOAD_DOCUMENT_RESPONSE = "DOWNLOAD_DOCUMENT_RESPONSE";
