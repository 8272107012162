export default {

	queries: [{
        title: "Query - getAccountV2 [since v4.55]",

		label: "getAccountV2",

		path: "v2_examples_getAccountV2",

		message:
`query { 
  getAccountV2( 
    filter: { 
     Accountnumber: "123456789"
    }
  ) { 
      Account_type 
      Accountnumber 
      ObjectId
    } 
 }`

	},
	{
		title: "Query - listAccountV2 [since v4.55]",

		label: "listAccountV2",

		path: "v2_examples_listAccountV2",

		message:
`query { 
  listAccountV2( 
    filter: { 
     Accountnumber: "123456789"
    }, fetchSize: 2, startIndex: 0
  ) { 
      results{
       ObjectId
       Accountnumber
       Account_type
      }
      totalNumberOfResults
      moreResultsAvailable
    } 
}`
	},
	{
		title: "Query - getClaimResultV2 [since v4.55]",

		label: "getClaimResultV2",

		path: "v2_examples_getClaimResultV2",

		message:
`query { 
  getClaimResultV2( 
    filter: { 
     ClaimNumberExtension: "VNDL"
     ClaimNumber: "12345678"
     ObjectId: "c879a6c3-e768-4c7c-a2db-efe1f"
    }
  ) { 
     ObjectId
     ClaimNumber
     ClaimNumberExtension
     ClaimCalcResults {
       Currency
     }
  } 
}`

	},
	{
		title: "Query - listClaimResultV2 [since v4.55]",

		label: "listClaimResultV2",

		path: "v2_examples_listClaimResultV2",

		message:
`query { 
  listClaimResultV2( 
    filter: { 
     ClaimNumberExtension: "VNDL"
     ClaimNumber: "12345678"
     ObjectId: "c879a6c3-e768-4c7c-a2db-efe1f"
    }, fetchSize:5, startIndex:0
    ) { 
     moreResultsAvailable
     totalNumberOfResults
     results{
       ClaimNumber
       Receivable_status{
         ObjectId
         Claim_Status{
           Key
           ShortText
           LongText
         }
         TimeFordstatus
        }
      ObjectId
      ClaimNumberExtension
     }
  } 
}`

	},
	{
		title: "Query - getClaimSegmentsV2 [since v4.55]",

		label: "getClaimSegmentsV2",

		path: "v2_examples_getClaimSegmentsV2",

		message:
`query { 
  getClaimSegmentsV2( 
    filter: { 
      Customernumber: "API0000168"
      Name1: "customer 68"
    }
  ) { 
    Name1
    Customernumber
    ObjectId
    ExternID
    Segment{
      ObjectId
      Segmentnumber
      Currency
      ClaimReason{
        Key
        ShortText
        LongText
     }
    }
    Name2
   } 
}`

	},
	{
		title: "Query - listClaimSegmentsV2 [since v4.55]",

		label: "listClaimSegmentsV2",

		path: "v2_examples_listClaimSegmentsV2",

		message:
`query { 
  listClaimSegmentsV2( 
    filter: { 
      Customernumber: "API0000168"
      Name1: "customer 68"
    }, fetchSize: 5, startIndex: 0
    ) { 
        totalNumberOfResults
        moreResultsAvailable
        results{
         Name1
         Customernumber
         ObjectId
         ExternID
         Name2
         }
      } 
}`

	},
	{
		title: "Query - getCollateralV2 [since v4.55]",

		label: "getCollateralV2",

		path: "v2_examples_getCollateralV2",

		message:
`query {
    getCollateralV2(
      filter: {
        Currency: "EUR"
       }
      )
    {
        ObjectId
        Currency
        ValidFrom
    }
}`

	},
	{
		title: "Query - listCollateralV2 [under construction]",

		label: "listCollateralV2",

		path: "v2_examples_listCollateralV2",

		message:
`query {
    listCollateralV2(
      filter: {
         Currency: "EUR"
        }
          fetchSize: 10
          startIndex: 0
       )
    {
        totalNumberOfResults
        moreResultsAvailable
        results {
            ObjectId
            Currency
        }
     
    }
}`

	},
	{
		title: "Query - listCollateralObjectV2 [under construction]",

		label: "listCollateralObjectV2",

		path: "v2_examples_listCollateralObjectV2",

		message:
`query {
    listCollateralObjectV2(
      filter: {
          CollateralObjectType: "GFND"
        }
          fetchSize: 5
          startIndex: 0
       )
    {
        totalNumberOfResults
        moreResultsAvailable
        results {
            ObjectId
            ObjectNumberExternal
            CollateralObjectType{
              Key
              ShortText
              LongText
            }
        }
     
    }
}`

	},
	{
		title: "Query - listCollateralObjectValuationV2 [since v4.55]",

		label: "listCollateralObjectValuationV2",

		path: "v2_examples_listCollateralObjectValuationV2",

		message:
`query {
    listCollateralObjectValuationV2(
       filter: {
         ObjectId: "5f65677d-3948-4d0e-98fe34"
        }
          fetchSize: 68
          startIndex: 0
       )
    {
        totalNumberOfResults
        moreResultsAvailable
        results {
            Valuation
            ValuationType{
              Key
              ShortText
              LongText
           }
            ObjectId
        }
    }
}`

	},
	{
		title: "Query - listCollateralObjectValuationREV2 [since v4.55]",

		label: "listCollateralObjectValuationREV2",

		path: "v2_examples_listCollateralObjectValuationREV2",

		message:
`query {
    listCollateralObjectValuationREV2(
       filter: {
          BG5Percent: 5
          Currency: "EUR"
        }
          fetchSize: 100
          startIndex: 0
       )
    {
        totalNumberOfResults
        moreResultsAvailable
        results {
            ValuationType{
              Key
              ShortText
              LongText
           }
            ObjectId
            Currency
            BG5Percent
        }
    }
}`

	},
	{
        title: "Query - getCustomerV2 [since v4.55]",

		label: "getCustomerV2",

		path: "v2_examples_getCustomerV2",

		message:
`query { 
  getCustomerV2( 
    filter: { 
     AccountNumber: "123456789"
    }
  ) { 
      AccountNumber 
      ObjectId
    } 
 }`

	},
	{
		title: "Query - listCustomerV2 [since v4.55]",

		label: "listCustomerV2",

		path: "v2_examples_listCustomerV2",

		message:
`query { 
  listCustomerV2( 
    filter: { 
     AccountNumber: "123456789"
    }, fetchSize: 2, startIndex: 0
  ) { 
      results{
       ObjectId
       AccountNumber
      }
      totalNumberOfResults
      moreResultsAvailable
    } 
}`
	}, {
		title: "Query - getCustomerUnitV2 [since v4.55]",

		label: "getCustomerUnitV2",

		path: "v2_examples_getCustomerUnitV2",

		message:
`query { 
  getCustomerUnitV2( 
    filter: { 
     Name: "KALOYAN"
    }
  ) { 
      ObjectId
    } 
 }`
	}, {
		title: "Query - getCustomerUnitMemberV2 [since v4.55]",

		label: "getCustomerUnitMemberV2",

		path: "v2_examples_getCustomerUnitMemberV2",

		message:
`query { 
  getCustomerUnitMemberV2( 
    filter: { 
     Name: "Johnatan"
    }
  ) { 
      ObjectId
      CommitmentNumber
      Name
      Customer {
       Name1
       Name2
       Customernumber
      }
    } 
 }`
	},{
		title: "Query - listCustomerUnitMemberV2 [since v4.55]",

		label: "listCustomerUnitMemberV2",

		path: "v2_examples_listCustomerUnitMemberV2",

		message:
`query { 
  listCustomerUnitMemberV2( 
    filter: { 
     Name: "Johnatan Yarn"
    }, fetchSize: 10, startIndex: 0
  ) { 
      totalNumberOfResults
      moreResultsAvailable
      results {
       CommitmentNumber
       Name
       Customer{
        Name1
        Name2
        Customernumber
       }
      }
    } 
 }`
	}, {
		title: "Query - getCustomerUnitAssociationV2 [since v4.55]",

		label: "getCustomerUnitAssociationV2",

		path: "v2_examples_getCustomerUnitAssociationV2",

		message:
`query { 
  getCustomerUnitAssociationV2( 
    filter: { 
     compoundName: "new_name"
    }
  ) { 
      ObjectId
    } 
 }`
	},{
		title: "Query - listCustomerUnitAssociationV2",

		label: "listCustomerUnitAssociationV2",

		path: "v2_examples_listCustomerUnitAssociationV2",

		message:
`query { 
  listCustomerUnitAssociationV2( 
    filter: { 
     compoundName: "new_name"
    }, fetchSize: 10, startIndex: 0
  ) { 
      totalNumberOfResults
      moreResultsAvailable
      results {
       compoundName
      }
    } 
 }`
	}, {
		title: "Query - getCustomerUnitCustomerV2 [since v4.55]",

		label: "getCustomerUnitCustomerV2",

		path: "v2_examples_getCustomerUnitCustomerV2",

		message:
`query { 
  getCustomerUnitCustomerV2( 
    filter: { 
     ObjectId: "123"
     CompoundPosition: "Position1"
    }
  ) { 
      ObjectId
      CompoundPosition
    } 
 }`
	}, {
		title: "Query - getLACurrentAccountV2 [since v4.55]",

		label: "getLACurrentAccountV2",

		path: "v2_examples_getLACurrentAccountV2",

		message:
`query { 
  getLACurrentAccountV2( 
    filter: { 
     Accountnumber: "123"
     IAccountNumber: 456
    }
  ) { 
      ObjectId
      Reason
    } 
 }`
	}, {
		title: "Query - listLACurrentAccountV2 [since v4.55]",

		label: "listLACurrentAccountV2",

		path: "v2_examples_listLACurrentAccountV2",

		message:
`query { 
  listLACurrentAccountV2( 
    filter: { 
     Accountnumber: "123456789"
    }, fetchSize: 2, startIndex: 0
  ) { 
      results{
       ObjectId
       IAccountNumber
      }
      totalNumberOfResults
      moreResultsAvailable
    } 
}`
	}, {
		title: "Query - getLALoanV2 [since v4.55]",

		label: "getLALoanV2",

		path: "v2_examples_getLALoanV2",

		message:
`query { 
  getLALoanV2( 
    filter: { 
     ObjectId: "23234"
    }
  ) { 
      ObjectId
      IDExtern
    } 
 }`
	}, {
		title: "Query - listLALoanV2 [since v4.55]",

		label: "listLALoanV2",

		path: "v2_examples_listLALoanV2",

		message:
`query { 
  listLALoanV2( 
    filter: { 
     Term_fee: 12
    }, fetchSize: 2, startIndex: 0
  ) { 
      results{
       ObjectId
      }
      totalNumberOfResults
      moreResultsAvailable
    } 
}`
	}, {
		title: "Query - getLegalMeasuresV2 [since v4.55]",

		label: "getLegalMeasuresV2",

		path: "v2_examples_getLegalMeasuresV2",

		message:
`query { 
  getLegalMeasuresV2( 
    filter: { 
     ObjectId: "23234"
    }
  ) { 
      ObjectId
      IBAN
    } 
 }`
	}, {
		title: "Query - listLegalMeasuresV2 [since v4.55]",

		label: "listLegalMeasuresV2",

		path: "v2_examples_listLegalMeasuresV2",

		message:
`query { 
  listLegalMeasuresV2( 
    filter: { 
     creator: "CreatorName"
    }, fetchSize: 2, startIndex: 0
  ) { 
      results{
       ObjectId
      }
      totalNumberOfResults
      moreResultsAvailable
    } 
}`
	}, {
		title: "Query - getLoanConditionsV2 [since v4.55]",

		label: "getLoanConditionsV2",

		path: "v2_examples_getLoanConditionsV2",

		message:
`query { 
  getLoanConditionsV2( 
    filter: { 
     ObjectId: "23234"
    }
  ) { 
      ObjectId
      Accountnumber
    } 
 }`
	}, {
		title: "Query - getWorkflowV2 [since v4.55]",

		label: "getWorkflowV2",

		path: "v2_examples_getWorkflowV2",

		message:
`query { 
  getWorkflowV2( 
    filter: { 
     ObjectOID: "23234"
    }
  ) { 
      ObjectId
      Status{
        Key
        ShortText
        LongText
      }
    } 
 }`
	}, {
		title: "Query - listWorkflowV2 [since v4.55]",

		label: "listWorkflowV2",

		path: "v2_examples_listWorkflowV2",

		message:
`query { 
  listWorkflowV2( 
    filter: { 
     Bold: true
    }, fetchSize: 2, startIndex: 0
  ) { 
      results{
       ObjectId
      }
      totalNumberOfResults
      moreResultsAvailable
    } 
}`
	}, {
		title: "Query - getWorkflowParameterV2 [since v4.55]",

		label: "getWorkflowParameterV2",

		path: "v2_examples_getWorkflowParameterV2",

		message:
`query { 
  getWorkflowParameterV2( 
    filter: { 
     ParameterName: "Param1"
    }
  ) { 
      ParameterName
      ParaValue
      ObjectId
    } 
 }`
	}, {
		title: "Query - listWorkflowParameterV2 [since v4.55]",

		label: "listWorkflowParameterV2",

		path: "v2_examples_listWorkflowParameterV2",

		message:
`query { 
  listWorkflowParameterV2( 
    filter: { 
     ParaValue: "TestValue"
    }, fetchSize: 2, startIndex: 0
  ) { 
      results{
       ObjectId
      }
      totalNumberOfResults
      moreResultsAvailable
    } 
}`
	}, {
		title: "Query - getWorkflowResultsV2 [since v4.55]",

		label: "getWorkflowResultsV2",

		path: "v2_examples_getWorkflowResultsV2",

		message:
`query { 
  getWorkflowResultsV2( 
    filter: { 
     ObjectId: "112324"
    }
  ) { 
      Type
      ObjectId
    } 
 }`
	}, {
		title: "Query - listWorkflowResultsV2 [since v4.55]",

		label: "listWorkflowResultsV2",

		path: "v2_examples_listWorkflowResultsV2",

		message:
`query { 
  listWorkflowResultsV2( 
    filter: { 
     Type: "TestType"
    }, fetchSize: 2, startIndex: 0
  ) { 
      results{
       ObjectId
      }
      totalNumberOfResults
      moreResultsAvailable
    } 
}`
	}, {
		title: "Query - getWorkflowRulesV2 [since v4.55]",

		label: "getWorkflowRulesV2",

		path: "v2_examples_getWorkflowRulesV2",

		message:
`query { 
  getWorkflowRulesV2( 
    filter: { 
     ObjectId: "112324"
    }
  ) { 
      RuleName
      ObjectId
    } 
 }`
	}, {
		title: "Query - listWorkflowRulesV2 [since v4.55]",

		label: "listWorkflowRulesV2",

		path: "v2_examples_listWorkflowRulesV2",

		message:
`query { 
  listWorkflowRulesV2( 
    filter: { 
     RuleName: "TestRule"
    }, fetchSize: 2, startIndex: 0
  ) { 
      results{
       ObjectId
      }
      totalNumberOfResults
      moreResultsAvailable
    } 
}`
	}, 
	{
		title: "Query - getMortgageV2 [since v4.55]",

		label: "getMortgageV2",

		path: "v2_examples_getMortgageV2",

		message:
`query { 
  getMortgageV2( 
    filter: { 
     RankAt: "1"
     CollateralType:"10"
    }
  ) { 
      ObjectId
      RankAt{
       Key
       ShortText
       LongText
     }
      CollateralType{
       Key
       ShortText
       LongText
     }
    } 
}`
	},
	{
		title: "Query - listPostingV2 [since v4.55]",

		label: "listPostingV2",

		path: "v2_examples_listPostingV2",

		message:
`query { 
  listPostingV2( 
    filter: { 
      OrderIBAN: "BGIN1230701323"
      VATAmount: 2
    }, fetchSize: 10, startIndex: 0
  ) { 
      results{
       ObjectId
       OrderIBAN
       VATAmount
      }
      totalNumberOfResults
      moreResultsAvailable
    } 
}`
	},
	{
		title: "Query - listPublicGuaranteeV2 [since v4.55]",

		label: "listPublicGuaranteeV2",

		path: "v2_examples_listPublicGuaranteeV2",

		message:
`query { 
  listPublicGuaranteeV2( 
    filter: { 
      IsGuarantee: true
    }, fetchSize: 10, startIndex: 0
  ) { 
      results{
       ObjectId
       IsGuarantee
      }
      totalNumberOfResults
      moreResultsAvailable
    } 
}`
	},
	{
		title: "Query - getPublicGuaranteeV2 [since v4.55]",

		label: "getPublicGuaranteeV2",

		path: "v2_examples_getPublicGuaranteeV2",

		message:
`query { 
 getPublicGuaranteeV2( 
   filter: { 
    IsGuarantee: true
    CollateralType: "11"
    }
  ) {
       ObjectId
       IsGuarantee
       CollateralType{
         Key
         ShortText
         LongText
      }
    } 
}`
	},
	{
		title: "Query - getRealEstateV2 [since v4.55]",

		label: "getRealEstateV2",

		path: "v2_examples_getRealEstateV2",

		message:
`query { 
  getRealEstateV2( 
    filter: { 
       CollateralObjectType: "ANHB"
    }
  ) {
       ObjectId
       CollateralObjectType{
         Key
         ShortText
         LongText
      }
    } 
}`
	},
	{
		title: "Query - listRealEstateV2 [since v4.55]",

		label: "listRealEstateV2",

		path: "v2_examples_listRealEstateV2",

		message:
`query { 
  listRealEstateV2( 
    filter: { 
       CollateralObjectType: "ANHB"
    }, fetchSize: 10, startIndex: 0
  ) {
    results{
      ObjectId
    }
    totalNumberOfResults
    moreResultsAvailable
  } 
}`
	}],

	mutations: [{
        title: "Mutation - createAccountV2 [since v4.55]",

		label: "createAccountV2",

		path: "v2_examples_createAccountV2",

		message:
`mutation { 
  createAccountV2( 
    create: { 
      Customer: {
       Customernumber:"API0000168"
     }
      createBKDetail:{
        NumberNegDisposaldo: 123
        PercentageShare: 50
        Recoverable: 10
        Currency: "EUR"
     }
      Account_type: "KKBereich"
      Accountnumber:"1084977236"
      Responsible: "ABIT"
      Currency: "EUR"
      DistributionKey: "10"
      SLLPKey: "00" 
      Credit_type: "22100"
      IBAN: "AT611904300234573202"
    }
  ) { 
      Account_type 
      Accountnumber 
      ObjectId 
    } 
 }`
},
{
        title: "Mutation - updateAccountV2 [since v4.55]",

		label: "updateAccountV2",

		path: "v2_examples_updateAccountV2",

		message:
`mutation { 
  updateAccountV2( 
    update: { 
      Account_type: "KKBereich"
      Accountnumber:"1234567770"
      where:{
       Accountnumber:"1234567890"
      }
    }
  ) { 
      Account_type 
      Accountnumber 
      ObjectId 
    } 
 }`
},
{
        title: "Mutation - createOrUpdateAccountV2 [since v4.55]",

        label: "createOrUpdateAccountV2",

		path: "v2_examples_createOrUpdateAccountV2",

		message:
`mutation { 
  createOrUpdateAccountV2( 
    createOrUpdate: { 
     Customer: {
       Customernumber:"API0000168"
     }
     createOrUpdateBKDetail:{
        NumberNegDisposaldo: 444
        PercentageShare: 55
        Recoverable: 10
        Currency: "EUR"
        where: {
         NumberNegDisposaldo: 333
       }
     }
      Account_type: "KKBereich"
      Accountnumber:"2585555555"
      Responsible: "ABIT"
      Currency: "EUR"
      DistributionKey: "10"
      SLLPKey: "00" 
      Credit_type: "22100"
      where:{
       Accountnumber:"5585555555"
      }
    }
  ) { 
      Account_type 
      Accountnumber 
      ObjectId 
    } 
 }`
},
{

        title: "Mutation - createAddressV2 [since v4.55]",

		label: "createAddressV2",

		path: "v2_examples_createAddressV2",

		message:
`mutation {
 createAddressV2(
  create:{
    Partner:{
     Partner_number: "API0000168"
    }
    PostalForeignCountries: "01"
    Addresstype: "01"
    HouseNoNumFrom: "01"
    location: "Berlin"
    address_status: "01"
    AddAddresstype: "01"
    origin: "01"
    Street: "Street1"
    FederalState: "NRW"
    PostalDomestic: "01"
    country: "004"
    AddressNo: "0001"
    postcode: "10115"
   }
) {
    Addresstype{
      Key
      ShortText
      LongText
    }
    AddAddresstype{
      Key
      ShortText
      LongText
    }
    AddressNo
    ObjectId
  }
}`

}, 
{
        title: "Mutation - updateAddressV2 [since v4.55]",

		label: "updateAddressV2",

		path: "v2_examples_updateAddressV2",

		message:
`mutation { 
  updateAddressV2( 
    update: { 
      Addresstype: "03" 
      AddAddresstype: "02" 
      AddressNo: "170" 
      where: { 
        ObjectId: "-2436f555:1816b305843:-7fa3"
        FederalState: "NRW" 
       }
 }
  ) { 
     Addresstype{
       Key
       ShortText
       LongText
     }
     AddAddresstype{
       Key
       ShortText
       LongText
      }
      AddressNo 
      ObjectId 
    } 
 }`

}, 
{
        title: "Mutation - createOrUpdateAddressV2 [since v4.55]",

		label: "createOrUpdateAddressV2",

		path: "v2_examples_createOrUpdateAddressV2",

		message:
`mutation { 
  createOrUpdateAddressV2( 
    createOrUpdate: { 
    Partner:{
     Partner_number: "API0000168"
    }
    PostalForeignCountries: "01"
    Addresstype: "01"
    HouseNoNumFrom: "01"
    location: "Berlin"
    address_status: "01"
    AddAddresstype: "01"
    origin: "01"
    Street: "Street65"
    FederalState: "NRW"
    PostalDomestic: "01"
    country: "004"
    AddressNo: "0001"
    postcode: "10115"
    where: { 
     ZIPCode_Town: "10318 Lichtenberg"
    }
 }
  ) { 
      Addresstype{
        Key
        ShortText
        LongText
      }
      AddAddresstype{
        Key
        ShortText
        LongText
      }
      AddressNo 
      ObjectId 
     } 
 }`

},
{
        title: "Mutation - createClaimV2 [since v4.55]",

		label: "createClaimV2",

		path: "v2_examples_createClaimV2",

		message:
`mutation { 
  createClaimV2( 
    create: { 
     ClaimNumber: "76543211"
     Responsible: "ABIT"
     Record_keeping: "1"
     Credit_type: "22100"
     ReceivableType: "K"
     Currency: "EUR"
     Customer: {
      Customernumber:"API0000168"
     }
     createSegment: {
       state_of_procedure: "1"
       CommitmentScope: "2"
       ClaimReason: "04"
       Currency: "EUR"
       Segmentnumber: 0
       ValueDateOriginalPrincipal: "2019-10-17"
     }
    }
  ) { 
     ObjectId
     ClaimNumber
    } 
}`

},
{
        title: "Mutation - updateClaimV2 [since v4.55]",

        label: "updateClaimV2",

		path: "v2_examples_updateClaimV2",

		message:
`mutation { 
  updateClaimV2( 
    update: { 
     ClaimNumber: "13412"
     Customer: {
      Name1:"George"
      Customernumber:"API00001687"
      ExternID:"3000"
     }
      where:{
       ObjectId: "1234"
      }
    }
  ) { 
     ObjectId
     ClaimNumber
    } 
}`

  },
  {
        title: "Mutation - createCollateralV2 [since v4.55]",

        label: "createCollateralV2",

		path: "v2_examples_createCollateralV2",

		message:
`mutation {
    createCollateralV2(
     create: { 
     Currency: "EUR"
     CollateralType: "60"
     createChargepos:{
      PositionLevel: 2
      ActPosition: 5
      CustomerUnit:{
       CommitmentNumber: "834834"
      }
     }
     createCollateralObject:{
      CollateralObjectType: "GFND"
      createSiObjValuation:{
        Reporter: "Test"
        Currency: "EUR"
      }
     }
     createPartial_right:{
      Currency: "EUR"
      CurrentPosition: 1
     }
     
    }
    ) {
       ObjectId
       CollateralType{
         Key
         ShortText
         LongText
       }
       CollateralObject {
         ObjectId
        }
      }
}`

  },
  {
        title: "Mutation - createOrUpdateCollateralV2 [since v4.55]",

        label: "createOrUpdateCollateralV2",

		path: "v2_examples_createOrUpdateCollateralV2",

		message:
`mutation{
 createOrUpdateCollateralV2(
   createOrUpdate:{
     CollateralType:"60"
     Currency: "BGN"
     createOrUpdateCollateralObject:{
       Type: "0"
       CollateralObjectType: "WPDP"
       where:{
        CollateralObjectType: "GFND"
       } 
     }  
     where:{
      SiNumber: 27
     }
    }
    ) {
       ObjectId
       Currency
       CollateralObject{
         ObjectId
       }
      }
}`

  },
  {
        title: "Mutation - deleteCollateralV2 [since v4.55]",

        label: "deleteCollateralV2",

		path: "v2_examples_deleteCollateralV2",

		message:
`mutation{
   deleteCollateralV2(
      delete:{
        Currency: "EUR"
      }
    ) {
       ObjectId
       Currency
      }
}`

  },
  {
        title: "Mutation - updateCollateralV2 [since v4.55]",

        label: "updateCollateralV2",

		path: "v2_examples_updateCollateralV2",

		message:
`mutation{
  updateCollateralV2(
   update:{
     CollateralType:"60"
     Currency: "EUR"
     updateCollateralObject:{
       CollateralObjectType: "WPDP"
       createSiObjValuation:{
         Reporter: "Test"
         Currency: "EUR"
       }
       where:{
        CollateralObjectType: "GFND"
       }
      }  
      where: {
       SiNumber: 27
      }
     }
    ) {
       ObjectId
       Currency
       CollateralObject{
         ObjectId
       }
      }
}`

  },
  {
        title: "Mutation - updateCollateralObjectV2 [since v4.55]",

        label: "updateCollateralObjectV2",

		path: "v2_examples_updateCollateralObjectV2",

		message:
`mutation{
    updateCollateralObjectV2(
       update:{
         LastValue: 10
         NameInstitute: "testInstitute"
         where:{
           ObjectId: "d16b5bd8-5e8b-4c77-adceq3"
         }
       }
    ) {
        ObjectId
        LastValue
        NameInstitute     
       }
      
}`

  },
  {
        title: "Mutation - createI9BBCashflowsV2 [since v4.60]",

        label: "createI9BBCashflowsV2",

		path: "v2_examples_createI9BBCashflowsV2",

		message:
`mutation {
createI9BBCashflowsV2(
  create: {  
      InitialPlan: true
      Description: "Test1"
      Collateral_values: false
      OwnShare: 100
      LfdNr: 1
      POCIInitial: true
      GueltigAb: "2019-10-17"
      RelevantScenario: true
      Reason: ""
      capture_date: "2036-10-17"
      Account: {
        IDExternal: "007"
      }
      createIASKtoTilgPlanElem: [{
        DelIndicater: false
        Amount: 5.667
        Value_date: "2046-10-17"
        RateType: "1102"
        Currency: "EUR"
        Reason: "1"
        AmountOld: 5.36
      },
      {
        DelIndicater: false
        Amount: 6.77
        Value_date: "2046-11-17"
        RateType: "1102"
        Currency: "EUR"
        Reason: "1"
        AmountOld: 5.36
      },
      {
        DelIndicater: false
        Amount: 7.75
        Value_date: "2046-12-17"
        RateType: "1102"
        Currency: "EUR"
        Reason: "1"
        AmountOld: 5.36
      }]
      Currency: "EUR"
      Probability: 100
      Status: "1"
      Fungible: true
  }) {
        ObjectId
    }
  
}`

},
  {
        title: "Mutation - createCollateralObjectValuationV2 [since v4.55]",

        label: "createCollateralObjectValuationV2",

		path: "v2_examples_createCollateralObjectValuationV2",

		message:
`mutation {
  createCollateralObjectValuationV2(
    create: {
     Valuation: 9987
     ValuationType: "10"
     Currency: "EUR"
     CollateralObject: {
       IDExtern: "777"
     }
    }
    ) {
        Valuation
        ValuationType{
           Key
           ShortText
           LongText
        }
      }
}`

  },
  {
        title: "Mutation - createCollateralObjectValuationREV2 [since v4.55]",

        label: "createCollateralObjectValuationREV2",

		path: "v2_examples_createCollateralObjectValuationREV2",

		message:
`mutation {
   createCollateralObjectValuationREV2(
     create: {
       ValuationType: "10"
       Currency: "EUR"
       BG5Percent: 5
       RealEstate: {
         IDExtern: "12345678"
       }
      }
    ) {
        Currency
        ValuationType{
           Key
           ShortText
           LongText
        }
        ObjectId
        BG5Percent
       }
}`
 },
{
        title: "Mutation - createCustomerV2 [since v4.55]",

		label: "createCustomerV2",

		path: "v2_examples_createCustomerV2",

		message:
		`mutation { 
  createCustomerV2( 
    create: { 
     PartyInvolvedTyp: "01"
     Customernumber: "2143235235"
     LegalName1: "George"
     Name1: "GeorgeName1"
     Administrator: "ABIT"
     createAddress: {
       PostalForeignCountries: "01"
       Addresstype: "01"
       HouseNoNumFrom: "01"
       location: "Berlin"
       address_status: "01"
    AddAddresstype: "01"
    origin: "01"
    Street: "Street1"
    FederalState: "NRW"
    PostalDomestic: "01"
    country: "004"
    AddressNo: "0001"
    postcode: "10115"
     }
       
    }
  ) {  
      ObjectId 
    } 
 }`
},
{
        title: "Mutation - updateCustomerV2 [since v4.55]",

        label: "updateCustomerV2",

		path: "v2_examples_updateCustomerV2",

		message:
`mutation { 
  updateCustomerV2( 
    update: { 
      LegalName1:"George2"
      AccountNumber:"987654321"
      where:{
       ObjectId: "123"
      }
    }
  ) { 
     ObjectId
     AccountNumber
    } 
}`

},
{
        title: "Mutation - deleteCustomerV2 [since v4.55]",

        label: "deleteCustomerV2",

		path: "v2_examples_deleteCustomerV2",

		message:
`mutation { 
  deleteCustomerV2( 
    delete: { 
       Partner_number: "123"
    }
  ) { 
     ObjectId
     AccountNumber
    } 
}`

},
{
        title: "Mutation - createCustomerUnitV2 [since v4.55]",

		label: "createCustomerUnitV2",

		path: "v2_examples_createCustomerUnitV2",

		message:
`mutation { 
  createCustomerUnitV2( 
    create: { 
     CommitmentNumber: "1233451324"
     MarketSegment: "00000"
     Customer: {
     Name1: "George"
     Customernumber: "213"
}
       
    }
  ) { 
      Sold 
      MarketSegment{
          Key
          ShortText
          LongText
      }
      ObjectId 
    } 
 }`
},
{
        title: "Mutation - updateCustomerUnitV2 [since v4.55]",

        label: "updateCustomerUnitV2",

		path: "v2_examples_updateCustomerUnitV2",

		message:
`mutation { 
  updateCustomerUnitV2( 
    update: { 
      MarketSegment:"Oil"
      where:{
       ObjectId: "123324"
      }
    }
  ) { 
     ObjectId
      MarketSegment{
          Key
          ShortText
          LongText
      }
    } 
}`

},
{
        title: "Mutation - createCustomerUnitAssociationV2 [since v4.55]",

		label: "createCustomerUnitAssociationV2",

		path: "v2_examples_createCustomerUnitAssociationV2",

		message:
`mutation { 
  createCustomerUnitAssociationV2( 
    create: { 
     AssociationType: "EvUabhVerb"
     CompositeNumber: "1234567"
     compoundName: "new_nameTest"
     CustomerUnit: {
      CommitmentNumber: "A000000002"
     }
     createCustomer2Association:{
      LinkType: 0
      Customer:{
       Customernumber: "A000000002"
      }
     }  
    }
  ) { 
      compoundName 
      ObjectId 
    } 
 }`
},
{
        title: "Mutation - updateCustomerUnitAssociationV2 [since v4.55]",

		label: "updateCustomerUnitAssociationV2",

		path: "v2_examples_updateCustomerUnitAssociationV2",

		message:
`mutation { 
  updateCustomerUnitAssociationV2( 
    update: { 
     AssociationType: "EV"
     compoundName: "Peter"
     where:{
      CompositeNumber: "62342131"
     }
    }
  ) { 
      compoundName 
      ObjectId 
    } 
 }`
},
{
        title: "Mutation - deleteCustomerUnitAssociationV2 [since v4.55]",

        label: "deleteCustomerUnitAssociationV2",

		path: "v2_examples_deleteCustomerUnitAssociationV2",

		message:
`mutation { 
  deleteCustomerUnitAssociationV2( 
    delete: { 
       AssociationType: "EV"
    }
  ) { 
     ObjectId
     compoundName
    } 
}`

},
{
        title: "Mutation - createCustomerUnitCustomerV2 [since v4.55]",

		label: "createCustomerUnitCustomerV2",

		path: "v2_examples_createCustomerUnitCustomerV2",

		message:
`mutation {
   createCustomerUnitCustomerV2(
    create: {
     CompoundPosition: "333"
     IsConsidered: true
     Customer: {
      Customernumber:"API0000168"
     }
     Customer1: {
      Customernumber:"9812123"
     }
    }
  ) {
      CompoundPosition
      ObjectId
    }
}`
},
{
        title: "Mutation - deleteCustomerUnitCustomerV2 [since v4.55]",

		label: "deleteCustomerUnitCustomerV2",

		path: "v2_examples_deleteCustomerUnitCustomerV2",

		message:
`mutation { 
  deleteCustomerUnitCustomerV2( 
    delete: { 
       ObjectId: "12334123"
    }
  ) { 
     ObjectId
     CompoundPosition
    } 
}`
},
{
        title: "Mutation - updateCustomerUnitCustomerV2 [since v4.55]",

		label: "updateCustomerUnitCustomerV2",

		path: "v2_examples_updateCustomerUnitCustomerV2",

		message:
`mutation { 
  updateCustomerUnitCustomerV2( 
    update: { 
      CompoundPosition: "666"
      where:{
       CompoundPosition:"555"
      }
    }
  ) { 
      CompoundPosition
      ObjectId 
    } 
 }`
},
{
        title: "Mutation - createLACurrentAccountV2 [since v4.55]",

		label: "createLACurrentAccountV2",

		path: "v2_examples_createLACurrentAccountV2",

		message:
`mutation { 
  createLACurrentAccountV2( 
    create: { 
     AccountnumberDebit: "123432"
     Customer: {
     Customernumber: "12313"
     Name1: "Ivan"
     }
    }
  ) { 
      AccountnumberDebit 
      ObjectId 
    } 
 }`
},
{
        title: "Mutation - createOrUpdateLACurrentAccountV2 [since v4.55]",

        label: "createOrUpdateLACurrentAccountV2",

		path: "v2_examples_createOrUpdateLACurrentAccountV2",

		message:
`mutation { 
  createOrUpdateLACurrentAccountV2( 
    createOrUpdate: {
      HandlingFeePercent: 12
      Customer: {
       Customernumber: "A000000004"
      }
      Accountnumber: "8213141519"
      where:{
       Accountnumber:"1213141519"
      }
    }
  ) { 
      Accountnumber 
      ObjectId 
    } 
 }`
},
{
        title: "Mutation - deleteLACurrentAccountV2 [since v4.55]",

        label: "deleteLACurrentAccountV2",

		path: "v2_examples_deleteLACurrentAccountV2",

		message:
`mutation { 
  deleteLACurrentAccountV2( 
    delete: { 
       ObjectId: "123"
    }
  ) { 
     ObjectId
     Accountnumber
    } 
}`
},
{
        title: "Mutation - updateLACurrentAccountV2 [since v4.55]",

		label: "updateLACurrentAccountV2",

		path: "v2_examples_updateLACurrentAccountV2",

		message:
`mutation { 
  updateLACurrentAccountV2( 
    update: { 
      Accountnumber:"123456"
      where:{
       ObjectId:"123"
      }
    }
  ) { 
      Accountnumber 
      ObjectId 
    } 
 }`
},
{
        title: "Mutation - createLALoanV2 [since v4.55]",

		label: "createLALoanV2",

		path: "v2_examples_createLALoanV2",

		message:
`mutation { 
  createLALoanV2( 
    create: { 
     NumberInterestRates: 123432
     Currency: "BGN"
     Customer: {
      Customernumber: "12313"
      Name1: "Ivan"
      }
    }
  ) { 
      Accountnumber 
      ObjectId 
    } 
 }`
},
{
        title: "Mutation - createOrUpdateLALoanV2 [since v4.55]",

        label: "createOrUpdateLALoanV2",

		path: "v2_examples_createOrUpdateLALoanV2",

		message:
`mutation { 
  createOrUpdateLALoanV2( 
    createOrUpdate: {
      NumberInterestRates: 123432
      Currency: "BGN"
      Customer: {
      Customernumber: "12313"
      Name1: "Ivan"
      }
      where:{
       ObjectId:"123"
      }
    }
  ) { 
      Accountnumber 
      ObjectId 
    } 
 }`
},
{
        title: "Mutation - deleteLALoanV2 [since v4.55]",

        label: "deleteLALoanV2",

		path: "v2_examples_deleteLALoanV2",

		message:
`mutation { 
  deleteLALoanV2( 
    delete: { 
       ObjectId: "123"
    }
  ) { 
     ObjectId
     Accountnumber
    } 
}`
},
{
        title: "Mutation - updateLALoanV2 [since v4.55]",

		label: "updateLALoanV2",

		path: "v2_examples_updateLALoanV2",

		message:
`mutation { 
  updateLALoanV2( 
    update: { 
      Accountnumber:"123456"
      where:{
       ObjectId:"123"
      }
    }
  ) { 
      Accountnumber 
      ObjectId 
    } 
 }`
},
{
        title: "Mutation - createLegalMeasuresV2 [since v4.55]",

		label: "createLegalMeasuresV2",

		path: "v2_examples_createLegalMeasuresV2",

		message:
`mutation { 
  createLegalMeasuresV2( 
    create: { 
     Responsible: "Name1"
     Currency: "BGN"
    }
  ) { 
      IBAN
      Currency 
      ObjectId 
    } 
 }`
},
{
        title: "Mutation - updateLegalMeasuresV2 [since v4.55]",

		label: "updateLegalMeasuresV2",

		path: "v2_examples_updateLegalMeasuresV2",

		message:
`mutation { 
  updateLegalMeasuresV2( 
    update: { 
      Currency:"EUR"
      where:{
       ObjectId:"123"
      }
    }
  ) { 
      Currency 
      ObjectId 
    } 
 }`
},
{
        title: "Mutation - updateLoanConditionsV2 [since v4.55]",

		label: "updateLoanConditionsV2",

		path: "v2_examples_updateLoanConditionsV2",

		message:
`mutation { 
  updateLoanConditionsV2( 
    update: { 
      Accountnumber:"54321"
      where:{
       ObjectId:"123"
      }
    }
  ) { 
      Accountnumber 
      ObjectId 
    } 
 }`
},
{
        title: "Mutation - createWorkflowEventV2 [since v4.55]",

		label: "createWorkflowEventV2",

		path: "v2_examples_createWorkflowEventV2",

		message:
`mutation { 
  createWorkflowEventV2( 
    create: { 
     EventKey: "000"
     Type: "TestType"
    }
  ) { 
      Type
      EventKey{
        Key
        ShortText
        LongText
      }
      ObjectId 
    } 
 }`
 
 
},
{
        title: "Mutation - createMortgageV2 [since v4.55]",

		label: "createMortgageV2",

		path: "v2_examples_createMortgageV2",

		message:
`mutation {
  createMortgageV2( 
    create: { 
     CollateralType: "10"
     RankAt: "1"
     SiNumber: 12357
     createChargepos:{
      PositionLevel: 2
      ActPosition: 5
      CustomerUnit:{
       CommitmentNumber: "834834"
      }
     }
     createObjektFolge:{
      ClearingPos: 1
      RealEstate:{
       Objectnumber: 187
      }
     }
     createRightSection3:{
      Currency: "EUR"
      Type: "0"
     }
    }
  ) { 
     CollateralType{
        Key
        ShortText
        LongText
     }
     RankAt{
        Key
        ShortText
        LongText
     }
     SiNumber
     ObjectId
    }
}`
},
{
        title: "Mutation - createOrUpdateMortgageV2 [since v4.55]",

		label: "createOrUpdateMortgageV2",

		path: "v2_examples_createOrUpdateMortgageV2",

		message:
`mutation {
  createOrUpdateMortgageV2( 
    createOrUpdate: { 
     CollateralType: "10"
     RankAt: "1"
     createOrUpdateChargepos:{
      PositionLevel: 3
      ActPosition: 5
      CustomerUnit:{
       CommitmentNumber: "834834"
      }
      where:{
       PositionLevel: 2
      }
     }
     createOrUpdateObjektFolge:{
      ClearingPos: 1
      RealEstate:{
       Objectnumber: 187
      }
      where:{
       ClearingPos: 2
      }
     }
     createOrUpdateRightSection3:{
      Currency: "EUR"
      Type: "0"
      where:{
       Type: "1"
      }
     }
     where:{
      SiNumber: 9991
     }
    }
  ) { 
     CollateralType{
       Key
       ShortText
       LongText
     }
     ObjectId
     Description
     SiNumber
    } 
}`
 
 
},
{
        title: "Mutation - deleteMortgageV2 [since v4.55]",

		label: "deleteMortgageV2",

		path: "v2_examples_deleteMortgageV2",

		message:
`mutation { 
  deleteMortgageV2( 
    delete: { 
      CollateralType: "10"
    }
  ) { 
     ObjectId
    }
}`
 
 
},
{
	    title: "Mutation - updateMortgageV2 [since v4.55]",

		label: "updateMortgageV2",

		path: "v2_examples_updateMortgageV2",

		message:
`mutation { 
  updateMortgageV2( 
    update: { 
      Description: "Test description"
      where:{
       SiNumber: 486
      }
    }
  ) { 
     ObjectId
     RankAt{
       Key
       ShortText
       LongText
     }
     Description
     CollateralType{
       Key
       ShortText
       LongText
     }
    }
}`
	
},
{
	    title: "Mutation - createPublicGuaranteeV2 [since v4.55]",

		label: "createPublicGuaranteeV2",

		path: "v2_examples_createPublicGuaranteeV2",

		message:
`mutation { 
  createPublicGuaranteeV2( 
   create: { 
      CollateralType: "37"
      IsGuarantee: true
      createCollateralObject: {
       CollateralObjectType: "PRAB"
      }
    }
  ) { 
     ObjectId
     CollateralType{
       Key
       ShortText
       LongText
     }
     IsGuarantee
     CollateralObject {
      CollateralObjectType{
       Key
       ShortText
       LongText
     }
     }
    }
}`
	
},
{
	    title: "Mutation - createOrUpdatePublicGuaranteeV2 [since v4.55]",

		label: "createOrUpdatePublicGuaranteeV2",

		path: "v2_examples_createOrUpdatePublicGuaranteeV2",

		message:
`mutation {
 createOrUpdatePublicGuaranteeV2(
  createOrUpdate: {
    IsGuarantee: false
    CollateralType: "37"
    createOrUpdateCollateralObject:{
     Currency: "EUR"
     CollateralObjectType: "PRAB"
     where: {
     CollateralObjectType: "PRAB"
     }
    }
    where: {
     CollateralType: "33"
    }
   }
  ) { 
     ObjectId
     CollateralType{
       Key
       ShortText
       LongText
     }
     IsGuarantee
    }
}`
	
},
{
	    title: "Mutation - deletePublicGuaranteeV2 [since v4.55]",

		label: "deletePublicGuaranteeV2",

		path: "v2_examples_deletePublicGuaranteeV2",

		message:
`mutation { 
  deletePublicGuaranteeV2( 
    delete: { 
      CollateralType: "10"
      Currency: "EUR"
    }
  ) { 
     ObjectId
     CollateralType{
       Key
       ShortText
       LongText
     }
     Currency
    }
}`
	
},
{
	    title: "Mutation - updatePublicGuaranteeV2 [since v4.55]",

		label: "updatePublicGuaranteeV2",

		path: "v2_examples_updatePublicGuaranteeV2",

		message:
`mutation {
  updatePublicGuaranteeV2(
    update: {
      Currency: "EUR"
      where:{
       CollateralType: "10"
     }
    }
  ) { 
     ObjectId
     CollateralType{
       Key
       ShortText
       LongText
     }
     Currency
    }
}`
	
},
{
	    title: "Mutation - createRealEstateV2 [since v4.55]",

		label: "createRealEstateV2",

		path: "v2_examples_createRealEstateV2",

		message:
`mutation {
 createRealEstateV2(
  create: {
    CollateralObjectType: "BSPV"
    House_number: "100"
    Currency: "EUR"
    createLand_Registry_Sheet:{
     SheetNumber: "10"
     TapeNumber: "8761"
     createRightSection3:{
      Currency: "EUR"
      Type: "0"
     }
     createInventory :{
      Corridor: "56"
      Description: "Test inventory"
      Currency: "EUR"
      SeqNo: "87654321"
     }
    }
    createSection1Owner:{
     SeqNo: "56893"
     OwnerShipShare: 12345678
     Bemerkung: "1"
     Customer: {
      Customernumber: "A000000004"
     }
    }
   }
  ) { 
     ObjectId
     CollateralObjectType{
       Key
       ShortText
       LongText
     }
     Land_Registry_Sheet {
      SheetNumber
      ObjectId
      TapeNumber
     }
    }
}`
},
{
	    title: "Mutation - createOrUpdateRealEstateV2 [since v4.55]",

		label: "createOrUpdateRealEstateV2",

		path: "v2_examples_createOrUpdateRealEstateV2",

		message:
`mutation {
 createOrUpdateRealEstateV2(
  createOrUpdate: {
   CollateralObjectType: "EZFO"
   FloorNumber: "3"
   Currency: "EUR"
   createOrUpdateLand_Registry_Sheet: {
     SheetNumber: "15"
     TapeNumber: "8761"
     where: {
      SheetNumber: "10"
     }
    }
   where:{
    CollateralObjectType: "ANHG"
   }
  }
  ) { 
     ObjectId
     CollateralObjectType{
       Key
       ShortText
       LongText
     }
     Land_Registry_Sheet {
      SheetNumber
      ObjectId
      TapeNumber
     }
    }
}
`
},
{
	    title: "Mutation - deleteRealEstateV2 [since v4.55]",

		label: "deleteRealEstateV2",

		path: "v2_examples_deleteRealEstateV2",

		message:
`mutation {
  deleteRealEstateV2(
    delete: {
      FloorNumber: "3"
    }
  ) { 
     ObjectId
     CollateralObjectType{
       Key
       ShortText
       LongText
     }
     FloorNumber
    }
}`
},
{
	    title: "Mutation - updateRealEstateV2 [since v4.55]",

		label: "updateRealEstateV2",

		path: "v2_examples_updateRealEstateV2",

		message:
`mutation {
  updateRealEstateV2(
    update: {
      TotalSM: 55
      where:{
        FloorNumber: "11"
      }
    }
  ) { 
     ObjectId
     FloorNumber
     TotalSM
    }
}`
},
{
      title: "Mutation - createResubmissionV2 [since v4.91]",

    label: "createResubmissionV2",

    path: "v2_examples_createResubmissionV2",

    message:
`mutation {
  createResubmissionV2(
    create: {
      Designation: "Wiedervorlage with Links"
      ResubmissionText: "Please check if the links are working."
      ResubmissionDate: "2024-08-21"
      Receiver: "ABIT"
      Link1: "www.google.com"
      LinkText1: "GOOGLE"
      Link2: "www.microsoft.com"
      Account: {
        Accountnumber: "1234567890"
      }
    }
  ) { 
     ObjectId
    }
}`
}], jobs:[], files:[], workflows:[], blanks:[], events:[]
}
