import React, { Component, createRef } from 'react';

import Button            from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow              from '@material-ui/core/Grow';
import Paper             from '@material-ui/core/Paper';
import Popper            from '@material-ui/core/Popper';
import MenuItem          from '@material-ui/core/MenuItem';
import MenuList          from '@material-ui/core/MenuList';
import { withStyles }    from '@material-ui/core/styles';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

const styles = theme => ({
    root: {
        float: 'right',
    },
    menuButton: {
        fontFamily: 'Poppins-Regular',
        fontSize: '0.9rem',
        fontWeight: '500',
        lineHeight: '1.75',
        verticalAlign: 'bottom',
        //marginTop: '30px',
        padding: '1px 8px 1px 8px',
        color: '#FFFFFF',
    },
    menuButtonSelected: {
        fontFamily: 'Poppins-Regular',
        fontSize: '0.9rem',
        fontWeight: '500',
        lineHeight: '1.75',
        verticalAlign: 'bottom',
        //marginTop: '30px',
        padding: '1px 8px 1px 8px',
        color: '#4c60f2',
    },
    menuPopper: {
        zIndex: '100',
    },
    menuPaper: {
        fontFamily: 'Poppins-Regular',
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.5',
        backgroundColor: '#0D1D41',
    },
    link: {
        verticalAlign: 'bottom',
        cursor: 'pointer',
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#4c60f2',
        },
        textDecoration: "none",
    },
    linkSelected : {
        verticalAlign: 'bottom',
        cursor: 'pointer',
        color: '#4c60f2',
        '&:hover': {
            backgroundColor: '#4c60f2',
            color: '#FFFFFF',
        }
    }
});

class DropDownMenu extends Component {
  state = {
    selectedPath: null,
    open: false,
  };

  anchorRef = createRef();

  handleListItemClick = (event, path) => {
    this.setState({ selectedPath: path });
    this.handleClose(event);
    this.props.onClick(event, path);
  };

  static getFirstWord = (text) => {
    let trimedText = text.trim();
    if (trimedText.length === 0) {
      return "";
    }
    if (!trimedText.includes(" ")) {
      return trimedText;
    }
    return trimedText.substring(0, trimedText.indexOf(" "));
  };

  static boldPartialText = (text) => {
    const boldOnlyText = ["originate", "score", "collect", "recovery"];
    const startBoldAfter = "APIv1";
    const superscriptText = "(OC4)";

    if (!text.includes(startBoldAfter)) {
      return text;
    }
    const normalText = text.substring(
      0,
      text.indexOf(startBoldAfter) + startBoldAfter.length
    );
    let remainingText = text.substring(
      text.indexOf(startBoldAfter) + startBoldAfter.length
    );

    const words = remainingText.split(/(\s+)/);

    const spanObjects = words.map((word, index) => {
      const trimmedWord = word.trim();

      if (boldOnlyText.includes(trimmedWord)) {
        if (word.includes(superscriptText)) {
          const [beforeSup, afterSup] = word.split(superscriptText);
          return (
            <React.Fragment key={index}>
              <strong>{beforeSup}</strong>
              <sup>{superscriptText}</sup>
              {afterSup}
            </React.Fragment>
          );
        } else {
          return <strong key={index}>{word}</strong>;
        }
      }

      if (word.includes(superscriptText)) {
        const [beforeSup, afterSup] = word.split(superscriptText);
        return (
          <React.Fragment key={index}>
            {beforeSup}
            <sup>{superscriptText}</sup>
            {afterSup}
          </React.Fragment>
        );
      }

      return <span key={index}>{word}</span>;
    });

    return (
      <span>
        {normalText}
        {spanObjects}
      </span>
    );
  };

  handleClose = (event) => {
    if (
      !this.anchorRef.current ||
      !this.anchorRef.current.contains(event.target)
    ) {
      this.setState({ open: false });
    }
  };

  toggle = (event) => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      this.setState({ loanOpen: false });
      this.setState({ debtOpen: false });
    }
  };

  render() {
    const { classes, selected, title, items, loginUrl, logoutUrl } = this.props;
    const { selectedPath, open } = this.state;

    return (
      <div className={classes.root}>
        <Button
          className={selected ? classes.menuButtonSelected : classes.menuButton}
          ref={this.anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={this.toggle}
        >
          {title ? (
            <div style={{ display: "inherit" }}>
              {title}
              <ArrowDropDownIcon />
            </div>
          ) : (
            <AccountCircleIcon />
          )}
        </Button>

        <Popper
          className={classes.menuPopper}
          open={open}
          anchorEl={this.anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper className={classes.menuPaper}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList
                    id="menu-list-grow"
                    onKeyDown={this.handleListKeyDown}
                  >
                    {loginUrl ? (
                      <MenuItem key={-2} className={classes.link}>
                        <a className={classes.link} href={loginUrl}>
                          Login
                        </a>
                      </MenuItem>
                    ) : (
                      ""
                    )}
                    {logoutUrl ? (
                      <MenuItem key={-1} className={classes.link}>
                        <a className={classes.link} href={logoutUrl}>
                          Logout
                        </a>
                      </MenuItem>
                    ) : (
                      ""
                    )}
                    {items.map((item, idx) => (
                      <MenuItem
                        key={idx}
                        className={
                          selected && item.path === selectedPath
                            ? classes.linkSelected
                            : classes.link
                        }
                        onClick={(event) =>
                          this.handleListItemClick(event, item.path)
                        }
                      >
                        {DropDownMenu.boldPartialText(item.text)}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

DropDownMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(DropDownMenu));
