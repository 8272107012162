import { createStore, applyMiddleware, compose } from "redux";

import createSagaMiddleware from "redux-saga";

import { authenticationMiddleware } from "./authentication";

import reducer from "./reducers";
import saga    from "./sagas";

const sagaMiddleware = createSagaMiddleware();

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
	reducer,
	storeEnhancers(applyMiddleware(authenticationMiddleware, sagaMiddleware))
);

sagaMiddleware.run(saga);

export default store;
