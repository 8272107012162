import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button         from '@material-ui/core/Button';
import InputBase      from "@material-ui/core/InputBase";
import SendIcon       from '@material-ui/icons/Send';
import AutorenewIcon  from '@material-ui/icons/Autorenew';

import Sidebar   from './Sidebar';
import Documents from './Documents';

import styles    from '../styles';

class Examples extends Component {
    state = {
        header        : null,
        queries       : [],
        mutations     : [],
        jobs          : [],
        files         : [],
        workflows     : [],
        blanks        : [],
        events        : []
    };

    constructor(props) {
        super(props);
        this.state = { header: props.header, queries: props.queries, mutations: props.mutations, jobs: props.jobs, files: props.files, workflows: props.workflows, blanks: props.blanks, events: props.events };
    }

    handleResetExampleData = (event) => {
        this.setState({ queries: this.props.queries, mutations: this.props.mutations, jobs: this.props.jobs, files: this.props.files, workflows: this.props.workflows, blanks: this.props.blanks, events: this.props.events });
    }

    handleQueryMessageChange = (event, idx) => {
        var queries = idx > 0 ? this.state.queries.slice(0, idx) : [];

        queries.push(Object.assign({}, this.state.queries[idx], {message: event.target.value}));

        this.setState({
            queries: queries.concat(idx < this.state.queries.length - 1 ? 
                this.state.queries.slice(idx + 1, this.state.queries.length) : 
                [])});
    }

    handleMutationMessageChange = (event, idx) => {
        var mutations = idx > 0 ? this.state.mutations.slice(0, idx) : [];

        mutations.push(Object.assign({}, this.state.mutations[idx], {message: event.target.value}));

        this.setState({
            mutations: mutations.concat(idx < this.state.mutations.length - 1 ? 
                this.state.mutations.slice(idx + 1, this.state.mutations.length) : 
                [])});
    }
    
    handleJobsMessageChange = (event, idx) => {
        var jobs = idx > 0 ? this.state.jobs.slice(0, idx) : [];

        jobs.push(Object.assign({}, this.state.jobs[idx], {message: event.target.value}));

        this.setState({
            jobs: jobs.concat(idx < this.state.jobs.length - 1 ? 
                this.state.jobs.slice(idx + 1, this.state.jobs.length) : 
                [])});
    }
    
	handleFilesMessageChange = (event, idx) => {
		var files = idx > 0 ? this.state.files.slice(0, idx) : [];

		files.push(Object.assign({}, this.state.files[idx], { message: event.target.value }));

		this.setState({
			files: files.concat(idx < this.state.files.length - 1 ?
				this.state.files.slice(idx + 1, this.state.files.length) :
				[])
		});
	}
	
	handleWorkflowsMessageChange = (event, idx) => {
		var workflows = idx > 0 ? this.state.workflows.slice(0, idx) : [];

		workflows.push(Object.assign({}, this.state.workflows[idx], { message: event.target.value }));

		this.setState({
			workflows: workflows.concat(idx < this.state.workflows.length - 1 ?
				this.state.workflows.slice(idx + 1, this.state.workflows.length) :
				[])
		});
	}
	
    handleBlanksMessageChange = (event, idx) => {
		var blanks = idx > 0 ? this.state.blanks.slice(0, idx) : [];

		blanks.push(Object.assign({}, this.state.blanks[idx], { message: event.target.value }));

		this.setState({
			blanks: blanks.concat(idx < this.state.blanks.length - 1 ?
				this.state.blanks.slice(idx + 1, this.state.blanks.length) :
				[])
		});
	}

    handleEventsMessageChange = (event, idx) => {
		var events = idx > 0 ? this.state.events.slice(0, idx) : [];

		events.push(Object.assign({}, this.state.events[idx], { message: event.target.value }));

		this.setState({
			events: events.concat(idx < this.state.events.length - 1 ?
				this.state.events.slice(idx + 1, this.state.events.length) :
				[])
		});
	}

    render() {
        const { classes } = this.props;
        var sidebarModel = [];
        if(this.state.queries && this.state.queries.length > 0) {
            sidebarModel.push({ label : "Query", children : this.state.queries});
        }
        if(this.state.mutations && this.state.mutations.length > 0) {
            sidebarModel.push({ label : "Mutation", children : this.state.mutations});
        }
        if(this.state.jobs && this.state.jobs.length > 0) {
            sidebarModel.push({ label : "Jobs", children : this.state.jobs});
        }
        if(this.state.files && this.state.files.length > 0) {
            sidebarModel.push({ label : "Files", children : this.state.files});
        }
        if(this.state.workflows && this.state.workflows.length > 0) {
            sidebarModel.push({ label : "Workflows", children : this.state.workflows});
        }
        if(this.state.events && this.state.events.length > 0) {
            sidebarModel.push({ label : "Events", children : this.state.events});
        }
        
        if(this.state.blanks && this.state.blanks.length > 0) {
            sidebarModel.push({ label : "Blank", children :[{
	            title: "Test requests", 
                label: "Test requests", 
                path:  "test_requests",
                message: ""
         }]});
        }
        
        var ExampleHeader = this.state.header;
        var isJobRender = this.state.jobs && this.state.jobs.length > 0;
        var isWorkflowRender = this.state.workflows && this.state.workflows.length > 0;
        var isEventsRender = this.state.events && this.state.events.length > 0;
        if(!isJobRender && !isWorkflowRender && !isEventsRender){
        sidebarModel.push({
            label: "Documents", 
            children: [{
                title: "Documents", 
                label: "Documents", 
                path: "Documents",
                message: ""}]});
        }
        return (
            <div className={classes.root}>
                <div className={classes.sidebarExample}>
                    <Sidebar title="Examples" samePageScroll={true} model={sidebarModel}/>
                    {this.props.readOnly ? "" : (
                        <Button className={classes.buttonResetExample} 
                                id = "resetButton"
                                variant="contained" 
                                color="primary" 
                                onClick={event => this.handleResetExampleData(event)}>
                            Reset Example Data &nbsp; <AutorenewIcon/>
                        </Button>
                    )}
                </div>
                <div className={classes.workarea} id ="content">
                    {ExampleHeader ? (
                        <div className={classes.paper}>
                            {React.createElement(ExampleHeader, { onChange: msg => this.handleMutationMessageChange({target: {value: msg}}, 0)})}
                        </div>
                    ) : ""}
                    <div className={classes.paper}>
                        {this.state.queries.map((query, idx) => {
                            return (
                                <div className={classes.example} id={query.path} key={query.path}>
                                    <div className={classes.heading}>
                                        <span className={classes.exampleTitle}>{query.title}</span>
                                        {this.props.readOnly ? "" : (
                                            <Button className={classes.runExample} 
                                                    variant="contained" 
                                                    color="secondary" 
                                                    disabled={!this.props.auth || (this.props.auth.expires_at && this.props.auth.expires_at < Date.now())}
                                                    onClick={event => 
                                                        this.props.exampleSend(query.message, query.path)}>
                                                Send &nbsp; <SendIcon/>
                                            </Button>
                                        )}
                                    </div>
                                    {this.props.readOnly ? (
                                        <div className={classes.containerCodeResult}>
                                            <div    className={classes.code} 
                                                    dangerouslySetInnerHTML={{__html: query.message}} />
                                        </div>
                                    ) : (
                                        <div className={classes.containerCodeResult}>
                                            <InputBase  id="multiline-static"
                                                        fullWidth
                                                        multiline
                                                        maxRows="32"
                                                        margin="none"
                                                        className={classes.code}
                                                        value={query.message}
                                                        onChange={event => this.handleQueryMessageChange(event, idx)}/>
                                            <div className={classes.result} 
                                                 dangerouslySetInnerHTML={{__html: 
                                                    this.props.data && 
                                                    this.props.data[query.path] ? 
                                                        this.props.data[query.path].data : 
                                                        ""}} />
                                        </div>
                                    )}
                                </div>
                            );
                        })}

                        {this.state.mutations.map((mutation, idx) => {
                            return (
                                <div className={classes.example} id={mutation.path} key={mutation.path}>
                                    <div className={classes.heading}>
                                        <span className={classes.exampleTitle}>{mutation.title}</span>
                                        {this.props.readOnly ? "" : (
                                            <Button className={classes.runExample} 
                                                    variant="contained" 
                                                    color="secondary" 
                                                    disabled={!this.props.auth || (this.props.auth.expires_at && this.props.auth.expires_at < Date.now())}
                                                    onClick={event => 
                                                        this.props.exampleSend(mutation.message, mutation.path)}>
                                                Send &nbsp; <SendIcon/>
                                            </Button>
                                        )}
                                    </div>
                                    {this.props.readOnly ? (
                                        <div className={classes.containerCodeResult}>
                                            <div className={classes.code} 
                                                 dangerouslySetInnerHTML={{__html: mutation.message}} />
                                        </div>
                                    ) : (
                                        <div className={classes.containerCodeResult}>
                                            <InputBase  id="multiline-static"
                                                        fullWidth
                                                        multiline
                                                        rowsMax="32"
                                                        margin="none"
                                                        className={classes.code}
                                                        value={mutation.message}
                                                        onChange={event => this.handleMutationMessageChange(event, idx)}/>
                                            <div className={classes.result} 
                                                 dangerouslySetInnerHTML={{__html: 
                                                    this.props.data && 
                                                    this.props.data[mutation.path] ? 
                                                        this.props.data[mutation.path].data : 
                                                        ""}} />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                        
                        {this.state.jobs.map((job, idx) => {
                            return (
                                <div className={classes.example} id={job.path} key={job.path}>
                                    <div className={classes.heading}>
                                        <span className={classes.exampleTitle}>{job.title}</span>
                                        {this.props.readOnly ? "" : (
                                            <Button className={classes.runExample} 
                                                    variant="contained" 
                                                    color="secondary" 
                                                    disabled={!this.props.auth || (this.props.auth.expires_at && this.props.auth.expires_at < Date.now())}
                                                    onClick={event => 
                                                        this.props.exampleSend(job.message, job.path)}>
                                                Send &nbsp; <SendIcon/>
                                            </Button>
                                        )}
                                    </div>
                                    {this.props.readOnly ? (
                                        <div className={classes.containerCodeResult}>
                                            <div    className={classes.code} 
                                                    dangerouslySetInnerHTML={{__html: job.message}} />
                                        </div>
                                    ) : (
                                        <div className={classes.containerCodeResult}>
                                            <InputBase  id="multiline-static"
                                                        fullWidth
                                                        multiline
                                                        maxRows="32"
                                                        margin="none"
                                                        className={classes.code}
                                                        value={job.message}
                                                        onChange={event => this.handleJobsMessageChange(event, idx)}/>
                                            <div className={classes.result} 
                                                 dangerouslySetInnerHTML={{__html: 
                                                    this.props.data && 
                                                    this.props.data[job.path] ? 
                                                        this.props.data[job.path].data : 
                                                        ""}} />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                        
                        {this.state.files.map((file, idx) => {
                            return (
                                <div className={classes.example} id={file.path} key={file.path}>
                                    <div className={classes.heading}>
                                        <span className={classes.exampleTitle}>{file.title}</span>
                                        {this.props.readOnly ? "" : (
                                            <Button className={classes.runExample} 
                                                    variant="contained" 
                                                    color="secondary" 
                                                    disabled={!this.props.auth || (this.props.auth.expires_at && this.props.auth.expires_at < Date.now())}
                                                    onClick={event => 
                                                        this.props.exampleSend(file.message, file.path)}>
                                                Send &nbsp; <SendIcon/>
                                            </Button>
                                        )}
                                    </div>
                                    {this.props.readOnly ? (
                                        <div className={classes.containerCodeResult}>
                                            <div    className={classes.code} 
                                                    dangerouslySetInnerHTML={{__html: file.message}} />
                                        </div>
                                    ) : (
                                        <div className={classes.containerCodeResult}>
                                            <InputBase  id="multiline-static"
                                                        fullWidth
                                                        multiline
                                                        maxRows="32"
                                                        margin="none"
                                                        className={classes.code}
                                                        value={file.message}
                                                        onChange={event => this.handleFilesMessageChange(event, idx)}/>
                                            <div className={classes.result} 
                                                 dangerouslySetInnerHTML={{__html: 
                                                    this.props.data && 
                                                    this.props.data[file.path] ? 
                                                        this.props.data[file.path].data : 
                                                        ""}} />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                        
                        {this.state.workflows.map((workflow, idx) => {
                            return (
                                <div className={classes.example} id={workflow.path} key={workflow.path}>
                                    <div className={classes.heading}>
                                        <span className={classes.exampleTitle}>{workflow.title}</span>
                                        {this.props.readOnly ? "" : (
                                            <Button className={classes.runExample} 
                                                    variant="contained" 
                                                    color="secondary" 
                                                    disabled={!this.props.auth || (this.props.auth.expires_at && this.props.auth.expires_at < Date.now())}
                                                    onClick={event => 
                                                        this.props.exampleSend(workflow.message, workflow.path)}>
                                                Send &nbsp; <SendIcon/>
                                            </Button>
                                        )}
                                    </div>
                                    {this.props.readOnly ? (
                                        <div className={classes.containerCodeResult}>
                                            <div    className={classes.code} 
                                                    dangerouslySetInnerHTML={{__html: workflow.message}} />
                                        </div>
                                    ) : (
                                        <div className={classes.containerCodeResult}>
                                            <InputBase  id="multiline-static"
                                                        fullWidth
                                                        multiline
                                                        maxRows="32"
                                                        margin="none"
                                                        className={classes.code}
                                                        value={workflow.message}
                                                        onChange={event => this.handleWorkflowsMessageChange(event, idx)}/>
                                            <div className={classes.result} 
                                                 dangerouslySetInnerHTML={{__html: 
                                                    this.props.data && 
                                                    this.props.data[workflow.path] ? 
                                                        this.props.data[workflow.path].data : 
                                                        ""}} />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                        
                        {this.state.blanks.map((blank, idx) => {
							return (
								<div className={classes.example} id={blank.path} key={blank.path}>
									<div className={classes.heading}>
										<span className={classes.exampleTitle}>{blank.title}</span>
										{this.props.readOnly ? "" : (
											<Button className={classes.runExample}
												variant="contained"
												color="secondary"
												disabled={!this.props.auth || (this.props.auth.expires_at && this.props.auth.expires_at < Date.now())}
												onClick={event =>
													this.props.exampleSend(blank.message, blank.path)}>
												Send &nbsp; <SendIcon />
											</Button>
										)}
									</div>
									{this.props.readOnly ? (
										<div className={classes.containerCodeResult}>
											<div className={classes.code}
												dangerouslySetInnerHTML={{ __html: blank.message }} />
										</div>
									) : (
											<div className={classes.containerCodeResult}>
												<InputBase id="multiline-static"
													fullWidth
													multiline
													maxRows="32"
													margin="none"
													className={classes.code}
													value={blank.message}
													onChange={event => this.handleBlanksMessageChange(event, idx)} />
												<div className={classes.result}
													dangerouslySetInnerHTML={{
														__html:
															this.props.data &&
																this.props.data[blank.path] ?
																this.props.data[blank.path].data :
																""
													}} />
											</div>
										)}
								</div>
							);
						})}
						
						  {this.state.events.map((currentEvent, idx) => {
                            const isRecieve = currentEvent.title.includes('recieve') ;
                            const eventTitle = isRecieve ? "Recieve \u00A0 " : "Send \u00A0 ";
                            return (
                                <div className={classes.example} id={currentEvent.path} key={currentEvent.path}>
                                    <div className={classes.heading}>
                                        <span className={classes.exampleTitle}>{currentEvent.title}</span>
                                        {this.props.readOnly ? "" : (
                                            <Button className={classes.runExample} 
                                                    variant="contained" 
                                                    color="secondary" 
                                                    disabled={!this.props.auth || (this.props.auth.expires_at && this.props.auth.expires_at < Date.now())}
                                                    onClick={() => isRecieve ? this.props.recieveEventsRequest() : this.props.exampleSend(currentEvent.message, currentEvent.path)}>
                                               {eventTitle} <SendIcon/>
                                            </Button>
                                        )}
                                    </div>

                               {isRecieve ?  
                                 <div className={classes.containerCodeResult}>
                                     <div style={{visibility: "hidden"}} className={classes.code} />
                                        <div className={classes.result} 
                                             dangerouslySetInnerHTML={{__html: 
                                             this.props.data.events ? this.props.data.events: ""}} />
                                 </div> :(
                                           <div className={classes.containerCodeResult}>
                                              <InputBase id="multiline-static"
                                                    fullWidth
                                                    multiline
                                                    maxRows="32"
                                                    margin="none"
                                                    className={classes.code}
                                                    value={currentEvent.message}
                                                    onChange={event => this.handleEventsMessageChange(event, idx)} />
                                             <div className={classes.result}
                                                  dangerouslySetInnerHTML={{__html:
                                                  this.props.data && this.props.data[currentEvent.path] ?
                                                  this.props.data[currentEvent.path].data : ""}} />
                                          </div>						
                                          )}   
                                 </div>
                            );
                        })}
                        
                        {!isJobRender && !isWorkflowRender && !isEventsRender ?
                           <Documents id="Documents"
							auth={this.props.auth}
							listDocuments={this.props.listDocumentsSend}
							deleteDocument={this.props.deleteDocumentSend}
							uploadDocument={this.props.uploadDocumentSend}
							downloadDocument={this.props.downloadDocumentSend}
							documents={this.props.data.documents}
							document={this.props.data.document} />
                        : null}

                    </div>
                </div>
            </div>
        );
    }
}

Examples.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Examples);
