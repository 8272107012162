import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	root: {
        height: '100%',
        width: '100%',
	},
    workarea: {
        height: '100%',
        width: '100%',
        color: '#212121',
        //backgroundColor: theme.palette.primary.light,
        backgroundColor: '#f1f5f6',
        //boxShadow: '0 5px 20px 0 rgba(0,0,0,.1)',
        //borderRadius: '.25rem',
        //padding: '23px 23px 23px 23px',
    },
});

class AIInferenceAPI extends Component {

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <iframe title="AIInferenceAPI" className={classes.workarea} src="https://opencredit-ai.collenda.cloud/api-docs/redoc" frameBorder="0">
                </iframe>
            </div>
        );
    }

}

AIInferenceAPI.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AIInferenceAPI);
